import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { Moment } from "moment";

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { VehicleAssignment } from '../api/types';
import {
  useGetUserSecurablesQuery, useUnassignVehicleMutation
} from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';

import { setToastMessage } from '../uiHelpers/toastSlice';

//import styles from './Licence.module.css'
import { Stack } from "@mui/material";

export const VehicleUnassign = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);
  const [vehicleAssignment, setVehicleAssignment] = useState<Partial<VehicleAssignment>>({});

  const { data: userSecurables } = useGetUserSecurablesQuery();

  const [unassignVehicle, { isLoading: isUpdating }] = useUnassignVehicleMutation();


  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.DriverLicence]));
    }
  }, [userSecurables])

  useEffect(() => {
    if (props.vehicleAssignment) {
      // Default toDate to today
      setVehicleAssignment({ ...props.vehicleAssignment, toDate: moment() });
    }
  }, [props.vehicleAssignment])

  // Formik validation schema
  const validationSchema = yup.object().shape({
    toDate: yup.date()
      .required('End Date is required'),
  });

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<VehicleAssignment>) => {
    if (newValues) {
      // Force employeeID to 0 so that we don't change assignment on save
      const res: any = await unassignVehicle(newValues);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        displayToastMessage("info", "Success", "Vehicle unassigned");
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error unassigning vehicle", errorText);
      }

    }
  };

  const captionStyle = { margin: '6px 0 4px 0' };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "400px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="dialogHeader">Unassign Vehicle</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={vehicleAssignment} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Stack>
                    <div>
                      <Grid container spacing={2}>

                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>End Date</span>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker value={values.toDate}
                                onChange={(value) => setFieldValue("toDate", value, true)}
                                renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                                inputFormat="DD/MM/yyyy"
                                disabled={!canUpdate}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Grid>

                      </Grid>
                    </div>
                    <div style={{ height: '42px', marginTop: '12px' }}>
                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className="gridButton"
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className="gridButton"
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!canUpdate || isSubmitting || !isValid}
                          >
                            <span>Unassign</span>
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Stack>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};