import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useMsal } from "@azure/msal-react";
import { loginRequestMSGraph } from "../../auth/authConfig";
import { callMsGraph } from './graph';

const initialState = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  initials: '',
  status: 'idle',
};

const getInitials = (text) => {
  var names = text.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      if (action.payload) {
        state.id = action.payload.id;
        state.firstName = action.payload.givenName;
        state.lastName = action.payload.surname;
        state.email = action.payload.userPrincipalName;
        state.initials = getInitials(action.payload.givenName + ' ' + action.payload.surname);
      } else {
        state = initialState;
      }
    },
  },
});

export const { setProfile } = profileSlice.actions;

export const selectProfileName = (state) => state.profile.firstName + ' ' + state.profile.lastName;
export const selectProfileEmail = (state) => state.profile.email;
export const selectProfileInitials = (state) => state.profile.initials;

export default profileSlice.reducer;