import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { DataGrid, GridRowModel, GridCellModesModel, GridCellModes, GridRenderCellParams } from '@mui/x-data-grid';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { AlertColor } from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as moment from 'moment';
import _ from 'lodash';

import { Moment } from "moment";
import { Role, RoleSecurable } from '../api/types';
import { ToastMessage, ToastMessageValue } from '../uiHelpers/ToastMessage';


import { useGetUserSecurablesQuery, useGetRoleSecurablesQuery, useUpdateRoleSecurableMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'

import { setToastMessage } from '../uiHelpers/toastSlice';

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './UserAccess.module.css'

export const RoleSecurables = (props: any) => {
  const dispatch = useDispatch();
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  //const [selectedRole, setSelectedRole] = useState({} as Role);
  const [selectedRoleSecurables, setSelectedRoleSecurables] = useState<RoleSecurable[]>([]);

  const [canUpdateSecurables, setCanUpdateSecurables] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const [ updateRoleSecurable ] = useUpdateRoleSecurableMutation();

  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})

  // Note the use of 'refetch'.  RTK Query caches query response data and for the same query parameters (dateFilter in this case) will only re-run
  // the HTTP call once there are no more subscribers to the store data and after a timeout has expired (60 seconds by default).
  // As the data we're interested in here may change through some other mechanism (such as auto creation or another user making changes) and we don't
  // yet have a SignalR type setup, we'll force a refetch on filter change...
  const { data: rawRoleSecurables, error, isLoading, refetch } = useGetRoleSecurablesQuery();

  const roleSecurables = useMemo(() => {
    let roles: Role[] = [];
    if (rawRoleSecurables) {
      roles = rawRoleSecurables;
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      roles = roles.map((r: Role) => {
        let newUr = { ...r };
        if (newUr.createDate) newUr.createDate = moment.parseZone(newUr.createDate.toString());
        if (newUr.changeDate) newUr.changeDate = moment.parseZone(newUr.changeDate.toString());
        newUr.roleSecurables = r.roleSecurables
          .filter((rs: RoleSecurable) => rs.securableActive) // Show only active securables
          .map((rs: RoleSecurable) => {
            let newRs = { ...rs };
            if (newRs.changeDate) newRs.changeDate = moment.parseZone(newRs.changeDate.toString());
            return newRs;
          });

        return newUr;
      });
    }
    return roles;
  }, [rawRoleSecurables]);

  // When user securables change (e.g. when they are first retrieved), set UI visibility variables from the securables
  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdateSecurables(hasUpdatePermission(userSecurables, [-1]));
    }
  }, [userSecurables])

  //useEffect(() => {
  //  if (roleSecurables && roleSecurables.length > 0 && selectedRoleId === 0) {
  //    setSelectedRoleId(roleSecurables[0].id);
  //  }
  //}, [roleSecurables])

  //useEffect(() => {
  //  let role: Role | undefined = undefined;
  //  if (selectedRoleId && roleSecurables) {
  //    role = roleSecurables.find((r) => r.id === selectedRoleId);
  //  }
  //  //setSelectedRole(role ? role : {} as Role);
  //  setSelectedRoleSecurables(role && role.roleSecurables ? role.roleSecurables : [] as RoleSecurable[]);

  //}, [selectedRoleId])

  useEffect(() => {
    //if (roleSecurables && roleSecurables.length > 0 && selectedRoleId === 0) {
    //  setSelectedRoleId(roleSecurables[0].id);
    //}

    let role: Role | undefined = undefined;
    if (selectedRoleId && roleSecurables) {
      role = roleSecurables.find((r) => r.id === selectedRoleId);
    }
    setSelectedRoleSecurables(role && role.roleSecurables ? role.roleSecurables : [] as RoleSecurable[]);

  }, [selectedRoleId, roleSecurables])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  /*
  const handleEdit = (field: string, roleSec: RoleSecurable) => {
    if (field === 'read') {
    }
    // TODO
    console.log("Clicked Edit for item ID: " + roleSec.securable);
  };

  const cellClicked = (params: any) => {
    if (params.field === 'read' || params.field === 'update' || params.field === 'delete') {
      console.log("Cell clicked: " + params.id + " " + params.field);
      setCellModesModel({ ...cellModesModel, [params.id]: { ...cellModesModel[params.id], [params.field]: { mode: GridCellModes.Edit } } });
    }
  }
  const cellLostFocus = (params: any) => {
    setCellModesModel({ ...cellModesModel, [params.id]: { ...cellModesModel[params.id], [params.field]: { mode: GridCellModes.View } } });
    console.log("Cell lost focus: " + params.id + " " + params.field);
  }

  const processRowUpdate = React.useCallback(
    async (newRow: RoleSecurable) => {
      // Make the HTTP request to save in the backend
      const res: any = await updateRoleSecurable(newRow);
      console.log("Processing a changed row...");
      return res.data;
    },
    [updateRoleSecurable],
  );

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    console.log('Error saving role securables');
  }, []);
  */

  const checkChanged = async (e: any, roleSec: RoleSecurable, field: string) => {
    const checked: boolean = e.target.checked;
    if (roleSec && selectedRoleSecurables) {
      // Firstly, take a copy of the role securable being edited
      let editedRoleSec = { ...roleSec };
      // Set new values based on the input checkbox state
      editedRoleSec.read = field === 'read' ? checked : editedRoleSec.read
      editedRoleSec.update = field === 'update' ? checked : editedRoleSec.update
      editedRoleSec.delete = field === 'delete' ? checked : editedRoleSec.delete
      // Permissions cascade from delete->update->read and vice-versa when unchecking
      // For unchecking
      if (checked === false) {
        if (editedRoleSec.read === false) {
          editedRoleSec.update = false;
        }
        if (editedRoleSec.update === false) {
          editedRoleSec.delete = false;
        }
      }
      // For checking
      if (checked === true) {
        if (editedRoleSec.delete === true) {
          editedRoleSec.update = true;
        }
        if (editedRoleSec.update === true) {
          editedRoleSec.read = true;
        }
      }
      // Save the change to the database
      const res: any = await updateRoleSecurable(editedRoleSec);
      // If all is well there should be some data returned
      const updatedRoleSec = res.data;
      if (updatedRoleSec !== undefined) {
        console.log("Securable" + updatedRoleSec.securable + " set to read: " + updatedRoleSec.read + " update: " + updatedRoleSec.update + " delete: " + updatedRoleSec.delete);
        // Reflect the new values in the state and therefore the UI
        setSelectedRoleSecurables(
          selectedRoleSecurables.map((rs) =>
            rs.securableID === roleSec.securableID
              ? {
                ...rs
                , read: updatedRoleSec.read
                , update: updatedRoleSec.update
                , delete: updatedRoleSec.delete
                , changeDate: (updatedRoleSec.changeDate ?moment.parseZone(updatedRoleSec.changeDate.toString()) : updatedRoleSec.changeDate)
                , changeUser: updatedRoleSec.changeUser
              }
              : { ...rs }
          )
        );

      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving securable permissions", errorText);
      }

    }
  }

  // For some reason, TravelRecord.travelDate is being returned from the api call as a string, despite
  // the travelDate field being defined as a Date type in the TravelRecord interface.
  // As a result, I am using a valueGetter column property to convert the string to a Date
  const columns = [
    { field: 'securable', headerName: 'Securable Object', width: 330},
    {
      field: 'read', headerName: 'Read', type: 'boolean', editable: true, width: 120
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Checkbox disabled={!canUpdateSecurables} tabIndex={params.hasFocus ? 0 : -1}
          checked={params.row.read}
          onChange={(e) => checkChanged(e, params.row, 'read')} />
      ),
    },
    {
      field: 'update', headerName: 'Update', type: 'boolean', editable: true, width: 120
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Checkbox disabled={!canUpdateSecurables} tabIndex={params.hasFocus ? 0 : -1}
          checked={params.row.update}
          onChange={(e) => checkChanged(e, params.row, 'update')} />
      ),
    },
    {
      field: 'delete', headerName: 'Delete', type: 'boolean', editable: true, width: 120
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Checkbox disabled={!canUpdateSecurables} tabIndex={params.hasFocus ? 0 : -1}
          checked={params.row.delete}
          onChange={(e) => checkChanged(e, params.row, 'delete')} />
      ),
    },
    {
      field: 'changeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'changeUser', headerName: 'Updated By', width: 220 },
  ];

  /*
  // This works, but doesn't use the @mui DataGrid
  return (
    <>
      <Container fluid>
        <Row>
          <span style={{ fontSize: '1.3em', fontWeight: '450', marginTop: '8px' }}>Assign Permissions to Roles</span>
        </Row>
        <Row>
          <Stack direction="horizontal" gap={3}>
            <div>
            </div>
            <div className="ms-auto">
              <span style={{ marginRight: '1em'}}>Role</span>
              <select className={styles.select} value={selectedRoleId} onChange={(e) => { setSelectedRoleId(Number(e.target.value)); }}>
                {roleSecurables ?
                  roleSecurables.map((rs: Role) => (
                    <option key={rs.id} value={rs.id}>{rs.name}</option>
                  ))
                  :
                  <></>
                }
              </select>
            </div>
          </Stack>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          {error ? (
            <>Oh no, there was an error</>
          ) : isLoading ? (
            <>Loading...</>
            ) : selectedRoleSecurables ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 140px)', overflowY: 'auto' }}>
                  <div style={{ flexGrow: '1' }}>
                    <Table bordered>
                      <thead className={styles.tableHeader}>
                        <tr>
                          <th style={{ width: '30%'}}>Securable</th>
                          <th style={{ width: '10%' }}>Read</th>
                          <th style={{ width: '10%' }}>Update</th>
                          <th style={{ width: '10%' }}>Delete</th>
                          <th style={{ width: '20%' }}>Last Updated</th>
                          <th style={{ width: '20%' }}>Changed By</th>
                        </tr>
                      </thead>
                      <tbody className={styles.tableBody}>
                        {
                          selectedRoleSecurables.map((rs: RoleSecurable) => {
                            return (
                              <tr key={rs.securableID}>
                                <td>{rs.securable}</td>
                                <td><Checkbox checked={rs.read} onChange={(e) => checkChanged(e, rs, 'read')} /></td>
                                <td><Checkbox checked={rs.update} onChange={(e) => checkChanged(e, rs, 'update')} /></td>
                                <td><Checkbox checked={rs.delete} onChange={(e) => checkChanged(e, rs, 'delete')} /></td>
                                <td>{rs.changeDate ? rs.changeDate.format('ddd Do MMM YYYY HH:mm:ss') : ''}</td>
                                <td>{rs.changeUser}</td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </Table>
              </div>
            </div>
          ) : null}
        </Row>
      </Container>
      <ToastMessage message={toastMessage} />

    </>
  );
  */

  return (
    <>
      <Container fluid style={{ paddingLeft: '20px' }}>
        <Row>
          <span className='pageHeader'>Assign Permissions to Roles</span>
        </Row>
        <Row>
          <Stack direction="horizontal" gap={3} style={{ marginTop: '0.5em' }}>
            <div>
              <span style={{ marginRight: '1em' }}>Role</span>
              <Select value={selectedRoleId} onChange={(e) => { setSelectedRoleId(Number(e.target.value)); }}
                sx={{ width: '260px' }} variant="standard">
                {roleSecurables ?
                  roleSecurables.map((rs: Role) => (
                    <MenuItem key={rs.id} value={rs.id}>{rs.name}</MenuItem>
                  ))
                  :
                  <></>
                }
              </Select>
            </div>
          </Stack>
        </Row>
        {selectedRoleId && selectedRoleId > 0 ?
          <Row style={{ marginTop: '10px' }}>
            {error ? (
              <>Oh no, there was an error</>
            ) : isLoading ? (
              <>Loading...</>
            ) : selectedRoleSecurables ? (
              <div style={{ display: 'flex', height: 'calc(100vh - 140px)', overflowY: 'auto' }}>
                <div style={{ flexGrow: '1' }}>
                      <DataGrid experimentalFeatures={{ newEditingApi: true }} rowHeight={GridRowHeight} getRowId={(row) => row.securableID}
                    rows={selectedRoleSecurables} columns={columns} sx={sxNoCellBorder} />
                </div>
              </div>
            ) : null}
          </Row>
          :
          <></>
        }
      </Container>

    </>
  );

  /*
   return (
    <>
      <Container fluid>
        <Row>
          <span style={{ fontSize: '1.3em', fontWeight: '450', marginTop: '8px' }}>Securables</span>
        </Row>
        <Row>
          <Stack direction="horizontal" gap={3}>
            <div>
            </div>
            <div className="ms-auto">
              <select className={styles.select} value={selectedRoleId} onChange={(e) => { setSelectedRoleId(Number(e.target.value)); }}>
                {roleSecurables ?
                  roleSecurables.map((rs: Role) => (
                    <option key={rs.id} value={rs.id}>{rs.name}</option>
                  ))
                  :
                  <></>
                }
              </select>
            </div>
          </Stack>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          {error ? (
            <>Oh no, there was an error</>
          ) : isLoading ? (
            <>Loading...</>
          ) : selectedRole && selectedRole.roleSecurables ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 140px)' }}>
              <div style={{ flexGrow: '1' }}>
                    <DataGrid experimentalFeatures={{ newEditingApi: true }} getRowId={(row) => row.securableID}
                      onCellClick={cellClicked} onCellFocusOut={cellLostFocus} cellModesModel={cellModesModel}
                      processRowUpdate={processRowUpdate} onProcessRowUpdateError={handleProcessRowUpdateError}
                  rows={selectedRole.roleSecurables} columns={columns} />
              </div>
            </div>
          ) : null}
        </Row>
      </Container>
      <ToastMessage message={toastMessage} />

    </>
  );

   */
};

