import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Form, Field, FieldRenderProps } from 'react-final-form'
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';

import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';
import InputAdornment from '@mui/material/InputAdornment';

import { TravelRecord, TravelAllowance, Job, EmployeeSummary, StandardDataItem } from '../api/types';
import { useGetJobsQuery, useGetEmployeesQuery, useGetAllowanceTypesQuery, useAddTravelAllowanceMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { TextFieldWrapper, SelectWrapper, AutocompleteWrapper, CheckboxWrapper } from '../uiHelpers/FormFieldWrappers';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Travel.module.css'

export const TravelAllowanceAdd = (props: any) => {
  const dispatch = useDispatch();
  const [travelRecord, setTravelRecord] = useState<TravelRecord | null>(null);
  const [travelAllowance, setTravelAllowance] = useState<TravelAllowance | null>(null);

  const [employeeSearchText, setEmployeeSearchText] = useState("");

  //const [updateTravelAllowance] = useUpdateTravelAllowanceMutation();
  const { data: rawJobs } = useGetJobsQuery();
  const { data: rawEmployees } = useGetEmployeesQuery({ includeInactive: true, maxInactiveDays: 90 });
  const { data: allowanceTypes } = useGetAllowanceTypesQuery();
  const [addTravelAllowance] = useAddTravelAllowanceMutation();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newAllowance: Partial<TravelAllowance>) => {
    if (newAllowance) {
      const res: any = await addTravelAllowance(newAllowance);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving allowance", errorText);
      }
    }
  };

  useEffect(() => {
    console.log("UseEffect is setting travelAllowance...");
    // Change a null LocationID to a -1
    if (props.travelRecord) {
      setTravelRecord(props.travelRecord);
    } else {
      setTravelRecord(null);
    }

    if (props.travelAllowance) {
      setTravelAllowance({ ...props.travelAllowance });
    } else {
      setTravelAllowance(null);
    }
  }, [props.travelAllowance, props.travelRecord]);

  const containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  //const employees = useMemo(() => {
  //  if (rawEmployees && employeeSearchText && employeeSearchText.length > 2) {
  //    return rawEmployees.filter((emp) => containsText(emp.name, employeeSearchText))
  //  }
  //},
  //  [rawEmployees, employeeSearchText]
  //);

  const jobs = useMemo(() => {
    let records: Job[] = [];
    if (rawJobs && travelRecord) {
      records = rawJobs.filter((r) => r.active === true);
      records = rawJobs.filter((j) => !j.locationID || j.locationID === travelRecord.locationID)
        .sort((a, b) => {
          if ((a.locationID ?? 0) > (b.locationID ?? 0)) {
            return -1
          }
          if ((a.locationID ?? 0) < (b.locationID ?? 0)) {
            return 1
          }
          return 0
        });
    }
    return records;
  }, [rawJobs, travelRecord]);

  //const employees = useMemo(() => {
  //  if (rawEmployees) {
  //    return rawEmployees.map((l: EmployeeSummary) => {
  //      return (
  //        { label: l.name, value: l.id }
  //      );
  //    })
  //  }
  //  return [];
  //}, [rawEmployees]);

  const employees = useMemo(() => {
    let records: EmployeeSummary[] = [];

    if (rawEmployees) {
      records = rawEmployees.filter((r) => r.active === true);
    }
    return records;
  }, [rawEmployees]);


  const validate = (values: any) => {
    const errors: any = {};
    if (!values.jobID || values.jobID <= 0) {
      errors.jobID = 'Required';
    }
    if (!values.employeeID || values.employeeID <= 0) {
      errors.employeeID = 'Required';
    }
    if (!values.allowanceTypeID || values.allowanceTypeID <= 0) {
      errors.allowanceTypeID = 'Required';
    }
    return errors;
  };

  const captionStyle = { margin: '0 0 4px 0' };

  // @T4888A
  const employeeFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.label + ' ' + option.additionalFilterData,
  });

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="dialogHeader">Add Out of Port Allowance</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Form
              onSubmit={handleSave}
              initialValues={travelAllowance}
              validate={validate}
              render={({ handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Job</span>
                        <Field
                          style={{ margin: '0' }}
                          fullWidth
                          required
                          name="jobID"
                          component={AutocompleteWrapper}
                          variant="standard"
                          formControlProps={{ fullWidth: true }}
                          ListboxProps={{ sx: { maxHeight: '10rem' } }}
                          options={jobs ? jobs.map((j) => { return { label: j.jobNumber, value: j.id }; }) : []}
                        >
                        </Field>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Employee</span>
                        <Field
                          style={{ margin: '0' }}
                          fullWidth
                          required
                          name="employeeID"
                          component={AutocompleteWrapper}
                          formControlProps={{ fullWidth: true }}
                          options={employees ? employees.map((j) => {
                            return {
                              label: (j.name + ' [' + j.employeeNumber + ']') // Display data
                              , value: j.id
                              , additionalFilterData: j.legalName // Additional filterable data - @T4888A
                            };
                          }) : []}
                          filterOptions={employeeFilterOptions}
                          ListboxProps={{ sx: { maxHeight: '10rem' } }}
                        >
                        </Field>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack>
                        <span style={captionStyle}>Allowance Type</span>
                        <Field
                          style={{ marginTop: '0' }}
                          fullWidth
                          required
                          name="allowanceTypeID"
                          component={AutocompleteWrapper}
                          variant="standard"
                          formControlProps={{ fullWidth: true }}
                          ListboxProps={{ sx: { maxHeight: '10rem' } }}
                          options={allowanceTypes ? allowanceTypes.map((a) => { return { label: a.name, value: a.id }; }) : []}
                        >
                        </Field>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      {values && values.allowanceTypeID === 1
                        ?
                        <Stack sx={{ marginLeft: '16px'} }>
                          <span style={captionStyle}>&nbsp;</span>
                          <Field
                            style={{ marginTop: '0', height: '34px' }}
                            name="isReturning"
                            label="Return / On Travel"
                            component={CheckboxWrapper}
                          />
                        </Stack>
                        :
                        <></>
                      }
                    </Grid>

                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          type="button"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={submitting || pristine}
                        >
                          <span>Add</span>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};