import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel'
import { AlertColor } from "@mui/material/Alert";
import * as moment from 'moment';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Moment } from "moment";

import { Location } from '../api/types';
import { timezones } from '../api/timezones';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import { useGetUserSecurablesQuery, useGetLocationsQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'

import { LocationDelete } from './LocationDelete';
import { LocationEdit } from './LocationEdit';

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './Configuration.module.css'

export const LocationHome = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<Partial<Location> | null>();
  const [showItemDelete, setShowItemDelete] = useState<boolean>(false);
  const [showItemEdit, setShowItemEdit] = useState<boolean>(false);
  const [includeInactive, setIncludeInactive] = useState<boolean>(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawLocations, error, isLoading } = useGetLocationsQuery();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.Location]));
      setCanDelete(hasDeletePermission(userSecurables, [SECURABLE_NAME.Location]));
    }
  }, [userSecurables])

  const locations = useMemo(() => {
    let records: Location[] = [];
    if (rawLocations) {
      records = rawLocations.filter((r) => includeInactive || r.active === true);
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = records.filter((r) => r.name.toLowerCase().includes(filter.toLowerCase())
        || r.country.toLowerCase().includes(filter.toLowerCase()))
        .map((tr) => {
          let newTr = { ...tr };
          if (newTr.changeDate) newTr.changeDate = moment.parseZone(newTr.changeDate.toString());
          // Set timezone to the display name
          if (timezones) {
            const tz = timezones.find((t) => t.id === newTr.timezoneID);
            if (tz) newTr.timezone = tz.name;
          }
          return newTr;
        });
    }
    return records;
  }, [rawLocations, filter, includeInactive, timezones]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleAdd = () => {
    setSelectedItem({ id: 0, countryID: 0, name: '', code: '', timezoneID: '' });
    setShowItemEdit(true);
  };

  const handleEdit = (item: Location) => {
    if (item) {
      setSelectedItem(item);
      setShowItemEdit(true);
    }
  };

  const handleDelete = (item: Location) => {
    if (item) {
      setSelectedItem(item);
      setShowItemDelete(true);
    }
  };

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    event.defaultMuiPrevented = true;
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 220 },
    { field: 'code', headerName: 'Code', width: 120 },
    { field: 'country', headerName: 'Country', width: 220 },
    { field: 'timezone', headerName: 'Timezone', width: 300 },
    { field: 'active', headerName: 'Active', type: 'boolean', width: 100 },
    {
      field: 'changeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'changeUser', headerName: 'Updated By', width: 220 },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 100, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Stack direction='row' gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <IconButton disabled={!canUpdate} color="primary" size="small"
              onClick={() => handleEdit(params.row)}>
              <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={params.row.active ? 'Delete' : 'Activate'} enterDelay={1000}>
            <IconButton disabled={params.row.active ? !canDelete : !canUpdate} color="primary" size="small"
              onClick={() => handleDelete(params.row)}>
              <i className={params.row.active ? 'fa fa-regular fa-trash-can' : 'fa fa-solid fa-trash-arrow-up'} style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Grid sx={{ margin: '0 8px' }} container spacing={2}>
        <Grid xs={12}>
          <span className='pageHeader'>Locations</span>
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={6}>
          <TextField sx={{ verticalAlign: 'bottom', marginTop: '4px', minWidth: '240px' }}
            placeholder="search location"
            value={filter}
            onChange={(e: any) => setFilter(e.target.value)}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ margin: '4px' }}>
                  <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                </InputAdornment>
              ),
              endAdornment: (
                < InputAdornment position="end" >
                  <IconButton size='small'
                    onClick={() => setFilter('')}
                  >
                    <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.7em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <FormControlLabel
            sx={{ marginLeft: '16px' }}
            value="activeOnly"
            control={<Switch color="primary" checked={includeInactive}
              onChange={(val: any) => setIncludeInactive(val.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />}
            label="Show inactive"
            labelPlacement="start"
          />
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={6}>
          <Button variant="outlined" className={styles.gridButton} style={{ marginLeft: 'auto' }}
            disabled={!canUpdate} onClick={handleAdd}>Add</Button>
        {/*  <Button variant="contained" className={styles.gridButton} style={{ marginLeft: '12px' }}*/}
        {/*    onClick={() => navigate(-1)}>Back</Button>*/}
        </Grid>
        <Grid xs={12}>
          {error ? (
            <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
          ) : isLoading ? (
            <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
          ) : locations ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 180px)' }}>
              <div style={{ flexGrow: '1' }}>
                    <DataGrid rows={locations} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                  onCellClick={handleGridCellClick} />
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <LocationDelete open={showItemDelete} onClose={() => setShowItemDelete(false)} location={selectedItem} />
      <LocationEdit open={showItemEdit} onClose={() => setShowItemEdit(false)} location={selectedItem} />

    </>
  );
}