import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { EmployeeDetail } from '../api/types';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';
import { useGetUserSecurablesQuery, useUpdateEmployeeMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Configuration.module.css'
import { Stack } from "@mui/material";

export const EmployeeEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);
  const [employee, setEmployee] = useState<Partial<EmployeeDetail>>();

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const [updateEmployee, { isLoading: isUpdating }] = useUpdateEmployeeMutation();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.Employee]));
    }
  }, [userSecurables])

  useEffect(() => {
    setEmployee(props.employee);
  }, [props.employee])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Formik validation schema
  const validationSchema = yup.object().shape({
    bankCompany: yup.string()
      .length(2, 'Bank code must be two numbers')
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required('Bank code is required'),
    bankBranch: yup.string()
      .length(4, 'Branch code must be four numbers. Prefix with 0s if required')
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required('Branch code is required'),
    bankAccountNumber: yup.string()
      .length(7, 'Account number must be 7 numbers. Prefix with 0s if required')
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required('Account number is required'),
    bankAccountSuffix: yup.string()
      .length(2, 'Account suffix must be two numbers')
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required('Account suffix is required'),
  });

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<EmployeeDetail>) => {
    if (newValues) {
      const res: any = await updateEmployee(newValues);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        displayToastMessage("info", "Success", "Employee saved");
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving property employee", errorText);
      }
    }
  }

  const captionStyle = { margin: '6px 0 4px 0' };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "420px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="dialogHeader">Update Employee</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={props.employee} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Employee Name</span>
                        <span style={captionStyle}>{props.employee.firstName + " " + props.employee.lastName}</span>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} spacing={0}>
                      <Stack>
                        <span style={captionStyle}>Bank Account</span>
                        <Grid container alignItems="flex-start" spacing={2}>
                          <Grid item xs={2}>
                            <Stack>
                              <span style={captionStyle}>Bank</span>
                              <TextField value={values.bankCompany}
                                name="bankCompany"
                                placeholder="2 digits"
                                onChange={handleChange}
                                disabled={!canUpdate}
                                variant="standard"
                                sx={{ width: '40px' }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={3}>
                            <Stack>
                              <span style={captionStyle}>Branch</span>
                              <TextField value={values.bankBranch}
                                name="bankBranch"
                                placeholder="4 digits"
                                onChange={handleChange}
                                disabled={!canUpdate}
                                variant="standard"
                                sx={{ width: '80px' }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={5}>
                            <Stack>
                              <span style={captionStyle}>Account number</span>
                              <TextField value={values.bankAccountNumber}
                                name="bankAccountNumber"
                                placeholder="7 digits"
                                onChange={handleChange}
                                disabled={!canUpdate}
                                variant="standard"
                                sx={{ width: '120px' }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={2}>
                            <Stack>
                              <span style={captionStyle}>Suffix</span>
                              <TextField value={values.bankAccountSuffix}
                                name="bankAccountSuffix"
                                placeholder="2 digits"
                                onChange={handleChange}
                                disabled={!canUpdate}
                                variant="standard"
                                sx={{ width: '40px' }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} style={{ marginTop: '8px' }}>
                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!canUpdate || isSubmitting || !isValid || !dirty}
                          >
                            <span>Update</span>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};