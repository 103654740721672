import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';

import { TRAVEL_STATUS } from '../api/types';

import { GetStatusColours } from '../travel/TravelHome';

import { useGetUserSecurablesQuery, useGetMetricOutOfPortEmployeesQuery } from '../api/apiSlice';
import { hasReadPermission, SECURABLE_NAME } from '../userProfile/securableHelper'
import { Container } from "@mui/material";

interface OOPEmployeeTravel {
  locationID: number;
  location: string;
  locationCode: string;
  employeeCount: number | null;
}

export const WidgetOutOfPortEmployees = (props: any) => {
  const [canRead, setCanRead] = useState<boolean>(false);
  const [employeeTotal, setEmployeeTotal] = useState<number>(0);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawOutOfPortEmployees, refetch: refetchOutOfPortEmployees } = useGetMetricOutOfPortEmployeesQuery(props.age);

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanRead(hasReadPermission(userSecurables, [SECURABLE_NAME.Travel]));
    }
  }, [userSecurables])

  useEffect(() => {
    refetchOutOfPortEmployees();
  }, [props.age])

  const outOfPortEmployees = useMemo(() => {
    let records: OOPEmployeeTravel[] = [];
    let totalCount: number = 0;
    if (rawOutOfPortEmployees) {
      records = rawOutOfPortEmployees.map((tr) => {
        const newTr: OOPEmployeeTravel = {
          locationID: tr.locationID,
          location: tr.location,
          locationCode: tr.locationCode,
          employeeCount: (tr.newAllowanceAmount ?? 0) + (tr.approvedAllowanceAmount ?? 0) + (tr.exportedAllowanceAmount ?? 0)
        };
        totalCount += (newTr.employeeCount ?? 0);
        return newTr;
      });
    }
    setEmployeeTotal(totalCount);
    return records;
  }, [rawOutOfPortEmployees]);

  const width: number = props.width ?? 400;
  const height: number = props.height ?? 300;

  const chartOptions: AgChartOptions = {
    autoSize: false,
    width: width - 30,
    height: height - 80,
    padding: { bottom: -2 },
    legend: { enabled: false },
    series: [
      {
        type: 'pie',
        data: outOfPortEmployees,
        calloutLabelKey: 'location',
        sectorLabelKey: 'employeeCount',
        angleKey: 'employeeCount',
        fills: [
          '#49afda',
          '#57cc8b',
          '#bcdf72',
          '#fbeb37',
          '#f4b944',
          '#fb7451',
          '#72508c',
          '#b7b5ba',
        ],
        strokeWidth: 0,
        highlightStyle: { item: { fill: 'white', fillOpacity: 0.5 } },
      },

    ]
  };

  return (
    <>
      {
        canRead ?
          <div style={{ backgroundColor: '#ffffff', border: '#f0f0f0 solid 1px', borderRadius: '5px', width: `${width}px`, height: `${height}px` }}>
            <Stack>
              <Grid container>
                <Grid xs={1}>
                </Grid>
                <Grid xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <span className="pageHeader">Employees Out Of Port Yesterday</span>
                </Grid>
                <Grid xs={1}>
                  <Tooltip title="Reload data" enterDelay={1000}>
                    <IconButton color="primary" size="small" sx={{ margin: '8px 0' }}
                      onClick={() => refetchOutOfPortEmployees()}>
                      <i className="fa fa-fw fa-rotate" style={{ fontSize: '0.9em' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <span>Total out-of-port (including unapproved): <strong>{employeeTotal}</strong></span>
                </Grid>

              </Grid>
              <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                {outOfPortEmployees && outOfPortEmployees.length > 0 ?
                  <AgChartsReact options={chartOptions} />
                  :
                  <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                    <span>No data for yesterday, or awaiting update...</span>
                  </Container>
                }
              </Grid>
            </Stack>
          </div>
          : <></>
      }
    </>
  );
}

