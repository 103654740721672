import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

function handleLogout(instance) {
  //instance.logoutPopup().catch(e => {
  //  console.error(e);
  //});

  // Trying redirect instead of popup...
  instance.logoutRedirect().catch(e => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = (props) => {
  const { instance } = useMsal();

  return (
    <Button variant="secondary" style={{ marginRight: '10px' }} onClick={() => handleLogout(instance)}><span style={{ fontSize: '13px' }}>Sign out</span></Button>
  );
}