import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { UserRole, Role, StandardDataItem } from '../api/types';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';
import { useGetUserSecurablesQuery, useGetRoleSecurablesQuery, useGetOperationalLocationsQuery, useUpdateUserRoleMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessage, ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './UserAccess.module.css'

export const UserRoleEdit = (props: any) => {
  const dispatch = useDispatch();
  //const [userRole, setUserRole] = useState<Partial<UserRole>>({});

  const [canUpdate, setCanUpdate] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const [updateUserRole] = useUpdateUserRoleMutation();
  const { data: roles } = useGetRoleSecurablesQuery();
  const { data: locations } = useGetOperationalLocationsQuery();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.User]));
    }
  }, [userSecurables])

  //useEffect(() => {
  //  console.log("UseEffect is setting role...");
  //  // Change a null LocationID to a -1
  //  if (props.userRole) {
  //    setUserRole({ ...props.userRole, locationID: (props.userRole.locationID ? props.userRole.locationID : -1) });
  //  }
  //}, [props.userRole]);

  // Note use of a const value for the userRole passed to the Formik form - this seems to work.
  // When a state variable was used, Formik seemed unable to get the latest value - it was always one state version behind...
  // Change a null LocationID to a -1
  const userRole: Partial<UserRole> = props.userRole ? { ...props.userRole, locationID: (props.userRole.locationID ? props.userRole.locationID : -1) } : {};

  const memoRoles = useMemo(() => {
    if (roles && roles.length > 0) {
      return roles.filter((rs) => rs.active === true);
    }
    return [] as Role[];
  }, [roles]);

  const memoLocations = useMemo(() => {
    if (locations && locations.length > 0) {
      return [{ id: -1, name: 'ALL', code: 'ALL' } as StandardDataItem, ...locations];
    }
    return [] as StandardDataItem[];
  }, [locations]);

  // Formik validation schema
  const validationSchema = yup.object().shape({
    roleID: yup.number()
      .min(1)
      .required('Role is required'),
  });

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (editedUserRole: Partial<UserRole>) => {
    if (editedUserRole) {
      const res: any = await updateUserRole(editedUserRole);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving user role", errorText);
      }
    }
  };

  const captionStyle = { margin: '6px 0 4px 0' };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "360px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {userRole && userRole.roleID === 0 ?
            <span className="dialogHeader">Add User Role</span>
            :
            <span className="dialogHeader">Edit User Role</span>
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '16px 0', margin: 'auto' }}>
            <Formik initialValues={userRole} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Role</span>
                        <AutocompleteWrapper
                          value={values?.roleID}
                          onChange={(e: any, value: any) => { if (value) setFieldValue("roleID", value, true); }}
                          renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                          options={memoRoles ? memoRoles.map((j) => { return { label: j.name, value: j.id }; }) : []}
                          disabled={!canUpdate || (userRole?.id ?? 0) > 0}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Location</span>
                        <AutocompleteWrapper
                          value={values?.locationID}
                          onChange={(e: any, value: any) => { if (value) setFieldValue("locationID", value, true); }}
                          renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                          options={memoLocations ? memoLocations.map((j) => { return { label: j.name, value: j.id }; }) : []}
                          disabled={!canUpdate}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: '8px' }}>

                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!canUpdate || isSubmitting || !isValid || !dirty}
                          >
                            {userRole && userRole.roleID === 0 ? 
                              <span>Add</span>
                              :
                              <span>Update</span>
                            }
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};