import * as React from "react";
import { useState, useEffect } from "react";
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import { EmployeeSummary } from '../api/types';
import Box from "@mui/material/Box";

export const EmployeeProfile = (props: any) => {
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeSummary>();

  useEffect(() => {
    setSelectedEmployee(props.employee);
  }, [props.employee])

  return (
    <>
      {selectedEmployee ? (
        <div style={{ height: '100%'} }>
          <Grid container>
          <Grid item xs={12}>
            <Stack>
              <Typography>Employee ID</Typography>
              <Typography sx={{ fontWeight: '700' }}>{selectedEmployee.employeeNumber}</Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ marginTop: '0.6em' }} xs={12}>
            <Stack>
              <Typography>Legal Name</Typography>
              <Typography sx={{ fontWeight: '700' }}>{selectedEmployee.legalName}</Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ marginTop: '0.6em' }} xs={12}>
            <Stack>
              <Typography>Preferred Name</Typography>
              <Typography sx={{ fontWeight: '700' }}>{selectedEmployee.preferredName}</Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ marginTop: '0.6em' }} xs={12}>
            <Stack>
              <Typography>Location</Typography>
              <Typography sx={{ fontWeight: '700' }}>{selectedEmployee.location}</Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ marginTop: '0.6em' }} xs={12}>
            <Stack>
              <Typography>Department</Typography>
              <Typography sx={{ fontWeight: '700' }}>{selectedEmployee.department}</Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ marginTop: '0.6em' }} xs={12}>
            <Stack>
              <Typography>Key Skill</Typography>
              <Typography sx={{ fontWeight: '700' }}>{selectedEmployee.keySkill}</Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ marginTop: '0.6em' }} xs={12}>
            <Stack>
              <Typography>Status</Typography>
              <Typography sx={{ fontWeight: '700' }}>{selectedEmployee.active ? 'Active' : 'Inactive'}</Typography>
            </Stack>
            </Grid>
          </Grid>
        </div>
      )
        : <></>
      }
    </>
  );
};