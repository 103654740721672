import { createSlice } from '@reduxjs/toolkit';
import { StandardDataItem } from '../api/types';

interface TravelStore {
  statusFilter: number,
  dateFilter: number,
  locationsFilter: StandardDataItem[],
}

const initialState: TravelStore = {
  statusFilter: 1,
  dateFilter: 0,
  locationsFilter: [],
};


export const travelSlice = createSlice({
  name: 'travel',
  initialState,
  reducers: {
    setStatusFilter: (state: TravelStore, action) => {
      if (action.payload) {
        state.statusFilter = action.payload;
      } else {
        state.statusFilter = initialState.statusFilter;
      }
    },
    setDateFilter: (state: TravelStore, action) => {
      if (action.payload) {
        state.dateFilter = action.payload;
      } else {
        state.dateFilter = initialState.dateFilter;
      }
    },
    setLocationsFilter: (state: TravelStore, action) => {
      if (action.payload) {
        state.locationsFilter = action.payload;
      } else {
        state.locationsFilter = initialState.locationsFilter;
      }
    },
  },
});

export const { setStatusFilter, setDateFilter, setLocationsFilter } = travelSlice.actions;

export const selectStatusFilter = (state: any) => state.travel.statusFilter;
export const selectDateFilter = (state: any) => state.travel.dateFilter;
export const selectLocationsFilter = (state: any) => state.travel.locationsFilter;

export default travelSlice.reducer;