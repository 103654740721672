import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AlertColor } from "@mui/material/Alert";
import * as moment from 'moment';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Moment } from "moment";

import { WorkVisa } from '../api/types';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import { useGetUserSecurablesQuery, useGetWorkVisasQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'

import { RenderCellExpand } from '../uiHelpers/GridCellExpand';
import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import { WorkVisaEdit } from "./WorkVisaEdit";
import { WorkVisaDelete } from "./WorkVisaDelete";
//import styles from './Vehicle.module.css'

export const WorkVisaHome = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [canRead, setCanRead] = useState<boolean>(false);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<Partial<WorkVisa> | null>();
  const [showItemDelete, setShowItemDelete] = useState<boolean>(false);
  const [showItemEdit, setShowItemEdit] = useState<boolean>(false);
  const [includeInactive, setIncludeInactive] = useState<boolean>(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawVisas, error, isLoading } = useGetWorkVisasQuery(includeInactive);

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanRead(hasReadPermission(userSecurables, [SECURABLE_NAME.WorkVisa]));
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.WorkVisa]));
      setCanDelete(hasDeletePermission(userSecurables, [SECURABLE_NAME.WorkVisa]));
    }
  }, [userSecurables])

  const visas = useMemo(() => {
    let records: WorkVisa[] = [];
    if (rawVisas) {
      records = rawVisas.filter((r) => includeInactive || r.active === true);
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = records.filter((r) => r.legalName.toLowerCase().includes(filter.toLowerCase()) // @T4888C
        || r.preferredName?.toLowerCase().includes(filter.toLowerCase()) ) // @T4888A
        .map((tr) => {
          let newTr = { ...tr };
          if (newTr.dateOfBirth) newTr.dateOfBirth = moment.parseZone(newTr.dateOfBirth.toString());
          if (newTr.startDate) newTr.startDate = moment.parseZone(newTr.startDate.toString());
          if (newTr.expiryDate) newTr.expiryDate = moment.parseZone(newTr.expiryDate.toString());
          return newTr;
        });
    }
    return records;
  }, [rawVisas, filter, includeInactive]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleAdd = () => {
    setSelectedItem({ id: 0, name: '', expiryDate: null } as Partial<WorkVisa>);
    setShowItemEdit(true);
    //displayToastMessage("warning", "Add Visa", "This feature is not yet implemented");
  };

  const handleEdit = (visa: Partial<WorkVisa>) => {
    setSelectedItem(visa);
    setShowItemEdit(true);
    //displayToastMessage("warning", "Edit Visa", "This feature is not yet implemented");
  };

  const handleDelete = (visa: Partial<WorkVisa>) => {
    setSelectedItem(visa);
    setShowItemDelete(true);
    //displayToastMessage("warning", "Delete Visa", "This feature is not yet implemented");
  };


  const columns = [
    { field: 'name', headerName: 'Employee', width: 180 },
    /* This data is not currently available in MahiTahi
    {
      field: 'dateOfBirth', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY");
      }
      , headerName: 'DOB', width: 140
    },
    {
      field: 'startDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY");
      }
      , headerName: 'Start Date', width: 140
    },
    */
    { field: 'workingLocation', headerName: 'Working Location', width: 180 },
    { field: 'country', headerName: 'Passport Nationality', width: 180 },
    { field: 'passportNumber', headerName: 'Passport Number', width: 140 },
    { field: 'visaType', headerName: 'Visa Type', width: 120 },
    {
      field: 'expiryDate', type: 'date'
      , valueFormatter: ({ value }: any) => {
        if (value) {
          let date: Moment = value;
          return date && date.format("DD/MM/YYYY");
        }
        return 'Indefinite';
      }
      , headerName: 'Visa Expiry', width: 140
    },
    { field: 'notes', headerName: 'Conditions', renderCell: RenderCellExpand, width: 140 },
    { field: 'active', headerName: 'Active', type: 'boolean', width: 150 },
    {
      field: ' ', headerName: 'Actions', width: 150, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Stack direction="row" gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <IconButton disabled={!canUpdate} color="primary" size="small"
              onClick={() => handleEdit(params.row)}>
              <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={params.row.active ? 'Delete' : 'Activate'} enterDelay={1000}>
            <IconButton disabled={params.row.active ? !canDelete : !canUpdate} color="primary" size="small"
              onClick={() => handleDelete(params.row)}>
              <i className={params.row.active ? 'fa fa-regular fa-trash-can' : 'fa fa-solid fa-trash-arrow-up'} style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    event.defaultMuiPrevented = true;
  }

  return (
    <>
      <Grid sx={{ margin: '0 12px' }} container spacing={2}>
        <Grid xs={12}>
          <span className='pageHeader'>Work Visa</span>
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={6}>
          <TextField sx={{ verticalAlign: 'bottom', marginTop: '4px', minWidth: '280px' }}
            placeholder="search by employee"
            value={filter}
            onChange={(e: any) => setFilter(e.target.value)}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ margin: '4px' }}>
                  <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                </InputAdornment>
              ),
              endAdornment: (
                < InputAdornment position="end" >
                  <IconButton size='small'
                    onClick={() => setFilter('')}
                  >
                    <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.7em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <FormControlLabel
            sx={{ marginLeft: '16px' }}
            value="activeOnly"
            control={<Switch color="primary" checked={includeInactive}
              onChange={(val: any) => setIncludeInactive(val.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />}
            label="Show inactive"
            labelPlacement="start"
          />
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={6}>
          <Button variant="outlined" className="gridButton" style={{ marginLeft: 'auto' }}
            disabled={!canUpdate} onClick={handleAdd}>Add Visa</Button>
        </Grid>
        <Grid xs={12}>
          {error ? (
            <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
          ) : isLoading ? (
            <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
          ) : visas ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 180px)' }}>
              <div style={{ flexGrow: '1' }}>
                <DataGrid rows={visas} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                  columnBuffer={10}
                  onCellClick={handleGridCellClick} />
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <WorkVisaEdit open={showItemEdit} onClose={() => setShowItemEdit(false)} workVisa={selectedItem} />
      <WorkVisaDelete open={showItemDelete} onClose={() => setShowItemDelete(false)} workVisa={selectedItem} />

    </>
  );
}