import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { AlertColor } from "@mui/material/Alert";
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent } from '@mui/x-data-grid';
import * as moment from 'moment';
import { Moment } from "moment";

import { Vehicle, VehicleAssignment } from '../api/types';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { RenderCellExpand } from '../uiHelpers/GridCellExpand';
import { setToastMessage } from '../uiHelpers/toastSlice';
import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';

import { VehicleAssignmentEdit } from './VehicleAssignmentEdit';

import { useGetUserSecurablesQuery, useGetVehiclesQuery, useGetVehicleAssignmentsQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'


export const VehicleAssignmentHistory = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [canRead, setCanRead] = useState<boolean>(false);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [vehicle, setVehicle] = useState<Vehicle | null>();
  const [filter, setFilter] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<Partial<VehicleAssignment> | null>();
  const [showItemEdit, setShowItemEdit] = useState<boolean>(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawVehicles } = useGetVehiclesQuery();
  const { data: rawVehicleAssignments, refetch, error, isLoading } = useGetVehicleAssignmentsQuery(parseInt(id ?? '0'));

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanRead(hasReadPermission(userSecurables, [SECURABLE_NAME.VehicleRegister]));
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.VehicleRegister]));
      setCanDelete(hasDeletePermission(userSecurables, [SECURABLE_NAME.VehicleRegister]));
    }
  }, [userSecurables])

  const vehicles = useMemo(() => {
    let records: Vehicle[] = [];
    if (rawVehicles) {
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = rawVehicles.map((tr) => {
        let newTr = { ...tr };
        if (newTr.year) newTr.year = moment.parseZone(newTr.year.toString());
        if (newTr.purchaseDate) newTr.purchaseDate = moment.parseZone(newTr.purchaseDate.toString());
        if (newTr.assignedDate) newTr.assignedDate = moment.parseZone(newTr.assignedDate.toString());
        if (newTr.changeDate) newTr.changeDate = moment.parseZone(newTr.changeDate.toString());
        return newTr;
      });
    }
    return records;
  }, [rawVehicles]);

  useEffect(() => {
    if (vehicles && vehicles.length > 0 && id) {
      setVehicle(vehicles.find((v) => v.id === parseInt(id ?? '0')));
    }
  }, [vehicles, id])

  //useEffect(() => {
  //  // Always refetch assignments
  //  if (id) {
  //    refetch();
  //  }
  //}, [id])

  const vehicleAssignments = useMemo(() => {
    let records: VehicleAssignment[] = [];
    if (rawVehicleAssignments) {
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = rawVehicleAssignments.filter((r) => r.employee.toLowerCase().includes(filter.toLowerCase()))
        .map((tr) => {
          let newTr = { ...tr };
          if (newTr.fromDate) newTr.fromDate = moment.parseZone(newTr.fromDate.toString());
          if (newTr.toDate) newTr.toDate = moment.parseZone(newTr.toDate.toString());
          if (newTr.changeDate) newTr.changeDate = moment.parseZone(newTr.changeDate.toString());
          return newTr;
        });
    }
    return records;
  }, [rawVehicleAssignments, filter]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleEdit = (visa: Partial<VehicleAssignment>) => {
    setSelectedItem(visa);
    setShowItemEdit(true);
    //displayToastMessage("warning", "Edit Vehicle Assignment", "This feature is not yet implemented");
  };

  const columns = [
    { field: 'employee', headerName: 'Employee', width: 180 },
    { field: 'vehiclePrivateUsage', headerName: 'Private Usage', width: 120 },
    {
      field: 'fromDate', type: 'date'
      , valueFormatter: ({ value }: any) => {
        if (value) {
          let date: Moment = value;
          return date && date.format("DD/MM/YYYY");
        }
        return '';
      }
      , headerName: 'Start Date', width: 140
    },
    {
      field: 'toDate', type: 'date'
      , valueFormatter: ({ value }: any) => {
        if (value) {
          let date: Moment = value;
          return date && date.format("DD/MM/YYYY");
        }
        return '';
      }
      , headerName: 'End Date', width: 140
    },
    {
      field: 'changeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'changeUser', headerName: 'Updated By', width: 220 },
    //{ field: 'active', headerName: 'Active', type: 'boolean', width: 150 },
    {
      field: ' ', headerName: 'Actions', width: 150, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Stack direction="row" gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <IconButton disabled={!canUpdate} color="primary" size="small"
              onClick={() => handleEdit(params.row)}>
              <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    event.defaultMuiPrevented = true;
  }

  return (
    <>
      <Grid sx={{ margin: '0 12px' }} container spacing={2}>
        <Grid xs={12}>
          <span className='pageHeader'>Vehicle Assignment History</span>
        </Grid>
        <Grid xs={12}>
          {/*<Stack direction="row">*/}
          {/*  <span style={{ marginRight: '8px' }}>Vehicle Make:</span>*/}
          {/*  <span style={{ marginRight: '8px', fontWeight: '700' }}>{vehicle?.vehicleMake}</span>*/}
          {/*  <span style={{ marginRight: '8px' }}>Model:</span>*/}
          {/*  <span style={{ marginRight: '8px', fontWeight: '700' }}>{vehicle?.vehicleModel}</span>*/}
          {/*  <span style={{ marginRight: '8px' }}>Registration:</span>*/}
          {/*  <span style={{ marginRight: '8px', fontWeight: '700' }}>{vehicle?.registration}</span>*/}
          {/*  <span style={{ marginRight: '8px' }}>Asset #:</span>*/}
          {/*  <span style={{ fontWeight: '700' }}>{vehicle?.assetNumber}</span>*/}
          {/*</Stack>*/}
          <Table sx={{ width: 650 }} size="small" aria-label="vehicle info">
            <TableHead>
              <TableRow>
                <TableCell>Vehicle Make</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Registration</TableCell>
                <TableCell>Asset #</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={1}>
                <TableCell sx={{ fontWeight: 700 }}>{vehicle?.vehicleMake}</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>{vehicle?.vehicleModel}</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>{vehicle?.registration}</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>{vehicle?.assetNumber}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={6}>
          <TextField sx={{ verticalAlign: 'bottom', marginTop: '4px', minWidth: '280px' }}
            placeholder="search by employee"
            value={filter}
            onChange={(e: any) => setFilter(e.target.value)}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ margin: '4px' }}>
                  <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                </InputAdornment>
              ),
              endAdornment: (
                < InputAdornment position="end" >
                  <IconButton size='small'
                    onClick={() => setFilter('')}
                  >
                    <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.7em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid xs={12}>
          {error ? (
            <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
          ) : isLoading ? (
            <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
          ) : vehicleAssignments ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 240px)' }}>
              <div style={{ flexGrow: '1' }}>
                <DataGrid rows={vehicleAssignments} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                  columnBuffer={10}
                  onCellClick={handleGridCellClick} />
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <VehicleAssignmentEdit open={showItemEdit} onClose={() => setShowItemEdit(false)} vehicleAssignment={selectedItem} />

    </>
  );
}