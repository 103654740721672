import { UserSecurable } from '../api/types'

//export const hasReadPermission = (userSecurables: UserSecurable[], securableName: string | '') : boolean => {
//  let hasPermisson: boolean = false;

//  if (userSecurables && userSecurables.length > 0) {
//    const sec = userSecurables.find((s) => s.securable == securableName);
//    if (sec) {
//      hasPermisson = sec.read;
//    }
//  }

//  return hasPermisson;
//};

// NOTE: Permission is granted if the user has the appropriate access to any of the supplied securable names.
//       Typically only one securable name will be passed, but there are cases where more than one is applicable.

// TODO: - Add optional locationId parameter
//       - Check for roleIsSysAdmin (always give access if this is the case)
export const hasAnyPermission = (userSecurables: UserSecurable[]): boolean => {
  let hasPermisson: boolean = false;

  if (userSecurables && userSecurables.length > 0) {
    let accessCount: number = 0;
      if (userSecurables.find(s => s.roleIsSysAdmin === true || s.read === true || s.update === true || s.delete === true)) {
        accessCount++;
      }

    hasPermisson = accessCount > 0;
  }

  return hasPermisson;
};


export const hasReadPermission = (userSecurables: UserSecurable[], securableIds: number[] | [], locationId?: number): boolean => {
  let hasPermisson: boolean = false;

  if (userSecurables && userSecurables.length > 0 && securableIds && securableIds.length > 0) {
    let accessCount: number = 0;
    securableIds.forEach((secId) => {
      if (userSecurables.find(s => s.roleIsSysAdmin === true || ((locationId === undefined || s.locationID === null || locationId === s.locationID)
        && s.securableID === secId && s.read === true)))
      {
        accessCount++;
      }
    });

    hasPermisson = accessCount > 0;
  }

  return hasPermisson;
};

export const hasUpdatePermission = (userSecurables: UserSecurable[], securableIds: number[] | [], locationId?: number): boolean => {
  let hasPermisson: boolean = false;

  if (userSecurables && userSecurables.length > 0 && securableIds && securableIds.length > 0) {
    let accessCount: number = 0;
    securableIds.forEach((secId) => {
      if (userSecurables.find(s => s.roleIsSysAdmin === true || ((locationId === undefined || s.locationID === null || locationId === s.locationID)
        && s.securableID === secId && s.update === true))) {
        accessCount++;
      }
    });

    hasPermisson = accessCount > 0;
  }

  return hasPermisson;
};

export const hasDeletePermission = (userSecurables: UserSecurable[], securableIds: number[] | [], locationId?: number): boolean => {
  let hasPermisson: boolean = false;

  if (userSecurables && userSecurables.length > 0 && securableIds && securableIds.length > 0) {
    let accessCount: number = 0;
    securableIds.forEach((secId) => {
      if (userSecurables.find(s => s.roleIsSysAdmin === true || ((locationId === undefined || s.locationID === null || locationId === s.locationID)
        && s.securableID === secId && s.delete === true))) {
        accessCount++;
      }
    });

    hasPermisson = accessCount > 0;
  }

  return hasPermisson;
};

export enum SECURABLE_NAME {
  Travel = 1,
  TravelExport = 2,
  DriverLicence = 3,
  PPE = 4,
  VehicleRegister = 5,
  CreditCard = 6,
  User = 7,
  Role = 8,
  Travel_Configuration = 9,
  DriverLicence_Configuration = 10,
  PPE_Configuration = 11,
  VehicleRegister_Configuration = 12,
  Country = 13,
  Location = 14,
  WorkVisa = 15,
  WorkVisa_Configuration = 16,
  MobileApplication = 17,
  VehicleUnavailability = 18,
  Employee = 19,
};
