import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { LicenceClass, LICENCE_CLASS_STAGE } from '../api/types';
import { useGetUserSecurablesQuery, useUpdateEmployeeLicenceClassMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Licence.module.css'
import { Stack } from "@mui/material";

export const LicenceClassEdit = (props: any) => {
  const dispatch = useDispatch();
  //const [licenceClass, setLicenceClass] = useState<Partial<LicenceClass>>({});

  const [canUpdateLicence, setCanUpdateLicence] = useState(false);
  const [classId, setClassId] = useState<number>(0);
  const [licenceId, setLicenceId] = useState<number>(0);
  
  const { data: userSecurables } = useGetUserSecurablesQuery();
  const [updateEmployeeLicenceClass, { isLoading: isUpdating }] = useUpdateEmployeeLicenceClassMutation();

  // Formik validation schema 
  const validationSchema = yup.object().shape({
    expiryDate: yup.date()
      .required('Expiry date is required'),
    licenceStageID: yup.number()
      .min(1)
      .required('Licence stage is required'),
  });

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdateLicence(hasUpdatePermission(userSecurables, [SECURABLE_NAME.DriverLicence]));
    }
  }, [userSecurables])

  useEffect(() => {
    if (props.licenceClass) {
      setClassId(props.licenceClass.classID);
      setLicenceId(props.licenceId ?? 0);
    }
  }, [props.licenceClass, props.licenceId]);

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<LicenceClass>) => {
    if (props.licenceClass && newValues) {
      // Create the parameter for the update call
      const editedLicenceClass = { licenceID: licenceId, ClassID: classId, licenceStageID: newValues.licenceStageID, expiryDate: newValues.expiryDate };

      const res: any = await updateEmployeeLicenceClass(editedLicenceClass);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving licence class", errorText);
      }
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.licenceClass && props.licenceClass.licenceClassID ? (
            <span className="dialogHeader">Update Class {classId}</span>
          )
            : (
              <span className="dialogHeader">Add Class {classId}</span>
            )
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={props.licenceClass} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <span>Stage</span>
                    </Grid>
                    <Grid style={{ paddingTop: 4 }} item xs={12}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="licenceStageID"
                        value={values.licenceStageID}
                        onChange={handleChange}
                      >
                        <Stack direction="row" gap={2}>
                          <FormControlLabel value={1} control={<Radio />} label="Learner" />
                          {classId === 1 || classId === 6 ? (
                            <FormControlLabel value={2} control={<Radio />} label="Restricted" />
                          ) : <></>}
                          <FormControlLabel value={3} control={<Radio />} label="Full" />
                        </Stack>
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <span>Expiry Date</span>
                    </Grid>
                    <Grid style={{ paddingTop: 8 }} item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker value={values.expiryDate}
                            onChange={(value) => setFieldValue("expiryDate", value, true)}
                            renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                            inputFormat="DD/MM/yyyy"
                            disabled={!canUpdateLicence}
                          />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '26px' }}>

                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting || !isValid || !dirty}
                          >
                            {props.licenceClass && props.licenceClass.licenceClassID ? (
                              <span>Update</span>
                            )
                              : (
                                <span>Add</span>
                              )
                            }
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};