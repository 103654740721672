import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form'
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { Vehicle } from '../api/types';
import { useToggleDeleteVehicleMutation, useGetRoleSecurablesQuery } from '../api/apiSlice';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

export const VehicleDelete = (props: any) => {
  const dispatch = useDispatch();
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [toggleDeleteVehicle, { isLoading: isUpdating }] = useToggleDeleteVehicleMutation();
  const { data: roleSecurables } = useGetRoleSecurablesQuery();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();
  const handleSave = async (delItem: Partial<Vehicle>) => {
    if (delItem) {
      const res: any = await toggleDeleteVehicle({ id: delItem.id ?? 0, targetState: !(delItem.active ?? false) });
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error deleting vehicle", errorText);
      }
    }
  };

  useEffect(() => {
    setVehicle(props.vehicle);
    setIsDelete(props.vehicle && props.vehicle.active);
  }, [props.vehicle]);

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          {isDelete ?
            <span className="dialogHeader">Delete Vehicle</span>
            : <span className="dialogHeader">Activate Vehicle</span>
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '16px 0', margin: 'auto' }}>
            <Form
              onSubmit={handleSave}
              initialValues={vehicle}
              render={({ handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container alignItems="flex-start" spacing={2}>
                    {isDelete ?
                      <>
                        <Grid item xs={12}>
                          <span>Are you sure you want to delete?</span>
                        </Grid>
                      </>
                      : <>
                        <Grid item xs={12}>
                          <span>Are you sure you want to activate?</span>
                        </Grid>
                      </>
                    }
                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className="gridButton"
                          type="button"
                          color="primary"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className="gridButton"
                          variant="contained"
                          type="submit"
                          disabled={submitting}
                        >
                          {isDelete ?
                            <span>Delete</span>
                            : <span>Activate</span>
                          }
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};