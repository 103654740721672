import { graphConfig } from "../../auth/authConfig";

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}

export async function callMsGraphUserPhoto(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "image/jpeg");

  const options = {
    method: "GET",
    headers: headers
  };
  /*
  return fetch(graphConfig.graphMePhoto, options)
    .then(response => {
      if (response != null && response.ok) {
        response.blob().then((data) => {
          if (data !== null) {
            window.URL = window.URL || window.webkitURL;
            const photoURL = window.URL.createObjectURL(data);
            console.log("We got a user photo!!!  URL is " + photoURL);
            return Promise.All(photoURL);
          }
        });
      } else {
        throw new Error("Profile image not found");
      }
    })
    .catch(error => console.log(error));
    */
  return fetch(graphConfig.graphMePhoto, options)
    .then(response => response.blob())
    .catch(error => console.log(error));

}