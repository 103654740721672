import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';

export const AutocompleteWrapper = (props: any) => {
  const { options, value, onChange, ListboxProps, renderInput, ...rest } = props;

  const defaultRenderInput = (params: any) => { return (<TextField {...params} variant="standard" />); };

  return (
    <Autocomplete
      {...rest}
      onChange={(e: any, data: any) => onChange(e, data ? data.value : 0)}
      options={options}
      value={options && value ? options.find((o: any) => o.value === value) : ''}
      ListboxProps={ListboxProps}
      isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
      renderInput={renderInput ?? defaultRenderInput}
    />);
}