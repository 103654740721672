import React from "react";
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';

import { SignInButton } from "../signin/SignInButton";
import styles from './LandingPage.module.css';

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const LandingPage = (props) => {

  return (
    <>
      <div className={styles.navbarColour} style={{ width: '100%', height: '100vh' }}>
      <Grid container >
          <Grid xs={12}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '12px'} }>
          <SignInButton style={{ marginTop: '12px', marginLeft: 'auto' }} />
          </div>
        </Grid>
        <Grid xs={12}>
            <div className={styles.titleDiv} style={{ height: 'calc(100vh - 120px)'} }>
          <Stack>
            <span className={styles.title}>mahi tahi</span>
            <span className={styles.subtitle} style={{ marginTop: '20px' }}>1. (verb) to work together, collaborate, cooperate.</span>
            <span className={styles.subtitle} style={{ marginTop: '18px' }}>2. (noun) working together, collaboration, cooperation, teamwork.</span>
          </Stack>
        </div>
        </Grid>
      </Grid>
      </div>
    </>
  );
};