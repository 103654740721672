import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent, GridAlignment } from '@mui/x-data-grid';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AlertColor } from "@mui/material/Alert";
import * as moment from 'moment';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useGetTravelRecordsQuery, useGetOperationalLocationsQuery } from "../api/apiSlice"
import { Moment } from "moment";
import { TravelRecord, TRAVEL_STATUS, StandardDataItem } from '../api/types';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';
import { setDateFilter, setStatusFilter, setLocationsFilter, selectDateFilter, selectStatusFilter, selectLocationsFilter } from './travelSlice';

import { TravelExport } from "./TravelExport";
import { TravelRecordAdd } from "./TravelRecordAdd";

import { useGetUserSecurablesQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'

import { RenderCellExpand } from '../uiHelpers/GridCellExpand';
import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './Travel.module.css'
import { STATUS_COLOURS } from '../uiHelpers/StatusColours';

export const GetStatusColours = (statusId: number) => {
  let colour = STATUS_COLOURS.Closed;
  if (statusId === TRAVEL_STATUS.New) {
    colour = STATUS_COLOURS.Open;
  }
  else if (statusId === TRAVEL_STATUS.Approved) {
    colour = STATUS_COLOURS.Waiting;
  }

  return { color: colour + 'ff', backgroundColor: colour + '20' };
};

export const TravelHome = (props: any) => {
  const dispatch = useDispatch();
  //const [dateFilter, setDateFilter] = useState(0);
  //const [statusFilter, setStatusFilter] = useState(1);
  //const [locationsFilter, setLocationsFilter] = useState<StandardDataItem[]>([]);

  const dateFilter = useSelector(selectDateFilter);
  const statusFilter = useSelector(selectStatusFilter);
  const locationsFilter = useSelector(selectLocationsFilter);

  const [canReadTravel, setCanReadTravel] = useState(false);
  const [canUpdateTravel, setCanUpdateTravel] = useState(false);
  const [canExportTravel, setCanExportTravel] = useState(false);
  const [showTravelExport, setShowTravelExport] = useState(false);
  const [showTravelAdd, setShowTravelAdd] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: opLocations, refetch: refetchOperationalLocations } = useGetOperationalLocationsQuery();

  // Note the use of 'refetch'.  RTK Query caches query response data and for the same query parameters (dateFilter in this case) will only re-run
  // the HTTP call once there are no more subscribers to the store data and after a timeout has expired (60 seconds by default).
  // As the data we're interested in here may change through some other mechanism (such as auto creation or another user making changes) and we don't
  // yet have a SignalR type setup, we'll force a refetch on filter change...
  const { data: rawTravelRecords, error, isLoading, refetch } = useGetTravelRecordsQuery(dateFilter);

  const navigate = useNavigate();

  const enum STATUS_SELECTOR {
    Open = 1,
    New = 2,
    Approved = 3,
    Exported = 4,
    All = 5,
  };

  const travelRecords = useMemo(() => {
    let records: TravelRecord[] = [];
    let statusIds: Number[] = [TRAVEL_STATUS.New, TRAVEL_STATUS.Approved];
    if (statusFilter === STATUS_SELECTOR.New) {
      statusIds = [TRAVEL_STATUS.New];
    } else if (statusFilter === STATUS_SELECTOR.Approved) {
      statusIds = [TRAVEL_STATUS.Approved];
    } else if (statusFilter === STATUS_SELECTOR.Exported) {
      statusIds = [TRAVEL_STATUS.Exported];
    } else if (statusFilter === STATUS_SELECTOR.All) {
      statusIds = [TRAVEL_STATUS.New, TRAVEL_STATUS.Approved, TRAVEL_STATUS.Exported];
    }

    if (rawTravelRecords) {
      records = rawTravelRecords;
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = records.filter((tr) => statusIds.find((s) => s === tr.statusID))
        .filter((tr) => locationsFilter?.length === 0 || locationsFilter.find((l:StandardDataItem) => l.id === tr.locationID))
        .map((tr) => {
          let newTr = { ...tr };
          if (newTr.changeDate) newTr.changeDate = moment.parseZone(newTr.changeDate.toString());
          if (newTr.travelDate) newTr.travelDate = moment.parseZone(newTr.travelDate.toString());
          return newTr;
        });
    }
    return records;
  }, [rawTravelRecords, statusFilter, locationsFilter]);

  // When user securables change (e.g. when they are first retrieved), set UI visibility variables from the securables
  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanReadTravel(hasReadPermission(userSecurables, [SECURABLE_NAME.Travel]));
      setCanUpdateTravel(hasUpdatePermission(userSecurables, [SECURABLE_NAME.Travel]));
      setCanExportTravel(hasUpdatePermission(userSecurables, [SECURABLE_NAME.TravelExport]));
    }
  }, [userSecurables])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleAdd = () => {
    //displayToastMessage("warning", "Add Travel Record", "This feature is not yet implemented");
    setShowTravelAdd(true);
  };

  const handleExport = () => {
    //displayToastMessage("warning", "Export Travel Records", "This feature is not yet implemented");
    setShowTravelExport(true);
  };

  const handleAddTravelClose = (id: number) => {
    setShowTravelAdd(false);
    if (id && id > 0) {
      const to = '/travel/allowances/' + id + '/' + dateFilter;
      if (window.location.pathname !== to) {
        navigate(to);
      }
    }
  }

  const handleEdit = (travRec: TravelRecord) => {
    if (travRec) {
      console.log("Clicked Edit for item ID: " + travRec.id);
      const to = '/travel/allowances/' + travRec.id + '/' + dateFilter;
      if (window.location.pathname !== to) {
        navigate(to);
      }
    }
  };

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    if (canReadTravel && params.colDef && params.colDef.headerName !== 'Actions') {
      handleEdit(params.row);
    }
    event.defaultMuiPrevented = true;
  }
  // For some reason, TravelRecord.travelDate is being returned from the api call as a string, despite
  // the travelDate field being defined as a Date type in the TravelRecord interface.
  // As a result, I am using a valueGetter column property to convert the string to a Date
  const columns = [
    { field: 'id', headerName: 'Travel ID', width: 100 },
    {
      field: 'travelDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("ddd Do MMM YYYY");
      }
      , headerName: 'Travel Date', width: 180
    },
    { field: 'location', headerName: 'Location', width: 200 },
    { field: 'department', headerName: 'Department', renderCell: RenderCellExpand, width: 200 },
    {
      field: 'status', headerName: 'Status', width: 100
      , renderCell: (params: GridRenderCellParams<string>) => (
        <div className="status" style={GetStatusColours(params.row.statusID)} >
          {params.value}
        </div>
      )
    },
    { field: 'allowanceCount', headerName: 'Allowances', align: 'center' as GridAlignment, width: 100 },
    {
      field: 'changeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'changeUser', headerName: 'Updated By', width: 150 },
    { field: 'approvedBy', headerName: 'Approved By', width: 150 },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 80, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Tooltip title="Edit" enterDelay={1000}>
          <IconButton disabled={!canReadTravel} color="primary" size="small"
            onClick={() => handleEdit(params.row)}>
            <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
          </IconButton>
        </Tooltip>

      ),
    },
  ];

  return (
    <>
      <Container fluid style={{ paddingLeft: '20px' }}>
        <Row>
          <span className='pageHeader'>Out of Port Travel</span>
        </Row>
        <Row>
          <Stack direction="horizontal" gap={3}>
            <div>
              <Select variant="standard" sx={{ width: 200 }} value={statusFilter} onChange={(e) => {
                dispatch(setStatusFilter(Number(e.target.value)));
              }}>
                <MenuItem key="1" value={STATUS_SELECTOR.Open}>Open</MenuItem>
                <MenuItem key="2" value={STATUS_SELECTOR.New}>New</MenuItem>
                <MenuItem key="3" value={STATUS_SELECTOR.Approved}>Approved</MenuItem>
                <MenuItem key="4" value={STATUS_SELECTOR.Exported}>Exported</MenuItem>
                <MenuItem key="5" value={STATUS_SELECTOR.All}>All</MenuItem>
              </Select>
            </div>
            <div>
              <Select variant="standard" sx={{ width: 200 }} value={dateFilter} onChange={(e) => {
                dispatch(setDateFilter(Number(e.target.value)));
                refetch();
              }}>
                <MenuItem key="0" value="0">Last 7 days</MenuItem>
                <MenuItem key="1" value="1">Last 3 days</MenuItem>
                <MenuItem key="2" value="2">Yesterday</MenuItem>
                <MenuItem key="3" value="3">This Month</MenuItem>
                <MenuItem key="4" value="4">Last Month</MenuItem>
                <MenuItem key="5" value="5">Last 3 Months</MenuItem>
              </Select>
            </div>
            <div>
              <Autocomplete sx={{ width: 360 }}
                size="small"
                multiple
                disableCloseOnSelect
                limitTags={2}
                options={opLocations ?? []}
                getOptionLabel={(option) => option.name}
                defaultValue={locationsFilter}
                onChange={(e,value) => dispatch(setLocationsFilter(value)) }
                
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      size="small"
                      style={{ marginRight: 8, marginTop: -4, marginBottom: -4 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label=""
                    placeholder="Filter Location(s)"
                  />
                )}
              />
            </div>
            <div className="ms-auto">
            </div>
            <div>
              <Button disabled={!canUpdateTravel} className={styles.gridButton} variant="outlined" onClick={() => handleAdd()}>Add</Button>
            </div>
            <div>
              <Button disabled={!canExportTravel} className={styles.gridButton} variant="contained" onClick={() => handleExport()}>Export</Button>
            </div>
          </Stack>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          {error ? (
            <>Oh no, there was an error</>
          ) : isLoading ? (
            <>Loading...</>
          ) : travelRecords ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 140px)' }}>
              <div style={{ flexGrow: '1' }}>
                <DataGrid rows={travelRecords} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                  onCellClick={handleGridCellClick} />
              </div>
            </div>
          ) : null}
        </Row>
      </Container>

      <TravelExport open={showTravelExport} onClose={() => setShowTravelExport(false)} />
      <TravelRecordAdd dateFilter={dateFilter} open={showTravelAdd} onClose={handleAddTravelClose} />
    </>
  );
};


/*
       <div>
        <h5 className="card-title">Mahi Tahi Data</h5>
        {error ? (
          <>Oh no, there was an error</>
        ) : isLoading ? (
          <>Loading...</>
        ) : data ? (
          <ul>
            {data.map((tr) => (
              <li>{tr.location}</li>
            ))}
          </ul>
        ) : null}
      </div>

 */