import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequestMahiTahi } from "../../auth/authConfig";
//import Button from "react-bootstrap/Button";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';

//function handleLogin(instance) {
//  //instance.loginPopup(loginRequestMahiTahi).catch(e => {
//  //  console.error(e);
//  //});

//  // Trying redirect instead of popup...
//  instance.loginRedirect(loginRequestMahiTahi).catch(e => {
//    console.error(e);
//  });

//}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  function handleLogin() {
    //instance.loginPopup(loginRequestMahiTahi).catch(e => {
    //  console.error(e);
    //});

    // Trying redirect instead of popup...
    instance.loginRedirect(loginRequestMahiTahi).catch(e => {
      console.error(e);
    });

    navigate("/");
  }

  return (
    <Button variant="outlined" className="gridButton"
      sx={{
        color: 'white', borderColor: 'white', borderWidth: '2px', borderRadius: '26px', padding: '8px 30px', marginRight: '10px',
        '&:hover': { borderColor: 'white', borderWidth: '2px', backgroundColor: 'white', color: '#4c5d46' }
      }}
      onClick={() => handleLogin()}>
      <span style={{ fontSize: '16px' }}>Log in</span>
    </Button>
  );
}