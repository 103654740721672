import * as moment from 'moment';

export interface StandardDataItem {
  id: number;
  code: string;
  name: string;
}

export interface UserSecurable {
  securableID: number;
  securable: string;
  locationID?: number;
  roleIsSysAdmin: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export interface TravelRecord {
  id: number;
  statusID: number;
  status: string;
  locationID: number;
  location: string;
  departmentID?: number; // @T4920A
  department: string; // @T4920A
  travelDate: moment.Moment;
  changeDate: moment.Moment;
  changeUser: string;
  approvedBy: string;
  allowanceCount: number;
  notes: string;
}

export interface TravelAllowance {
  id: number;
  travelRecordID: number;
  jobID: number;
  jobNumber: string;
  employeeTravelID: number;
  employeeID: number;
  employeeNumber: string;
  employeeName: string;
  homePort: string;
  allowanceTypeID: number;
  allowanceType: string;
  isReturning: boolean;
  futureAllowanceCount: number;
  changeDate: moment.Moment; // @T4853A
  changeUser: string; // @T4853A
  department: string; // @T4853A
}

export interface Allowance {
  id: number;
  name: string;
  shortName: string;
  paymentCode: string;
  allowanceRate: number;
  allowanceLevelID: number;
  allowanceLevel: string;
  allowanceTypeID: number;
  allowanceType: string;
  changeDate: moment.Moment;
  changeUser: string;
  active: boolean;
}

export interface TravelAllowanceDeleteParm {
  id: number;
  travelRecordID: number;
  closeEmployeeTravel: boolean;
}

export interface TravelAllowanceExport {
  id: number;
  jobNumber: string;
  employeeNumber: string;
  employeeName: string;
  bankAccount: string;
  travelRecordID: number;
  travelDate: moment.Moment;
  paymentCode: string;
  allowanceRate: number;
  travelExportID: number;
}

export interface EmployeeSummary {
  id: number;
  locationID: number;
  unionID: number;
  allowanceLevelID: number;
  name: string;
  legalName: string;
  preferredName: string;
  employeeNumber: string;
  location: string;
  department: string;
  keySkill: string;
  active: boolean;
}

export interface EmployeeDetail {
	id: number;
	employeeNumber: string;
	firstName: string;
	preferredName: string;
	lastName: string;
	locationID: number;
	location: string;
	departmentID: number;
	department: string;
	jobTitle: string;
	keySkillID: number;
	keySkill: string;
	unionID: number;
	union: string;
	payrollID: number;
	payroll: string;
	allowanceLevelID: number;
	bankAccount: string;
	email: string;
	homePhone: string;
	mobilePhone: string;
	changeDate: moment.Moment;
	changeUser: string;
  active: boolean;

  bankCompany: string;
  bankBranch: string;
  bankAccountNumber: string;
  bankAccountSuffix: string;

  bankAccountDisplay: string;

}

export interface Job {
  id: number;
  locationID: number;
  location: string;
  jobNumber: string;
  description: string;
  changeDate: moment.Moment;
  changeUser: string;
  active: boolean;
}

export interface Role {
  id: number;
  name: string;
  isSysAdmin: boolean;
  createDate: moment.Moment;
  createUser: string;
  changeDate: moment.Moment;
  changeUser: string;
  active: boolean;
  userCount: number;
  roleSecurables: RoleSecurable[];
}

export interface RoleSecurable {
  roleID: number;
  securableID: number;
  securable: string;
  read: boolean;
  update: boolean;
  delete: boolean;
  changeDate: moment.Moment;
  changeUser: string;
  securableActive: boolean;
}

export interface User {
  id: number;
  userName: string;
  name: string;
  lastLoginDate: moment.Moment;
}

export interface UserWithRoles {
  id: number;
  userName: string;
  name: string;
  active: boolean;
  lastLoginDate: moment.Moment;
  roles: UserRole[];
  lastChangeDate: moment.Moment;
  lastChangeUser: string;
  userStatus: string;
}

export interface UserRole {
  id: number;
  userID: number;
  roleID: number;
  role: string;
  isSysAdmin: boolean;
  locationID?: number;
  location: string;
  changeDate: moment.Moment;
  changeUser: string;
}

//#region Licences
export interface Licence {
  id: number;
  employeeID: number;
  licenceNumber: string;
  suspensionDate: moment.Moment;
  notes: string;
  createDate: moment.Moment;
  createUser: string;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
  status: string;
  licenceClasses: LicenceClass[];
  licenceEndorsements: LicenceEndorsement[];
}

export interface LicenceClass {
  classID: number;
  className: string;
  classShortName: string;
  licenceClassID: number;
  licenceStageID: number;
  licenceStage: string;
  expiryDate: moment.Moment;
  active: boolean;
  isExpired: boolean;
  licenceID: number; // Used only as a parameter for mutation calls
}

export interface LicenceEndorsement {
  endorsementID: number;
  endorsement: string;
  endorsementShortName: string;
  licenceEndorsementID: number;
  expiryDate: moment.Moment;
  active: boolean;
  isExpired: boolean;
  licenceID: number; // Used only as a parameter for mutation calls
}
//#endregion Licences

//#region Property
export interface PropertyCategory {
  id: number;
  name: string;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}

export interface PropertyItem {
  id: number;
  name: string;
  propertyCategoryID: number;
  propertyCategory: string;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}

export interface PropertyAssigned {
  id: number;
  employeeID: number;
  propertyItemID: number;
  propertyItem: string;
  propertyCategoryID: number;
  propertyCategory: string;
  dateIssued: moment.Moment;
  purchaseOrder: string;
  assetNumber: string;
  quantity: number;
  notes: string;
  isReturned: boolean;
  returnedDate: moment.Moment;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}
//#endregion Property

//#region Vehicle
export interface VehicleMake {
  id: number;
  name: string;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}

export interface VehicleModel {
  id: number;
  name: string;
  vehicleMakeID: number;
  vehicleMake: string;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}

export interface Vehicle {
  id: number;
  assetNumber: string;
  registration: string;
  vehicleModelID: number;
  vehicleModel: string;
  vehicleMakeID: number;
  vehicleMake: string;
  year: moment.Moment | null;
  purchaseDate: moment.Moment | null;
  vehicleAssignmentID: number;
  employeeID: number;
  assignedTo: string;
  assignedDate: moment.Moment | null;
  isAssigneeActive: boolean | null; // @T4820A
  vehiclePrivateUsageID: number;
  vehiclePrivateUsage: string;
  notes: string;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}

export interface VehicleAssignment {
  id: number;
  vehicleID: number;
  employeeID: number;
  employee: string;
  fromDate: moment.Moment | null;
  toDate: moment.Moment | null;
  vehiclePrivateUsageID: number;
  vehiclePrivateUsage: string;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}

export interface VehicleUnavailability {
  id: number;
  vehicleID: number;
  vehicleUnavailabilityTypeID: number;
  vehicleUnavailabilityType: string;
  fromDate: moment.Moment | null;
  toDate: moment.Moment | null;
  notes: string;
  createDate: moment.Moment;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}

export interface VehicleUnavailabilityType {
  id: number;
  name: string;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}

//#endregion Vehicle

//#region Countries and locations
export interface Country {
  id: number;
  name: string;
  code: string;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}

export interface Location {
  id: number;
  name: string;
  code: string;
  countryID: number;
  country: string;
  timezoneID: string;
  timezone: string;
  locationTypeID: number;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
}

// @T4831A
export interface LocationNotification {
  locationID: number;
  location: string;
  notificationTypeID: number;
  locationNotificationID: number;
  emailAddress: string;
  active: boolean;
}
//#endregion Countries and locations

//#region Work visas
export interface WorkVisa {
  id: number;
  employeeID: number;
  name: string;
  legalName: string;
  preferredName: string;
  workingLocationID: number;
  workingLocation: string;
  countryID: number;
  country: string;
  passportNumber: string;
  visaTypeID: number;
  visaType: string;
  expiryDate: moment.Moment | null;
  dateOfBirth: moment.Moment;
  startDate: moment.Moment;
  notes: string;
  active: boolean;
}

export interface WorkVisaType {
  id: number;
  name: string;
  changeDate: moment.Moment;
  changeUser: string;
  changeType: string;
  active: boolean;
  isExpiryDateRequired: boolean; // @T4800A
}

//#endregion

//#region Notification
export interface NotificationItem {
  id: number;
  notificationTypeID: number;
  timespan: number;
  timespanUnit: number;
  emailAddress: string;
  active: boolean;
}
//#endregion

//#region Metrics
export interface MetricTravelAmount {
  locationID: number;
  location: string;
  locationCode: string;
  newAllowanceAmount: number | null;
  approvedAllowanceAmount: number | null;
  exportedAllowanceAmount: number | null;
}
//#endregion Metrics

//#region Enumerations
export enum TRAVEL_STATUS {
  New = 1,
  Approved = 2,
  Exported = 3,
}

export enum TRAVEL_ALLOWANCE_TYPE {
  OutOfPort = 1,
  ReturnOnly = 2,
}

export enum LICENCE_CLASS_STAGE {
  Learner = 1,
  Restricted = 2,
  Full = 3,
}

export enum NOTIFICATION_TYPES {
  WorkVisaExpiry = 1,
  TravelAllowanceApproval = 2,
}

export enum WORK_VISA_NOTIFICATION_UNITS {
  Days = 1,
  Months = 2,
  Years = 3,
}
//#endregion Enumerations
