import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Form, Field, FieldRenderProps } from 'react-final-form'
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { UserWithRoles } from '../api/types';
import { useDeleteAllUserRolesMutation, useGetRoleSecurablesQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './UserAccess.module.css'

export const UserDelete = (props: any) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<UserWithRoles | null>(null);

  const [deleteAllUserRoles] = useDeleteAllUserRolesMutation();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();
  const handleSave = async (delUser: Partial<UserWithRoles>) => {
    if (delUser) {
      const res: any = await deleteAllUserRoles(delUser.id ?? 0);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        displayToastMessage("info", "Application access removed", "Application access removed from user " + (user ? user.name : ''));
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error removing user access", errorText);
      }
    }
  };

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="dialogHeader">Remove User Access</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '16px 0', margin: 'auto' }}>
            <Form
              onSubmit={handleSave}
              initialValues={user}
              render={({ handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      {user ?
                        <span>Are you sure you want to remove application access from user {user.name}?</span>
                        :
                        <></>
                      }
                    </Grid>
                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          type="button"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                        <Grid item style={{ marginLeft: '10px' }}>
                          <Button
                            className={styles.gridButton}
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={submitting}
                          >
                            <span>Remove</span>
                          </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};