import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent, GridAlignment } from '@mui/x-data-grid';
//import { Button as MuiButton } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from "@mui/material/Grid";
import { AlertColor } from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import * as moment from 'moment';
import { Moment } from "moment";

import { TravelAllowanceDelete } from "./TravelAllowanceDelete";
import { TravelAllowanceAdd } from "./TravelAllowanceAdd";
import {
  useGetTravelAllowancesQuery, useGetTravelRecordsQuery, useGetUserSecurablesQuery
  , useUpdateTravelAllowanceReturnStatusMutation, useUpdateTravelAllowanceJobMutation, useUpdateTravelAllowanceTypeMutation
  , useUpdateTravelRecordNotesMutation, useUpdateTravelRecordStatusMutation, useGetAllowanceTypesQuery, useGetJobsQuery
  , useDeleteTravelRecordMutation
} from '../api/apiSlice';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { RenderCellExpand } from '../uiHelpers/GridCellExpand';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './Travel.module.css'
import { TravelAllowance, TravelRecord, Job, StandardDataItem, TRAVEL_STATUS, TRAVEL_ALLOWANCE_TYPE } from "../api/types";
import { GetStatusColours } from './TravelHome';

import { setToastMessage } from '../uiHelpers/toastSlice';

const getClassNameForStatus = (statusId: number) => {
  let className = '';
  switch (statusId) {
    case 1: { className = styles.statusNew; break; }
    case 2: { className = styles.statusApproved; break; }
    case 3: { className = styles.statusExported; break; }
  }
  return className;
}

const enum STATUS_CHANGE_ACTION {
  Approve = 1,
  ResetApprovedStatus = 2,
  ResetExportedStatus = 3,
};

export const TravelAllowances = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, filter } = useParams();

  const [travelRecord, setTravelRecord] = useState<TravelRecord>();
  const [travelRecordNotes, setTravelRecordNotes] = useState<string>('');
  const [travelRecordDate, setTravelRecordDate] = useState<Moment>();
  const [editedAllowance, setEditedAllowance] = useState<TravelAllowance>();
  const [currentAllowance, setCurrentAllowance] = useState<Partial<TravelAllowance>>();
  const [statusChangeAction, setStatusChangeAction] = useState<STATUS_CHANGE_ACTION>(STATUS_CHANGE_ACTION.Approve);

  const [updateType, setUpdateType] = useState<string>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [enableTravelRecordSave, setEnableTravelRecordSave] = useState<boolean>(false);
  const [enableStatusChange, setEnableStatusChange] = useState<boolean>(false);

  const [showTravelAllowanceAdd, setShowTravelAllowanceAdd] = useState(false);
  const [showTravelAllowanceDelete, setShowTravelAllowanceDelete] = useState(false);
  const [showStatusChangeDialog, setShowStatusChangeDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const [canUpdateTravel, setCanUpdateTravel] = useState(false);
  const [canDeleteTravel, setCanDeleteTravel] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawTravelAllowances, error, isLoading, refetch: refetchTravelAllowances } = useGetTravelAllowancesQuery(parseInt(id ?? '0'));
  const { data: travelRecords, refetch: refetchTravelRecords } = useGetTravelRecordsQuery(parseInt(filter ?? '0'));
  const { data: rawJobs } = useGetJobsQuery();
  const { data: allowanceTypes } = useGetAllowanceTypesQuery();
  const [updateTravelAllowanceReturnStatus] = useUpdateTravelAllowanceReturnStatusMutation();
  const [updateTravelAllowanceJob] = useUpdateTravelAllowanceJobMutation();
  const [updateTravelAllowanceType] = useUpdateTravelAllowanceTypeMutation();
  const [updateTravelRecordNotes] = useUpdateTravelRecordNotesMutation();
  const [updateTravelRecordStatus] = useUpdateTravelRecordStatusMutation();
  const [deleteTravelRecord] = useDeleteTravelRecordMutation();

  const jobs = useMemo(() => {
    let records: Job[] = [];
    if (rawJobs && travelRecord) {
      records = rawJobs.filter((r) => r.active === true);
      records = rawJobs.filter((j) => !j.locationID || j.locationID === travelRecord.locationID)
        .sort((a, b) => {
          if ((a.locationID ?? 0) > (b.locationID ?? 0)) {
            return -1
          }
          if ((a.locationID ?? 0) < (b.locationID ?? 0)) {
            return 1
          }
          return 0
        });
    }
    return records;
  }, [rawJobs, travelRecord]);

  const travelAllowances = useMemo(() => {
    let records: TravelAllowance[] = [];
    if (rawTravelAllowances && rawTravelAllowances.length > 0) {
      // An undefined jobID reports an error in the console log, so set to 0...
      records = rawTravelAllowances.map((ta: TravelAllowance) => {
        let newTa = { ...ta };
        if (!newTa.jobID) newTa.jobID = 0;
        if (newTa.changeDate) newTa.changeDate = moment.parseZone(newTa.changeDate.toString());
        return newTa;
      });
    }
    return records;
  }, [rawTravelAllowances])

  useEffect(() => {
    if (travelRecord && userSecurables && userSecurables.length > 0) {
      if (travelRecord.statusID > TRAVEL_STATUS.New) {
        // Editing enabled only for New status travel records
        setCanUpdateTravel(false);
        setCanDeleteTravel(false);
      } else {
        setCanUpdateTravel(hasUpdatePermission(userSecurables, [SECURABLE_NAME.Travel]));
        setCanDeleteTravel(travelAllowances && travelAllowances.length === 0 && hasDeletePermission(userSecurables, [SECURABLE_NAME.Travel]));
      }
    }

    if (travelRecord && userSecurables) {
      const canUpdateTravel = hasUpdatePermission(userSecurables, [SECURABLE_NAME.Travel]);
      const canExportTravel = hasUpdatePermission(userSecurables, [SECURABLE_NAME.TravelExport]);

      switch (statusChangeAction) {
        case STATUS_CHANGE_ACTION.Approve:
          // Enable approval only when all allowances are assigned to a job...
          setEnableStatusChange(canUpdateTravel && travelAllowances && travelAllowances.length > 0 && travelAllowances.filter((t) => (t.jobID ?? 0) === 0).length === 0);
          break;
        case STATUS_CHANGE_ACTION.ResetApprovedStatus:
          setEnableStatusChange(canUpdateTravel);
          break;
        case STATUS_CHANGE_ACTION.ResetExportedStatus:
          setEnableStatusChange(canExportTravel);
          break;
        default:
          setEnableStatusChange(false);
          break;
      }
    }
  }, [userSecurables, travelRecord, travelAllowances])

  //useEffect(() => {
  //  if (travelAllowances && travelAllowances.length > 0) {
  //    // Enable approval only when all allowances are assigned to a job...
  //    setEnableStatusChange(travelAllowances.filter((t) => (t.jobID ?? 0) === 0).length === 0);
  //  }
  //}, [travelAllowances])

  useEffect(() => {
    if (travelRecords && travelRecords.length > 0) {
      setTravelRecord(travelRecords.find((tr) => tr.id === parseInt(id ?? '0')));
    }
  }, [travelRecords, id])

  useEffect(() => {
    if (travelRecord && travelRecord.travelDate) {
      setTravelRecordDate(moment.parseZone(travelRecord.travelDate));
      setTravelRecordNotes(travelRecord.notes);
    }
    // Set the action for the state-change button
    if (travelRecord) {
      switch (travelRecord.statusID) {
        case TRAVEL_STATUS.Approved:
          setStatusChangeAction(STATUS_CHANGE_ACTION.ResetApprovedStatus);
          break;
        case TRAVEL_STATUS.Exported:
          setStatusChangeAction(STATUS_CHANGE_ACTION.ResetExportedStatus);
          break;
        default:
          setStatusChangeAction(STATUS_CHANGE_ACTION.Approve);
          break;
      }
    }
  }, [travelRecord])

  useEffect(() => {
    if (travelRecord) {
      setEnableTravelRecordSave(travelRecord.notes !== travelRecordNotes);
    }
  }, [travelRecord, travelRecordNotes])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleAdd = () => {
    if (travelRecord) {
      //displayToastMessage("warning", "Add Travel Allowance", "This feature is not yet implemented");
      setCurrentAllowance({ id: 0, jobID: 0, employeeID: 0, allowanceTypeID: 0, travelRecordID: travelRecord.id } as Partial<TravelAllowance>);
      setShowTravelAllowanceAdd(true);
    }
  };

  const handleDelete = (allowance: TravelAllowance) => {
    //displayToastMessage("warning", "Delete Travel Allowance", "This feature is not yet implemented");
    if (allowance) {
      setCurrentAllowance(allowance);
      setShowTravelAllowanceDelete(true);
    }
  };

  const handleSaveNotes = async () => {
    if (travelRecord) {
      const editedTravelRecord = { ...travelRecord, notes: travelRecordNotes };
      // Save the change to the database
      const res: any = await updateTravelRecordNotes(editedTravelRecord);
      // If all is well there should be some data returned
      if (res.data !== undefined) {
        console.log("Travel Records notes set to: " + editedTravelRecord.notes);
        // Reflect the new values in the state and therefore the UI
        setTravelRecord(editedTravelRecord);
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving travel record notes", errorText);
      }
    }
  };

  const checkChanged = async (e: any, allowance: TravelAllowance) => {
    const checked: boolean = e.target.checked;
    if (allowance && travelAllowances) {
      // Firstly, take a copy of the role securable being edited
      let newAllowance = { ...allowance };
      // Set new values based on the input checkbox state
      newAllowance.isReturning = checked;

      // If there are any future allowances and we are setting the isReturning flag (i.e. we are closing the employee travel)
      // then we need to warn the user that these future allowances will be deleted
      if (checked === true && newAllowance.futureAllowanceCount > 0) {
        setEditedAllowance(newAllowance);
        setUpdateType('Return');
        setShowDialog(true);
      } else {
        await updateAllowanceReturnStatus(newAllowance);
      }

      /*
       * TODO - Save the change
       * This will be complicated as we'll probably need to confirm the request...
       * ...and there may be a return result that means we need to re-confirm and re-save (probably with a 'force' save parameter)...
       */
      /*
      // Save the change to the database
      const res: any = await updateRoleSecurable(editedAllowance);
      // If all is well there should be some data returned
      const updatedRoleSec = res.data;
      if (updatedRoleSec !== undefined) {
        console.log("Securable" + updatedRoleSec.securable + " set to read: " + updatedRoleSec.read + " update: " + updatedRoleSec.update + " delete: " + updatedRoleSec.delete);
        // Reflect the new values in the state and therefore the UI
        setSelectedRoleSecurables(
          selectedRoleSecurables.map((rs) =>
            rs.securableID === roleSec.securableID
              ? {
                ...rs
                , read: updatedRoleSec.read
                , update: updatedRoleSec.update
                , delete: updatedRoleSec.delete
                , changeDate: (updatedRoleSec.changeDate ? moment.parseZone(updatedRoleSec.changeDate.toString()) : updatedRoleSec.changeDate)
                , changeUser: updatedRoleSec.changeUser
              }
              : { ...rs }
          )
        );

      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving securable permissions", errorText);
      }
      */

    }
  }

  const jobChanged = async (e: any, allowance: TravelAllowance) => {
    const newValue: number = e.target.value;
    if (newValue && allowance && travelAllowances) {
      // Firstly, take a copy of the item being edited
      let newAllowance = { ...allowance };
      // Set new values based on the input checkbox state
      newAllowance.jobID = newValue;

      // Save the change to the database
      const res: any = await updateTravelAllowanceJob(newAllowance);
      // If all is well there should be some data returned
      if (res.data !== undefined) {
        console.log("Travel Allowance jobId set to: " + allowance.jobID);
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error setting travel allowance job", errorText);
      }
    }
  }

  const allowanceTypeChanged = async (e: any, allowance: TravelAllowance) => {
    const newValue: number = e.target.value;
    if (newValue && allowance && travelAllowances) {
      // Firstly, take a copy of the item being edited
      let newAllowance = { ...allowance };
      // Set new values based on the input checkbox state
      newAllowance.allowanceTypeID = newValue;

      // Setting the allowance type to 'Return Only' automatically sets the isReturning, so...
      // If there are any future allowances and we are setting the isReturning flag (i.e. we are closing the employee travel)
      // then we need to warn the user that these future allowances will be deleted
      if (newValue === TRAVEL_ALLOWANCE_TYPE.ReturnOnly && newAllowance.futureAllowanceCount > 0) {
        setEditedAllowance(newAllowance);
        setUpdateType('AllowanceType');
        setShowDialog(true);
      } else {
        await updateAllowanceType(newAllowance);
      }
    }
  }

  const updateAllowanceReturnStatus = async (allowance: TravelAllowance) => {
    if (allowance && travelAllowances) {
      // Save the change to the database
      const res: any = await updateTravelAllowanceReturnStatus(allowance);
      // If all is well there should be some data returned
      const updatedRoleSec = res.data;
      if (res.data !== undefined) {
        console.log("Travel Allowance return status set to: " + allowance.isReturning);
        // Reflect the new values in the state and therefore the UI
        //setTravelAllowances(
        //  travelAllowances.map((al) =>
        //    al.id === allowance.id
        //      ? {
        //        ...al
        //        , isReturning: allowance.isReturning
        //      }
        //      : { ...al }
        //  )
        //);

      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error closing employee travel", errorText);
      }

    }
  }

  const applyStatusChange = (travelRecord: TravelRecord) => {
    switch (statusChangeAction) {
      case STATUS_CHANGE_ACTION.Approve:
        approveTravelRecord(travelRecord);
        break;
      case STATUS_CHANGE_ACTION.ResetApprovedStatus:
        resetApprovedTravelRecord(travelRecord);
        break;
      case STATUS_CHANGE_ACTION.ResetExportedStatus:
        resetExportedTravelRecord(travelRecord);
        break;
    }
    // Also save any notes - @T4946A
    handleSaveNotes();
  };

  const approveTravelRecord = async (travelRecord: TravelRecord) => {
    if (travelRecord) {
      const editedTravelRecord = { ...travelRecord, statusID: TRAVEL_STATUS.Approved };
      // Save the change to the database
      const res: any = await updateTravelRecordStatus(editedTravelRecord);
      // If all is well there should be some data returned
      if (res.data !== undefined) {
        displayToastMessage("info", "Travel record approved", "Travel record has been successfuly approved");
        navigate(-1);
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error approving travel record", errorText);
      }
    }
  }

  const resetApprovedTravelRecord = async (travelRecord: TravelRecord) => {
    if (travelRecord && travelRecord.statusID === TRAVEL_STATUS.Approved) {
      const editedTravelRecord = { ...travelRecord, statusID: TRAVEL_STATUS.New };
      // Save the change to the database
      const res: any = await updateTravelRecordStatus(editedTravelRecord);
      // If all is well there should be some data returned
      if (res.data !== undefined) {
        displayToastMessage("info", "Travel record reset", "Travel record approved status has been successfuly reset");
        navigate(-1);
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error resetting travel record status", errorText);
      }
    }
  }

  const resetExportedTravelRecord = async (travelRecord: TravelRecord) => {
    if (travelRecord && travelRecord.statusID === TRAVEL_STATUS.Exported) {
      const editedTravelRecord = { ...travelRecord, statusID: TRAVEL_STATUS.Approved };
      // Save the change to the database
      const res: any = await updateTravelRecordStatus(editedTravelRecord);
      // If all is well there should be some data returned
      if (res.data !== undefined) {
        displayToastMessage("info", "Travel record reset", "Travel record export status has been successfuly reset");
        navigate(-1);
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error resetting travel record status", errorText);
      }
    }
  }

  const delTravelRecord = async (travelRecord: TravelRecord) => {
    if (travelRecord) {
      // Save the change to the database
      const res: any = await deleteTravelRecord(travelRecord);
      // If all is well there should be some data returned
      if (res.data !== undefined) {
        displayToastMessage("info", "Travel record deleted", "Travel record has been successfuly deleted");
        navigate(-1);
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error deleting travel record", errorText);
      }
    }
  }

  const updateAllowanceType = async (allowance: TravelAllowance) => {
    if (allowance && travelAllowances) {
      // Save the change to the database
      const res: any = await updateTravelAllowanceType(allowance);
      // If all is well there should be some data returned
      if (res.data !== undefined) {
        console.log("Travel Allowance Type Id set to: " + allowance.allowanceTypeID);
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error setting travel allowance type", errorText);
      }
    }
  }

  const ConfirmSetReturningDialog = (props: any) => {

    return (
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          <span className="dialogHeader">Close Employee Travel</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '16px 0', margin: 'auto' }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <span>Are you sure you want to close this employee's travel record?</span>
                <p>If you continue, allowances for subsequent days will be deleted</p>
              </Grid>
              <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                <Grid item style={{ marginLeft: '10px' }}>
                  <Button
                    className={styles.gridButton}
                    type="button"
                    color="primary"
                    onClick={() => setShowDialog(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item style={{ marginLeft: '10px' }}>
                  <Button
                    className={styles.gridButton}
                    variant="contained"
                    onClick={() => {
                      updateType === 'Return'
                        ? updateAllowanceReturnStatus(props.allowance)
                        : updateAllowanceType(props.allowance);
                      setShowDialog(false);
                    }}
                  >
                    <span>Close Record</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>

    );
  };

  const StatusChangeTitle = (props: any) => {
    if (props.action === STATUS_CHANGE_ACTION.ResetApprovedStatus) {
      return (<span className="dialogHeader">Reset Approved Status</span>);
    } else if (props.action === STATUS_CHANGE_ACTION.ResetExportedStatus) {
      return (<span className="dialogHeader">Reset Export Status</span>);
    } else {
      return (<span className="dialogHeader">Approve Payment</span>);
    }
  };

  const StatusChangeText = (props: any) => {
    if (props.action === STATUS_CHANGE_ACTION.ResetApprovedStatus) {
      return (<div><p>This action will allow further editing of the travel record.</p><br /><span>Reset travel approved status to New?</span></div>);
    } else if (props.action === STATUS_CHANGE_ACTION.ResetExportedStatus) {
      return (<span>Reset export status to Approved?</span>);
    } else {
      return (<span>Do you want to approve allowances for payment?</span>);
    }
  };

  const StatusChangeConfirmButtonText = (props: any) => {
    if (statusChangeAction === STATUS_CHANGE_ACTION.Approve) {
      return (<span>Approve</span>);
    } else {
      return (<span>Reset</span>);
    }
  };

  const ConfirmStatusChangeDialog = (props: any) => {

    return (
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={showStatusChangeDialog}
        onClose={() => setShowStatusChangeDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          <StatusChangeTitle action={statusChangeAction} />
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <StatusChangeText action={statusChangeAction} />
              </Grid>
              <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                <Grid item style={{ marginLeft: '10px' }}>
                  <Button
                    className={styles.gridButton}
                    type="button"
                    onClick={() => setShowStatusChangeDialog(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item style={{ marginLeft: '10px' }}>
                  <Button
                    className={styles.gridButton}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      applyStatusChange(props.travelRecord);
                      setShowStatusChangeDialog(false);
                    }}
                  >
                    <StatusChangeConfirmButtonText action={statusChangeAction} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>

    );
  };

  const ConfirmDeleteTravelDialog = (props: any) => {

    return (
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          <span className="dialogHeader">Delete Travel</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <span>Are you sure you want to delete this travel record?</span>
              </Grid>
              <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                <Grid item style={{ marginLeft: '10px' }}>
                  <Button
                    className={styles.gridButton}
                    type="button"
                    color="primary"
                    onClick={() => setShowDeleteDialog(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item style={{ marginLeft: '10px' }}>
                  <Button
                    className={styles.gridButton}
                    variant="contained"
                    onClick={() => {
                      delTravelRecord(props.travelRecord);
                      setShowDeleteDialog(false);
                    }}
                  >
                    <span>Delete</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>

    );
  };

  const columns = [
    {
      field: 'jobNumber', headerName: 'Job No.', editable: true, width: 160
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Select disabled={!canUpdateTravel} tabIndex={params.hasFocus ? 0 : -1}
          fullWidth
          variant="standard"
          sx={{ height: '80%', backgroundColor: (params.row.jobID > 0 ? '' : 'yellow') }}
          value={params.row.jobID ?? undefined}
          onChange={(e) => jobChanged(e, params.row)}>
          {(jobs ?? []).map((j: Job) => {
            return (
              <MenuItem value={j.id} key={j.id}>{j.jobNumber}</MenuItem>
            );
          })
          }
        </Select>
      ),
    },
    { field: 'employeeNumber', headerName: 'Employee No.', width: 140 },
    { field: 'employeeName', headerName: 'Employee', width: 180 },
    { field: 'homePort', headerName: 'Home Port', width: 180 },
    /*{ field: 'allowanceType', headerName: 'Allowance Type', width: 160 },*/
    { field: 'department', headerName: 'Department', renderCell: RenderCellExpand, width: 200 },
    {
      field: 'allowanceTypeID', headerName: 'Allowance Type', editable: true, width: 160
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Select disabled={!canUpdateTravel} tabIndex={params.hasFocus ? 0 : -1}
          fullWidth
          variant="standard"
          sx={{ height: '80%' }}
          value={params.row.allowanceTypeID}
          onChange={(e) => allowanceTypeChanged(e, params.row)}>
          {(allowanceTypes ?? []).map((j: StandardDataItem) => {
            return (
              <MenuItem value={j.id} key={j.id}>{j.name}</MenuItem>
            );
          })
          }
        </Select>
      ),
    },
    {
      field: 'isReturning', headerName: 'Return/On Travel', type: 'boolean', editable: true, width: 160
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Checkbox disabled={!canUpdateTravel || params.row.allowanceTypeID === 2} tabIndex={params.hasFocus ? 0 : -1}
          checked={params.row.isReturning}
          onChange={(e) => checkChanged(e, params.row)} />
      ),
    },
    { field: 'futureAllowanceCount', headerName: 'Future Allowances', align: 'center' as GridAlignment, width: 140 },
    {
      field: 'changeDate', type: 'dateTime', hide: true
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'changeUser', headerName: 'Updated By', hide: true, width: 150 },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 80, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Tooltip title="Delete" enterDelay={1000}>
          <IconButton disabled={!canUpdateTravel} color="primary" size="small"
            onClick={() => handleDelete(params.row)}>
            <i className="fa fa-regular fa-trash-can" style={{ fontSize: '0.9em' }} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const StatusChangeButtonText = (props: any) => {
    if (props.action === STATUS_CHANGE_ACTION.ResetApprovedStatus) {
      return (<span>Reset Approved</span>);
    } else if (props.action === STATUS_CHANGE_ACTION.ResetExportedStatus) {
      return (<span>Reset Export</span>);
    } else {
      return (<span>Approve</span>);
    }
  };

  return (
    <>
      <Container fluid style={{ paddingLeft: '20px' }}>
          <Row>
            <Stack direction="horizontal" gap={3}>
            <span className='pageHeader'>{travelRecord ? travelRecord.location : ''} Travel {(travelRecord && travelRecord.department) ? ' - ' + travelRecord.department : ''}</span>
              <div className="status" style={{ ...GetStatusColours(travelRecord ? travelRecord.statusID : 0), marginTop: '2px', padding: '4px 1rem', width: 'auto' }} >
                {travelRecord ? travelRecord.status : ''}
              </div>

            </Stack>
          </Row>
          <Row>
            <span style={{ fontWeight: '450', marginTop: '8px' }}>{travelRecordDate ? travelRecordDate.format('dddd D MMMM, YYYY') : ''}</span>
          </Row>
          <Row>
            <Stack direction="horizontal" gap={3}>
              <div>
              </div>
              <div className="ms-auto">
                <Button disabled={!canUpdateTravel} className={styles.gridButton} variant="outlined" onClick={() => handleAdd()}>Add</Button>
              </div>
              <div>
                {/*  <Button className={styles.gridButton} variant="contained" onClick={() => navigate(-1)}>Close</Button>*/}
              </div>
            </Stack>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            {error ? (
              <>Oh no, there was an error</>
            ) : isLoading ? (
              <>Loading...</>
            ) : travelAllowances ? (
              <div style={{ display: 'flex', height: 'calc(60vh)' }}>
                <div style={{ flexGrow: '1' }}>
                  <DataGrid rows={travelAllowances} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id} />
                </div>
              </div>
            ) : null}
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <div>
              <TextField
                label="Notes"
                multiline
                fullWidth
                value={travelRecordNotes ?? ''}
                onChange={(e: any) => setTravelRecordNotes(e.target.value)}
                rows={4}
              />
            </div>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Stack direction="horizontal" gap={3}>
              <div>
                <Button disabled={!canDeleteTravel} className={styles.gridButton} variant="outlined" onClick={() => setShowDeleteDialog(true)}>Delete</Button>
              </div>
              <div className="ms-auto">
                <Button disabled={!canUpdateTravel || !enableTravelRecordSave} className={styles.gridButton} variant="outlined" onClick={() => handleSaveNotes()}>Save Notes</Button>
              </div>
              <div>
                <Button disabled={!enableStatusChange} className={styles.gridButton} variant="contained" onClick={() => setShowStatusChangeDialog(true)}>
                  <StatusChangeButtonText action={statusChangeAction} />
                </Button>
              </div>
            </Stack>
          </Row>

        </Container>

        <ConfirmSetReturningDialog allowance={editedAllowance} />
        <ConfirmStatusChangeDialog travelRecord={travelRecord} />
        <ConfirmDeleteTravelDialog travelRecord={travelRecord} />
        <TravelAllowanceAdd open={showTravelAllowanceAdd} onClose={() => setShowTravelAllowanceAdd(false)}
          travelRecord={travelRecord} travelAllowance={currentAllowance} />
        <TravelAllowanceDelete open={showTravelAllowanceDelete} onClose={() => setShowTravelAllowanceDelete(false)} travelAllowance={currentAllowance} />
    </>
  );
}