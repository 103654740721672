import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'; import { CardActionArea } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";

import { AlertColor } from "@mui/material/Alert";

import { setToastMessage } from '../uiHelpers/toastSlice';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import {
  useGetUserSecurablesQuery, useGetEmployeeLicenceQuery, useUpdateEmployeeLicenceMutation
  , useDeleteEmployeeLicenceClassMutation
} from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'
import { EmployeeSummary, Licence, LicenceClass, LicenceEndorsement } from '../api/types';
import { EmployeeList } from './EmployeeList';
import { EmployeeProfile } from './EmployeeProfile';

import styles from './Licence.module.css'
import { LicenceClassEdit } from "./LicenceClassEdit";
import { LicenceEndorsementEdit } from "./LicenceEndorsementEdit";
import { LicenceClassDelete } from "./LicenceClassDelete";
import { LicenceEndorsementDelete } from "./LicenceEndorsementDelete";


export const LicenceHome = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdateLicence, setCanUpdateLicence] = useState(false);
  const [canDeleteLicence, setCanDeleteLicence] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeSummary>();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();
  const [selectedLicenceClass, setSelectedLicenceClass] = useState<LicenceClass>();
  const [selectedLicenceEndorsement, setSelectedLicenceEndorsement] = useState<LicenceEndorsement>();

  const [showClassEdit, setShowClassEdit] = useState(false);
  const [showClassDelete, setShowClassDelete] = useState(false);
  const [showEndorsementEdit, setShowEndorsementEdit] = useState(false);
  const [showEndorsementDelete, setShowEndorsementDelete] = useState(false);

  const [isLicencePristine, setIsLicencePristine] = useState<boolean>(true);
  const [licenceId, setLicenceId] = useState<number>(0);
  const [licenceNumber, setLicenceNumber] = useState<string>('');
  const [licenceNotes, setLicenceNotes] = useState<string>('');
  const [licenceStatusId, setLicenceStatusId] = useState<number>(0);
  const [licenceStatus, setLicenceStatus] = useState<string>('');
  const [isSuspended, setIsSuspended] = useState<boolean>(false);
  const [suspensionDate, setSuspensionDate] = useState<moment.Moment | null>(null);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: licence, error, isLoading, refetch } = useGetEmployeeLicenceQuery(selectedEmployeeId);
  const [updateEmployeeLicence, { isLoading: isLicenceUpdating }] = useUpdateEmployeeLicenceMutation();
  const [deleteEmployeeLicenceClass, { isLoading: isClassDeleting }] = useDeleteEmployeeLicenceClassMutation();

  // When user securables change (e.g. when they are first retrieved), set UI visibility variables from the securables
  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdateLicence(hasUpdatePermission(userSecurables, [SECURABLE_NAME.DriverLicence]));
      setCanDeleteLicence(hasDeletePermission(userSecurables, [SECURABLE_NAME.DriverLicence]));
    }
  }, [userSecurables])

  useEffect(() => {
    if (licence) {
      setLicenceId(licence.id);
      setLicenceNumber(licence.licenceNumber ?? '');
      setLicenceStatus(licence.status);
      //setLicenceStatusId(licence.suspensionDate ? 2 : 1);
      setIsSuspended(licence.suspensionDate ? true : false);
      setSuspensionDate(licence.suspensionDate ? moment.parseZone(licence.suspensionDate.toString()) : null);
      setLicenceNotes(licence.notes ?? '');
      setIsLicencePristine(true);
    }
  }, [licence])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const onEmployeeSelected = (employee: EmployeeSummary) => {
    //console.log("Employee " + employee.name + " selected");
    setSelectedEmployee(employee);
    if (employee) {
      setSelectedEmployeeId(employee.id);
    }
  };

  const handleLicenceNumberChanged = (e: any) => {
    setIsLicencePristine(e.target.value === licenceNumber);
    setLicenceNumber(e.target.value);
  }

  const handleIsSuspendedChanged = (value: boolean) => {
    setIsLicencePristine(value === isSuspended);
    setIsSuspended(value);
    if (value === false) {
      // 'Current' selected, so clear the suspension date
      setSuspensionDate(null);
    }
    else {
      // 'Suspended' selected.  If not already set, default the suspension date to today
      if (!suspensionDate) {
        setSuspensionDate(moment());
      }
    }
  }

  const handleSuspensionDateChanged = (e: any) => {
    setIsLicencePristine(e === suspensionDate);
    //if (e === null) {
    //  setSuspensionDate(undefined);
    //} else {
    setSuspensionDate(e);
    //}
  }

  const handleLicenceNotesChanged = (e: any) => {
    setIsLicencePristine(e.target.value === licenceNotes);
    setLicenceNotes(e.target.value);
  }

  const handleLicenceClassEdit = async (licenceClass: LicenceClass) => {
    let licenceExists = licenceId > 0;

    if (!licenceExists) {
      // The licence doesn't yet exist so we need to call the save method (which will return false if the save fails)
      licenceExists = await handleSave(false);
    }

    if (licenceExists) {
      setSelectedLicenceClass(licenceClass);
      setShowClassEdit(true);
    }
    //displayToastMessage("warning", "Edit Licence Class", "This feature is not yet implemented");
  }

  const handleLicenceClassDelete = async (licenceClass: LicenceClass) => {
    setSelectedLicenceClass(licenceClass);
    setShowClassDelete(true);
  }

  const handleLicenceEndorsementEdit = async (licenceEndorsement: LicenceEndorsement) => {
    let licenceExists = licenceId > 0;

    if (!licenceExists) {
      // The licence doesn't yet exist so we need to call the save method (which will return false if the save fails)
      licenceExists = await handleSave(false);
    }

    if (licenceExists) {
      setSelectedLicenceEndorsement(licenceEndorsement);
      setShowEndorsementEdit(true);
    }
    //displayToastMessage("warning", "Edit Licence Endorsement", "This feature is not yet implemented");
  }

  const handleLicenceEndorsementDelete = async (licenceEndorsement: LicenceEndorsement) => {
    setSelectedLicenceEndorsement(licenceEndorsement);
    setShowEndorsementDelete(true);
  }

  const handleSave = async (showMessage: boolean = true): Promise<boolean> => {
    let success: boolean = false;
    if (licence) {
      const editedLicence = {
        id: licence.id,
        employeeID: licence.employeeID,
        licenceNumber: licenceNumber,
        suspensionDate: suspensionDate ?? undefined,
        notes: licenceNotes
      };

      const res: any = await updateEmployeeLicence(editedLicence);
      // If all is well there should be some data returned (with a return code)
      const returnValue = res.data;
      if (returnValue !== undefined) {
        // Save the returned licence ID - needed if this is a newly created licence
        setLicenceId(parseInt(returnValue));
        setIsLicencePristine(true);
        if (showMessage) displayToastMessage("info", "", "Licence saved");
        success = true;
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving licence", errorText);
      }
    }
    return success;
  };

  const getClassColour = (licenceClass: LicenceClass, alpha: string = 'ff') => {
    switch (licenceClass.licenceStageID) {
      case 1:
        return (licenceClass.licenceClassID ? '#138cf3' + alpha : 'transparent');
      case 2:
        return (licenceClass.licenceClassID ? '#cbae20' + alpha : 'transparent');
      default:
        return (licenceClass.licenceClassID ? '#1dc122' + alpha : 'transparent');
    };
  };

  const getClassIcon = (licenceClass: LicenceClass) => {
    const fontSize = '22px';
    const activeColour = 'black';
    const inactiveColour = 'gray';

    switch (licenceClass.classID) {
      case 1:
        return (licenceClass.licenceClassID ?
          <i className="fa fa-fw fa-car" style={{ fontSize: fontSize, color: activeColour }} />
          : <i className="fa fa-fw fa-car" style={{ fontSize: fontSize, color: inactiveColour }} />
        );
      case 6:
        return (licenceClass.licenceClassID ?
          <i className="fa fa-fw fa-motorcycle" style={{ fontSize: fontSize, color: activeColour }} />
          : <i className="fa fa-fw fa-motorcycle" style={{ fontSize: fontSize, color: inactiveColour }} />
        );
      default:
        return (licenceClass.licenceClassID ?
          <i className="fa fa-fw fa-truck" style={{ fontSize: fontSize, color: activeColour }} />
          : <i className="fa fa-fw fa-truck" style={{ fontSize: fontSize, color: inactiveColour }} />
        );
    };
  };


  const renderLicenceClass = (licenceClass: LicenceClass) => {
    const expiryDate = licenceClass.expiryDate ? moment.parseZone(licenceClass.expiryDate.toString()) : undefined;

    const toolTipText: string = canUpdateLicence ? (licenceClass.licenceClassID ? "Click to edit" : "Click to add") : "";

    return (
      <Tooltip arrow title={toolTipText} enterDelay={1000}>
        < Card key={licenceClass.classID} sx={{
          border: '#e0e0e0 solid 1px', boxShadow: 'none',
          width: "150px", height: "120px", backgroundColor: getClassColour(licenceClass, '40'), margin: "0 0.8em 0.8em 0"
          , opacity: 0.8
          , transition: '0.05s',
          '&:hover': {
            transform: 'scale(1.05)',
            opacity: 1,
            '.deleteButton': { display: 'inline-flex !important', padding: '2px' }
          },
          '.deleteButton': { display: 'none !important' }
        }
        }>
          <CardActions sx={{ height: '20px' }}>
            {canDeleteLicence && licenceClass.licenceClassID ?
              <Grid xs="auto" justifyContent="flex-end" sx={{ marginLeft: 'auto' }} >
                <IconButton className='deleteButton' onClick={() => handleLicenceClassDelete(licenceClass)}>
                  <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.5em', color: '#b0b0b0' }} />
                </IconButton>
              </Grid>
              : <></>
            }
          </CardActions>
          <CardActionArea onClick={() => canUpdateLicence && handleLicenceClassEdit(licenceClass)} disabled={!canUpdateLicence}
            sx={{ '.MuiCardActionArea-focusHighlight': { backgroundColor: 'transparent' } }} >
            <CardContent sx={{ padding: 0, marginTop: '4px' }}>
              <Stack spacing={0}>
                <div style={{ margin: '0 auto' }}>{getClassIcon(licenceClass)}</div>
                <span style={{ margin: '0 auto', fontWeight: 700 }}>{licenceClass.classShortName}</span>
                <div style={{ margin: '1px auto', fontSize: '12px', fontWeight: '600', padding: '2px 8px', borderRadius: 5, backgroundColor: getClassColour(licenceClass, '80') }}>
                  {licenceClass.licenceStage ? licenceClass.licenceStage.toUpperCase() : <span>&nbsp;</span>}
                </div>
                {licenceClass.expiryDate ? (
                  <Stack style={{ margin: '1px auto', padding: '0 4px', borderRadius: 5, backgroundColor: (licenceClass.isExpired ? 'orangered' : 'transparent') }} direction='row'>
                    <span style={{ fontSize: '11px' }}>{licenceClass.isExpired ? 'Expired' : 'Expires'}</span>
                    <span style={{ marginLeft: '4px', fontSize: '11px' }}>{expiryDate ? expiryDate.format('DD/MM/yyyy') : ''}</span>
                  </Stack>
                ) : <span>&nbsp;</span>
                }
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card >
      </Tooltip >
    );
  };

  const getEndorsementColour = (licenceEndorsement: LicenceEndorsement, alpha: string = 'ff') => {
    return (licenceEndorsement.licenceEndorsementID ? '#138cf3' + alpha : 'transparent');
  };

  const renderLicenceEndorsement = (licenceEndorsement: LicenceEndorsement) => {
    const expiryDate = licenceEndorsement.expiryDate ? moment.parseZone(licenceEndorsement.expiryDate.toString()) : undefined;

    const toolTipText: string = canUpdateLicence ? (licenceEndorsement.licenceEndorsementID ? "Click to edit" : "Click to add") : "";

    return (
      <Tooltip arrow title={toolTipText} enterDelay={1000}>
        <Card key={licenceEndorsement.endorsementID} sx={{
          border: '#e0e0e0 solid 1px', boxShadow: 'none',
          width: "126px", height: "110px", backgroundColor: getEndorsementColour(licenceEndorsement, '40'), margin: "0 0.6em 0.6em 0"
          , opacity: 0.8
          , transition: '0.05s',
          '&:hover': {
            transform: 'scale(1.05)',
            opacity: 1,
            '.deleteButton': { display: 'inline-flex !important', padding: '2px' }
          },
          '.deleteButton': { display: 'none !important' }
        }}>
          <CardActions sx={{ height: '20px' }}>
            {canDeleteLicence && licenceEndorsement.licenceEndorsementID ?
              <Grid xs="auto" justifyContent="flex-end" sx={{ marginLeft: 'auto' }} >
                <IconButton className='deleteButton' onClick={() => handleLicenceEndorsementDelete(licenceEndorsement)}>
                  <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.5em', color: '#b0b0b0' }} />
                </IconButton>
              </Grid>

              : <></>
            }
          </CardActions>
          <CardActionArea onClick={() => canUpdateLicence && handleLicenceEndorsementEdit(licenceEndorsement)} disabled={!canUpdateLicence}
            sx={{ '.MuiCardActionArea-focusHighlight': { backgroundColor: 'transparent' } }} >
            <CardContent sx={{ padding: 0 }}>
              <Stack spacing={0}>
                <div style={{ margin: '0 auto', fontSize: '24px', fontWeight: 700 }}>{licenceEndorsement.endorsementShortName}</div>
                <div style={{ margin: '0 auto', fontSize: '11px' }}>{licenceEndorsement.endorsement}</div>
                {expiryDate ? (
                  <div style={{ margin: '4px auto 0 auto', padding: '0 2px', borderRadius: 5, backgroundColor: (licenceEndorsement.isExpired ? 'orangered' : 'transparent') }}>
                    <Stack direction='row'>
                      <div style={{ fontSize: '11px' }}>{licenceEndorsement.isExpired ? 'Expired' : 'Expires'}:</div>
                      <div style={{ marginLeft: '4px', fontSize: '11px' }}>{expiryDate ? expiryDate.format('DD/MM/yyyy') : ''}</div>
                    </Stack>
                  </div>
                ) : <span>&nbsp;</span>
                }
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Tooltip>
    );
  };

  const renderLicence = (licence: Licence | undefined) => {
    return (
      <div style={{ marginTop: '8px' }}>
        <Grid container>
          <Grid xs={12}>
            <span className='pageHeader'>Driver Licence</span>
          </Grid>

          {licence ?
            <>
              <Grid xs={10} sx={{ marginTop: '8px' }}>
                <Grid container direction="row">
                  <Grid>
                    <Stack spacing={0}>
                      <div style={{ width: '180px' }}>Licence Number</div>
                      <TextField sx={{ width: 'calc(100% - 20px)', backgroundColor: 'white' }}
                        value={licenceNumber}
                        onChange={handleLicenceNumberChanged}
                        disabled={!canUpdateLicence}
                        variant="standard" />
                    </Stack>
                  </Grid>
                  <Grid>
                    <Stack spacing={0}>
                      <div style={{ width: '180px' }}>Status</div>
                      <div style={{ width: 'calc(100% - 20px)', padding: '4px 0 3px 0', borderBottom: 'solid #909090 1px' }}>
                        <span style={{ color: licenceStatus === 'Current' ? 'black' : 'red' }}>{licenceStatus}</span>
                      </div>
                    </Stack>
                  </Grid>
                  <Grid>
                    <FormControlLabel sx={{ marginTop: '16px' }} label="Suspend Licence" control={
                      <Checkbox
                        checked={isSuspended}
                        onChange={(e: any) => handleIsSuspendedChanged(e.target.checked)}
                        disabled={!canUpdateLicence}
                      />
                    } />
                  </Grid>
                  {isSuspended ? (
                    <Grid sx={{ marginLeft: '20px' }}>
                      <Stack spacing={0}>
                        <div>Suspended Date</div>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker value={suspensionDate}
                            onChange={handleSuspensionDateChanged}
                            renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '160px' }} variant="standard" />); }}
                            inputFormat="DD/MM/yyyy"
                            disabled={!canUpdateLicence}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                  ) :
                    <></>}
                </Grid>
              </Grid>

              <Grid xs="auto" justifyContent="flex-end" sx={{ marginTop: '8px', marginLeft: 'auto' }} >
                <Button
                  className={styles.gridButton}
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={isLicenceUpdating || isLicencePristine || !canUpdateLicence}
                  onClick={() => handleSave()}
                >
                  <span>Save</span>
                </Button>
              </Grid>

              <Grid sx={{ marginTop: '24px' }} xs={12}>
                <span style={{ fontWeight: 500 }}>Licence Classes</span>
              </Grid>

              <Grid sx={{ marginTop: '8px' }} container direction="row">
                {licence.licenceClasses.map((l: LicenceClass) => {
                  return renderLicenceClass(l);
                }
                )}
              </Grid>

              <Grid sx={{ marginTop: '16px' }} xs={12}>
                <span style={{ fontWeight: 500 }}>Licence Endorsements</span>
              </Grid>

              <Grid sx={{ marginTop: '8px' }} container direction="row">
                {licence.licenceEndorsements.map((l: LicenceEndorsement) => {
                  return renderLicenceEndorsement(l);
                }
                )}
              </Grid>

              <Grid sx={{ marginTop: '8px' }} xs={12}>
                <Stack spacing={0}>
                  <div>Notes (optional)</div>
                  <TextField
                    sx={{ width: 'calc(60% - 20px)', backgroundColor: 'white', marginTop: '4px' }}
                    multiline
                    rows={3}
                    value={licenceNotes}
                    onChange={handleLicenceNotesChanged}
                    disabled={!canUpdateLicence}
                  />
                </Stack>
              </Grid>
            </>
            : <></>
          }
        </Grid>
      </div>
    )
  };

  return (
    <>
      <Grid container sx={{ padding: 0 }}>
        <Grid sx={{ borderRight: '#e8e8e8 1px solid' }} xs="auto">
          <EmployeeList onSelected={onEmployeeSelected} showPreferredName={false} showActiveSwitch={false} />
        </Grid>

        <Grid sx={{ marginLeft: '8px' }} xs>
          {renderLicence(licence)}
        </Grid>

        <Grid sx={{
          backgroundColor: 'transparent', borderLeft: '#e8e8e8 1px solid', margin: '0 8px', padding: '16px 0 0 16px', width: '260px'
        }} xs="auto">
          <EmployeeProfile employee={selectedEmployee} />
        </Grid>
      </Grid>

      <LicenceClassDelete licenceId={licenceId} licenceClass={selectedLicenceClass} open={showClassDelete} onClose={() => setShowClassDelete(false)} />
      <LicenceEndorsementDelete licenceId={licenceId} licenceEndorsement={selectedLicenceEndorsement} open={showEndorsementDelete} onClose={() => setShowEndorsementDelete(false)} />
      <LicenceClassEdit licenceId={licenceId} licenceClass={selectedLicenceClass} open={showClassEdit} onClose={() => setShowClassEdit(false)} />
      <LicenceEndorsementEdit licenceId={licenceId} licenceEndorsement={selectedLicenceEndorsement} open={showEndorsementEdit} onClose={() => setShowEndorsementEdit(false)} />
    </>
  );
};