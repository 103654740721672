import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CardActionArea, CardHeader } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent } from '@mui/x-data-grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { Moment } from "moment";

import { AlertColor } from "@mui/material/Alert";

import { setToastMessage } from '../uiHelpers/toastSlice';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import {
  useGetUserSecurablesQuery, useGetPropertyAssignedQuery, useUpdatePropertyAssignedReturnedStatusMutation
} from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'
import { EmployeeSummary, PropertyAssigned } from '../api/types';
import { EmployeeList } from '../licence/EmployeeList';
import { EmployeeProfile } from '../licence/EmployeeProfile';
import { PropertyAssignEdit } from './PropertyAssignEdit';
import { PropertyAssignDelete } from './PropertyAssignDelete';

import { RenderCellExpand } from '../uiHelpers/GridCellExpand';
import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './Property.module.css'

const enum PROPERTY_VIEW_TYPE {
  Current = 'Current', Returned = 'Returned'
};

export const PropertyHome = (props: any) => {
  const dispatch = useDispatch();

  const [canReadProperty, setCanReadProperty] = useState(false);
  const [canUpdateProperty, setCanUpdateProperty] = useState(false);
  const [canDeleteProperty, setCanDeleteProperty] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeSummary>();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();
  const [filter, setFilter] = useState<string>('');
  const [tabValue, setTabValue] = useState<PROPERTY_VIEW_TYPE>(PROPERTY_VIEW_TYPE.Current);
  const [selectedPropertyAssigned, setSelectedPropertyAssigned] = useState<Partial<PropertyAssigned> | null>();

  const [showPropertyAssignEdit, setShowPropertyAssignEdit] = useState<boolean>(false);
  const [showPropertyAssignDelete, setShowPropertyAssignDelete] = useState<boolean>(false);


  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawAssignedPropertyItems, error, isLoading, refetch } = useGetPropertyAssignedQuery(selectedEmployeeId ?? 0);
  const [updatePropertyAssignedReturnedStatus, { isLoading: isUpdating }] = useUpdatePropertyAssignedReturnedStatusMutation();

  const [assignedPropertyItems, setAssignedPropertyItems] = useState<PropertyAssigned[]>();

  // When user securables change (e.g. when they are first retrieved), set UI visibility variables from the securables
  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanReadProperty(hasReadPermission(userSecurables, [SECURABLE_NAME.PPE]));
      setCanUpdateProperty(hasUpdatePermission(userSecurables, [SECURABLE_NAME.PPE]));
      setCanDeleteProperty(hasDeletePermission(userSecurables, [SECURABLE_NAME.PPE]));
    }
  }, [userSecurables])

  useEffect(() => {
    let items: PropertyAssigned[] = [];
    if (rawAssignedPropertyItems) {
      // Always filter out inactive 
      items = rawAssignedPropertyItems.filter((ur) => ur.active === true);
      // Filter if required
      if (filter && filter.length > 0) {
        items = items.filter((r) => r.propertyItem.toLowerCase().includes(filter.toLowerCase())
          || (r.purchaseOrder ?? '').toLowerCase().includes(filter.toLowerCase())
          || (r.assetNumber ?? '').toLowerCase().includes(filter.toLowerCase())
        );
      }
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      items = items.map((ur) => {
        let newUr = { ...ur };
        if (newUr.changeDate) newUr.changeDate = moment.parseZone(newUr.changeDate.toString());
        if (newUr.dateIssued) newUr.dateIssued = moment.parseZone(newUr.dateIssued.toString());
        if (newUr.returnedDate) newUr.returnedDate = moment.parseZone(newUr.returnedDate.toString());
        return newUr;
      });
      if (tabValue === PROPERTY_VIEW_TYPE.Current) {
        items = items.filter((r) => r.isReturned === false)
          .sort((a, b) => {
            if (a.dateIssued > b.dateIssued) return -1;
            if (a.dateIssued < b.dateIssued) return 1;
            return 0;
          });
      } else if (tabValue === PROPERTY_VIEW_TYPE.Returned) {
        items = items.filter((r) => r.isReturned === true && r.returnedDate)
          .sort((a, b) => {
            if (a.returnedDate > b.returnedDate) return -1;
            if (a.returnedDate < b.returnedDate) return 1;
            return 0;
          });
      }
    }
    setAssignedPropertyItems(items);
  }, [rawAssignedPropertyItems, filter, tabValue]);

  //const assignedPropertyItems = useMemo(() => {
  //  let items: PropertyAssigned[] = [];
  //  if (rawAssignedPropertyItems) {
  //    // Always filter out inactive 
  //    items = rawAssignedPropertyItems.filter((ur) => ur.active === true);
  //    // Filter if required
  //    if (propertyFilter && propertyFilter.length > 0) {
  //      items = items.filter((r) => r.propertyItem.toLowerCase().includes(propertyFilter.toLowerCase())
  //        || (r.purchaseOrder ?? '').toLowerCase().includes(propertyFilter.toLowerCase())
  //        || (r.assetNumber ?? '').toLowerCase().includes(propertyFilter.toLowerCase())
  //      );
  //    }
  //    // Set dates to be moment objects (they're strings when returned from the web service call...)
  //    items = items.map((ur) => {
  //      let newUr = { ...ur };
  //      if (newUr.changeDate) newUr.changeDate = moment.parseZone(newUr.changeDate.toString());
  //      if (newUr.dateIssued) newUr.dateIssued = moment.parseZone(newUr.dateIssued.toString());
  //      if (newUr.returnedDate) newUr.returnedDate = moment.parseZone(newUr.returnedDate.toString());
  //      return newUr;
  //    });
  //    if (tabValue === PROPERTY_VIEW_TYPE.Current) {
  //      items = items.filter((r) => r.isReturned === false)
  //        .sort((a, b) => {
  //          if (a.dateIssued > b.dateIssued) return -1;
  //          if (a.dateIssued < b.dateIssued) return 1;
  //          return 0;
  //        });
  //    } else if (tabValue === PROPERTY_VIEW_TYPE.Returned) {
  //      items = items.filter((r) => r.isReturned === true && r.returnedDate)
  //        .sort((a, b) => {
  //          if (a.returnedDate > b.returnedDate) return -1;
  //          if (a.returnedDate < b.returnedDate) return 1;
  //          return 0;
  //        });
  //    }
  //  }
  //  return items;
  //}, [rawAssignedPropertyItems, propertyFilter, tabValue])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const onEmployeeSelected = (employee: EmployeeSummary) => {
    //console.log("Employee " + employee.name + " selected");
    setSelectedEmployee(employee);
    setFilter('');
    if (employee) {
      setSelectedEmployeeId(employee.id);
    }
  };

  const handleAdd = () => {
    //displayToastMessage("warning", "Add Assigned Property", "This feature is not yet implemented");
    if (selectedEmployee) {
      setSelectedPropertyAssigned({
        id: 0,
        employeeID: selectedEmployee.id,
        propertyItemID: 0,
        propertyCategoryID: 0,
        dateIssued: moment(),
        purchaseOrder: '',
        assetNumber: '',
        quantity: 1,
        notes: '',
        isReturned: false,
      } as Partial<PropertyAssigned>);
      setShowPropertyAssignEdit(true);
    }
  };

  const handleEdit = (item: PropertyAssigned) => {
    if (item) {
      setSelectedPropertyAssigned(item);
      setShowPropertyAssignEdit(true);
    }
  };

  const handleDelete = (item: PropertyAssigned) => {
    if (item) {
      setSelectedPropertyAssigned(item);
      setShowPropertyAssignDelete(true);
    }
  };

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    event.defaultMuiPrevented = true;
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log("Tab is now set to " + newValue);
    setTabValue(newValue as PROPERTY_VIEW_TYPE);
  };

  const isReturnedChanged = async (e: any, property: PropertyAssigned) => {
    const checked: boolean = e.target.checked;
    if (property) {
      // Firstly, take a copy of the item being edited
      let editedProperty = { ...property };
      // Set new value based on the input checkbox state
      editedProperty.isReturned = checked;

      // Save the change to the database
      const res: any = await updatePropertyAssignedReturnedStatus(editedProperty);
      // If all is well there should be some data returned
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined && assignedPropertyItems) {
        // Success
        setAssignedPropertyItems(
          assignedPropertyItems.map((p) =>
            p.id === property.id
              ? {
                ...p
                , isReturned: editedProperty.isReturned
              }
              : { ...p }
          )
        );
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving returned status", errorText);
      }
    }
  }

  const initialGridState = {
    columns: {
      columnVisibilityModel: {
        // Hide certain columns by default
        purchaseOrder: false,
        assetNumber: false,
      }
    }
  };

  const columns = [
    {
      field: 'dateIssued', type: 'date'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY");
      }
      , headerName: 'Date Issued', width: 130
    },
    { field: 'propertyCategory', headerName: 'Category', flex: 2 },
    { field: 'propertyItem', headerName: 'Item Type', renderCell: RenderCellExpand, flex: 2 },
    { field: 'quantity', headerName: 'Quantity', flex: 1 },
    { field: 'purchaseOrder', headerName: 'PO #', flex: 1 },
    { field: 'assetNumber', headerName: 'Asset #', flex: 1 },
    {
      field: 'isReturned', headerName: 'Returned', type: 'boolean', flex: 1
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Checkbox disabled={!canUpdateProperty}
          checked={params.row.isReturned} tabIndex={params.hasFocus ? 0 : -1}
          onChange={(e) => isReturnedChanged(e, params.row)} />
      )
    },
    {
      field: 'changeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', flex: 2
    },
    { field: 'changeUser', headerName: 'Updated By', flex: 2 },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 100, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Stack direction='row' gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <IconButton disabled={!canReadProperty} color="primary" size="small"
              onClick={() => handleEdit(params.row)}>
              <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" enterDelay={1000}>
            <IconButton disabled={!canDeleteProperty} color="primary" size="small"
              onClick={() => handleDelete(params.row)}>
              <i className="fa fa-regular fa-trash-can" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Grid container sx={{ padding: 0 }}>
        <Grid sx={{ borderRight: '#e8e8e8 1px solid' }} xs="auto">
          <EmployeeList onSelected={onEmployeeSelected} showPreferredName={true} showActiveSwitch={true} />
        </Grid>

        <Grid xs sx={{ margin: '8px 0 0 8px' }}>
          <Grid container>
            <Grid xs={12}>
              <span className='pageHeader'>PPE & Company Property Issued</span>
            </Grid>
            {selectedEmployee ? (
              <>
                <Grid xs={10} sx={{ marginTop: '8px' }}>
                  <Grid container direction="row">
                    <Grid>
                      <div style={{ marginRight: '8px' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                          <Tab className={styles.tabButton} label={PROPERTY_VIEW_TYPE.Current} value={PROPERTY_VIEW_TYPE.Current} />
                          <Tab className={styles.tabButton} label={PROPERTY_VIEW_TYPE.Returned} value={PROPERTY_VIEW_TYPE.Returned} />
                        </Tabs>
                      </div>
                    </Grid>
                    <Grid>
                      <TextField sx={{ marginTop: '8px', minWidth: '240px' }}
                        placeholder="search"
                        value={filter}
                        onChange={(e: any) => setFilter(e.target.value)}
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" sx={{ margin: '4px' }}>
                              <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            < InputAdornment position="end" >
                              <IconButton size='small'
                                onClick={() => setFilter('')}
                              >
                                <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.7em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs="auto" justifyContent="flex-end" sx={{ marginLeft: 'auto', marginTop: '16px' }} >
                  <Button disabled={!canUpdateProperty} className={styles.gridButton} variant="outlined" onClick={() => handleAdd()}>Add</Button>
                </Grid>

                <Grid sx={{ marginTop: 0 }} xs={12}>
                  {error ? (
                    <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
                  ) : isLoading ? (
                    <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
                  ) : assignedPropertyItems ? (
                    <div style={{ display: 'flex', height: 'calc(100vh - 152px)' }}>
                      <div style={{ flexGrow: '1' }}>
                        <DataGrid rows={assignedPropertyItems} columns={columns} initialState={initialGridState}
                          rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                          onCellClick={handleGridCellClick} />
                      </div>
                    </div>
                  ) : null}
                </Grid>
              </>
            ) : <></>}
          </Grid>
        </Grid>

        <Grid sx={{
          backgroundColor: 'transparent', borderLeft: '#e8e8e8 1px solid', margin: '0 8px', padding: '16px 0 0 16px', width: '260px'
        }} xs="auto">
          <EmployeeProfile employee={selectedEmployee} />
        </Grid>

      </Grid>

      <PropertyAssignEdit employee={selectedEmployee} propertyAssigned={selectedPropertyAssigned} open={showPropertyAssignEdit} onClose={() => setShowPropertyAssignEdit(false)} />
      <PropertyAssignDelete open={showPropertyAssignDelete} onClose={() => setShowPropertyAssignDelete(false)} propertyAssigned={selectedPropertyAssigned} />
    </>
  );
};