import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { TRAVEL_STATUS } from '../api/types';
import { GetStatusColours } from '../travel/TravelHome';

import { useGetUserSecurablesQuery, useGetMetricTravelPendingApprovalQuery } from '../api/apiSlice';
import { hasReadPermission, SECURABLE_NAME } from '../userProfile/securableHelper'


export const WidgetTravelPendingApproval = (props: any) => {
  const [canRead, setCanRead] = useState<boolean>(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: pendingApproval, refetch: refetchData } = useGetMetricTravelPendingApprovalQuery(props.age);

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanRead(hasReadPermission(userSecurables, [SECURABLE_NAME.Travel]));
    }
  }, [userSecurables])

  useEffect(() => {
    refetchData();
  }, [props.age])

  const width: number = props.width ?? 300;
  const height: number = props.height ?? 300;

  return (
    <>
      {
        canRead ?
          <div style={{ backgroundColor: '#ffffff', border: '#f0f0f0 solid 1px', borderRadius: '5px', width: `${width}px`, height: `${height}px` }}>
            <Stack>
              <Grid container>
                <Grid xs={1}>
                </Grid>
                <Grid xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <span className="pageHeader">Travel Pending Approval</span>
                </Grid>
                <Grid xs={1}>
                  <Tooltip title="Reload data" enterDelay={1000}>
                    <IconButton color="primary" size="small" sx={{ margin: '8px 0' }}
                      onClick={() => refetchData()}>
                      <i className="fa fa-fw fa-rotate" style={{ fontSize: '0.9em' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <span style={{ margin: '0 20px 0 20px', textAlign: 'center' }}>Travel records in 'New' state, pending approval over the last {props.age} days</span>
                </Grid>
                <Grid xs={12} sx={{ display: 'flex', height: `${height - 100}px`, alignItems: 'center', justifyContent: 'center' }}>
                  <span style={{ fontSize: '48px', fontWeight: 'bold', color: GetStatusColours(TRAVEL_STATUS.New)?.color }}>
                    {pendingApproval ?? 0}
                  </span>
                </Grid>
              </Grid>
            </Stack>
          </div>
          : <></>
      }
    </>
  );
}

