import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Form, Field, FieldRenderProps } from 'react-final-form'
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';

import { Role } from '../api/types';
import { useGetRoleSecurablesQuery, useUpdateRoleMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessage, ToastMessageValue } from '../uiHelpers/ToastMessage';
import { TextFieldWrapper } from '../uiHelpers/FormFieldWrappers';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './UserAccess.module.css'

export const RoleEdit = (props: any) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState<Role | null>(null);

  const [updateRole, result] = useUpdateRoleMutation();
  const { data: roleSecurables } = useGetRoleSecurablesQuery();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();
  const handleSave = async (editedRole: Partial<Role>) => {
    if (editedRole) {
      const res: any = await updateRole(editedRole);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        displayToastMessage("info", "", "Role saved");
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving role", errorText);
      }
    }
  };

  useEffect(() => {
    console.log("UseEffect is setting role...");
    setRole(props.role);
  }, [props.role]);

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    return errors;
  };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "360px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {role && role.id === 0 ?
            <span className="dialogHeader">Add role</span>
            :
            <span className="dialogHeader">Edit role</span>
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '16px 0', margin: 'auto' }}>
            <Form
              onSubmit={handleSave}
              initialValues={role}
              validate={validate}
              render={({ handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <span>Role Name</span>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        required
                        name="name"
                        type="text"
                        variant="standard"
                        component={TextFieldWrapper}
                      />
                    </Grid>
                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          type="button"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={submitting}
                        >
                          {role && role.id === 0 ?
                            <span>Add</span>
                            :
                            <span>Update</span>
                          }
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

  /*
    return (
      <>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ width: '350px' }}>
            Edit role
          </DialogTitle>
          <DialogContent>
            <Container fluid>
              <Row>
                <span style={{ fontSize: '1.3em', fontWeight: '450', marginTop: '8px' }}>Edit role: {role ? role.name : ''}</span>
              </Row>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave} autoFocus>Save</Button>
            <Button onClick={handleClose} autoFocus>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
    */
};