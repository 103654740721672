import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createFilterOptions } from '@mui/material/Autocomplete';
import * as moment from 'moment';
import { Moment } from "moment";

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { WorkVisa, EmployeeSummary, Country, WorkVisaType } from '../api/types';
import {
  useGetUserSecurablesQuery, useUpdateWorkVisaMutation, useGetEmployeesQuery, useGetCountriesQuery, useGetVisaTypesQuery
} from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';

import { setToastMessage } from '../uiHelpers/toastSlice';

//import styles from './Licence.module.css'

export const WorkVisaEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawEmployees } = useGetEmployeesQuery({ includeInactive: false, maxInactiveDays: 90 });
  const { data: rawCountries } = useGetCountriesQuery();
  const { data: rawVisaTypes } = useGetVisaTypesQuery();

  const [updateWorkVisa, { isLoading: isUpdating }] = useUpdateWorkVisaMutation();


  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.DriverLicence]));
    }
  }, [userSecurables])

  const countries = useMemo(() => {
    let records: Country[] = [];
    if (rawCountries) {
      records = rawCountries.filter((r) => r.active === true);
    }
    return records;
  }, [rawCountries]);

  const employees = useMemo(() => {
    let records: EmployeeSummary[] = [];

    if (rawEmployees) {
      records = rawEmployees.filter((r) => r.active === true);
    }
    return records;
  }, [rawEmployees]);

  const visaTypes = useMemo(() => {
    let records: WorkVisaType[] = [];

    if (rawVisaTypes) {
      records = rawVisaTypes.filter((r) => r.active === true);
    }
    return records;
  }, [rawVisaTypes]);

  // Formik validation schema
  const validationSchema = yup.object().shape({
    employeeID: yup.number()
      .min(1)
      .required('Employee is required'),
    countryID: yup.number()
      .min(1)
      .required('Nationality is required'),
    visaTypeID: yup.number()
      .min(1)
      .required('Visa type is required'),
    expiryDate: yup.date().nullable(true)
      .test(
        'Expiry date test',
        'Expiry Date is required',
        (value, context) => {
          if (visaTypes && context.parent.visaTypeID) {
            const visaType = visaTypes.find((t) => t.id === context.parent.visaTypeID);
            if (visaType && visaType.isExpiryDateRequired) {
              return (value !== undefined && value !== null);
            }
            return true;
          }
          return true;
        }
      ),
  });

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<WorkVisa>) => {
    if (newValues) {
      // Force employeeID to 0 so that we don't change assignment on save
      const res: any = await updateWorkVisa(newValues);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        displayToastMessage("info", "Success", "Work visa saved");
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving work visa", errorText);
      }

    }
  };

  const captionStyle = { margin: '6px 0 4px 0' };

  // @T4888A
  const employeeFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.label + ' ' + option.additionalFilterData,
  });

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "400px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.workVisa && props.workVisa.id && props.workVisa.id > 0 ? (
            <span className="dialogHeader">Update Work Visa</span>
          )
            : (
              <span className="dialogHeader">Add Work Visa</span>
            )
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={props.workVisa} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Stack>
                    <div>
                      <Grid container spacing={2}>

                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>Employee</span>
                            <AutocompleteWrapper
                              value={values?.employeeID}
                              onChange={(e: any, value: any) => {
                                if (value) {
                                  setFieldValue("employeeID", value, true);
                                }
                              }
                              }
                              renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                              options={employees ? employees.map((j) => {
                                return {
                                  label: (j.name + ' [' + j.employeeNumber + ']') // Display data
                                  , value: j.id
                                  , additionalFilterData: j.legalName // Additional filterable data - @T4888A
                                };
                              }) : []}
                              filterOptions={employeeFilterOptions}
                              disabled={!canUpdate}
                            />
                          </Stack>
                        </Grid>

                        <Grid item xs={7}>
                          <Stack>
                            <span style={captionStyle}>Passport Nationality</span>
                            <AutocompleteWrapper
                              value={values?.countryID}
                              onChange={(e: any, value: any) => { if (value) { setFieldValue("countryID", value, true); } }}
                              renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                              options={countries ? countries.map((j) => { return { label: j.name, value: j.id }; }) : []}
                              disabled={!canUpdate}
                            />
                          </Stack>
                        </Grid>

                        <Grid item xs={5}>
                          <Stack>
                            <span style={captionStyle}>Passport Number</span>
                            <TextField value={values.passportNumber}
                              name="passportNumber"
                              onChange={handleChange}
                              disabled={!canUpdate}
                              variant="standard"
                            />
                          </Stack>
                        </Grid>

                        <Grid item xs={7}>
                          <Stack>
                            <span style={captionStyle}>Visa Type</span>
                            <AutocompleteWrapper
                              value={values?.visaTypeID}
                              onChange={(e: any, value: any) => { if (value) { setFieldValue("visaTypeID", value, true); } }}
                              renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                              options={visaTypes ? visaTypes.map((j) => { return { label: j.name, value: j.id }; }) : []}
                              disabled={!canUpdate}
                            />
                          </Stack>
                        </Grid>

                        <Grid item xs={5}>
                          <Stack>
                            <span style={captionStyle}>Visa Expiry</span>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker value={values.expiryDate}
                                onChange={(value) => setFieldValue("expiryDate", value, true)}
                                renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                                inputFormat="DD/MM/yyyy"
                                disabled={!canUpdate}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>Notes / Conditions</span>
                            <TextField value={values.notes}
                              sx={{ width: '100%' }}
                              name="notes"
                              multiline
                              rows={3}
                              onChange={handleChange}
                              disabled={!canUpdate}
                            />
                          </Stack>
                        </Grid>

                      </Grid>
                    </div>
                    <div style={{ height: '42px', marginTop: '12px' }}>
                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className="gridButton"
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className="gridButton"
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!canUpdate || isSubmitting || !isValid || !dirty}
                          >
                            {props.workVisa && props.workVisa.id && props.workVisa.id > 0 ? (
                              <span>Update</span>
                            )
                              : (
                                <span>Add</span>
                              )
                            }
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Stack>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};