// MSAL configuration settings come from the runtime-config.js file, loaded in index.html from the base URL directory
const { clientId, tenantId, redirectUri, apiScopes } = window['runConfig'];

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: "https://login.microsoftonline.com/" + tenantId, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: redirectUri
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

//export const msalConfig = {
//  auth: {
//    clientId: "0223b526-81be-44ec-9aca-8d492cb5813a",
//    authority: "https://login.microsoftonline.com/90a7e1f9-ed7b-4a68-86f7-88958c3de0ac", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//    redirectUri: "https://localhost:5001"
//  },
//  cache: {
//    cacheLocation: "sessionStorage", // This configures where your cache will be stored
//    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//  }
//};

// NOTE: We use separate login configurations for the MS Graph and MahiTahi calls.
//       The reason for this is that the presence of MS Graph API scope "User.Read" results in a "nonce" value in the JWT header
//       and this "nonce" causes the MahiTahi Web API to reject the JWT with an "Invalid signature" error (and HTTP 401) !!!!

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequestMSGraph = {
  scopes: ["User.Read"]
};

// Add scopes here for ID token to be used Mahi Tahi Web API endpoints.
export const loginRequestMahiTahi = {
  scopes: apiScopes
};
//export const loginRequestMahiTahi = {
//  scopes: ["api://68b27eee-c053-492f-986e-534a2377ecf2/access_as_user"]
//};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMePhoto: "https://graph.microsoft.com/v1.0/me/photos/48x48/$value"
};