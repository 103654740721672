import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as moment from 'moment';
import { Moment } from "moment";

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Vehicle, VehicleMake, VehicleModel, EmployeeSummary, StandardDataItem } from '../api/types';
import {
  useGetUserSecurablesQuery, useGetVehicleMakesQuery, useGetVehicleModelsQuery, useUpdateVehicleAndAssignmentMutation
  , useGetVehiclePrivateUsageTypesQuery
} from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';

import { setToastMessage } from '../uiHelpers/toastSlice';

//import styles from './Licence.module.css'
import { Stack } from "@mui/material";

export const VehicleEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawVehicleModels } = useGetVehicleModelsQuery();
  const { data: rawVehicleMakes } = useGetVehicleMakesQuery();

  const [updateVehicleAndAssignment, { isLoading: isUpdating }] = useUpdateVehicleAndAssignmentMutation();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.DriverLicence]));
    }
  }, [userSecurables])

  const vehicleMakes = useMemo(() => {
    let records: VehicleMake[] = [];
    if (rawVehicleMakes) {
      records = rawVehicleMakes.filter((r) => r.active === true);
    }
    return records;
  }, [rawVehicleMakes]);

  const vehicleModels = useMemo(() => {
    let records: VehicleModel[] = [];
    if (rawVehicleModels) {
      records = rawVehicleModels.filter((r) => r.active === true);
    }
    return records;
  }, [rawVehicleModels]);

  // Formik validation schema 
  const validationSchema = yup.object().shape({
    year: yup.date()
      .required('Year is required'),
    registration: yup.string()
      .required('Registration is required'),
    vehicleModelID: yup.number()
      .min(1)
      .required('Make and Model are required'),
    purchaseDate: yup.date()
      .required('Purchase Date is required'),
    assetNumber: yup.string()
      .required('Asset Number is required'),
  });

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<Vehicle>) => {
    if (newValues) {
      // Force employeeID to 0 so that we don't change assignment on save
      const res: any = await updateVehicleAndAssignment({ ...newValues, employeeID: 0 });
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        // Look for a warning message
        if (returnCode.warning && returnCode.warning.length > 0) {
          displayToastMessage("warning", "Vehicle saved", returnCode.warning);
        } else {
          displayToastMessage("info", "Success", "Vehicle saved");
        }
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving vehicle", errorText);
      }

    }
  };

  const captionStyle = { margin: '6px 0 4px 0' };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="dialogHeader">Update Vehicle Details</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={props.vehicle} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Stack>
                    <div>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>Registration</span>
                            <TextField value={values.registration}
                              name="registration"
                              onChange={handleChange}
                              disabled={!canUpdate}
                              variant="standard"
                              sx={{ width: '180px' }}
                            />
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>Year</span>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker value={values.year}
                                onChange={(value) => setFieldValue("year", value, true)}
                                openTo="year"
                                views={["year"]}
                                renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                                inputFormat="yyyy"
                                disabled={!canUpdate}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Grid>

                        <Grid item xs={5}>
                          <Stack>
                            <span style={captionStyle}>Make</span>
                            <AutocompleteWrapper
                              value={values?.vehicleMakeID}
                              onChange={(e: any, value: any) => {
                                if (value) {
                                  setFieldValue("vehicleMakeID", value, true);
                                  setFieldValue("vehicleModelID", 0, true);
                                }
                              }
                              }
                              renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                              options={vehicleMakes ? vehicleMakes.map((j) => { return { label: j.name, value: j.id }; }) : []}
                              disabled={!canUpdate}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={7}>
                          <Stack>
                            <span style={captionStyle}>Model</span>
                            <AutocompleteWrapper
                              value={values?.vehicleModelID}
                              onChange={(e: any, value: any) => { if (value) setFieldValue("vehicleModelID", value, true); }}
                              renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                              options={values.vehicleMakeID && vehicleModels ?
                                vehicleModels.filter((p) => p.vehicleMakeID === values.vehicleMakeID).map((j) => { return { label: j.name, value: j.id }; })
                                : []}
                              disabled={!canUpdate}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>Date Purchased</span>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker value={values.purchaseDate}
                                onChange={(value) => setFieldValue("purchaseDate", value, true)}
                                renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                                inputFormat="DD/MM/yyyy"
                                disabled={!canUpdate}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>Asset Number</span>
                            <TextField value={values.assetNumber}
                              name="assetNumber"
                              onChange={handleChange}
                              disabled={!canUpdate}
                              variant="standard"
                              sx={{ width: '180px' }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>Notes</span>
                            <TextField value={values.notes}
                              sx={{ width: '100%' }}
                              name="notes"
                              multiline
                              rows={3}
                              onChange={handleChange}
                              disabled={!canUpdate}
                            />
                          </Stack>
                        </Grid>

                      </Grid>
                    </div>
                    <div style={{ height: '42px', marginTop: '12px' }}>
                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className="gridButton"
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className="gridButton"
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting || !isValid || !dirty}
                          >
                            <span>Update</span>
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Stack>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};