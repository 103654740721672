import * as React from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const TextFieldWrapper = (props: any) => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    ...rest
  } = props;
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <TextField
      {...rest}
      name={name}
      helperText={showError ? meta.error || meta.submitError : ' '}
      error={showError}
      inputProps={restInput}
      onChange={onChange}
      value={value}
    />
  );
};


export const SelectWrapper = (props: any) => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    label,
    formControlProps,
    ...rest
  } = props;
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <FormControl {...formControlProps} error={showError}>
      <InputLabel htmlFor={name}>{label}</InputLabel>

      <Select
        {...rest}
        name={name}
        onChange={onChange}
        inputProps={restInput}
        value={value}
      />

        <FormHelperText>{showError ? meta.error || meta.submitError : ' '}</FormHelperText>
    </FormControl>
  );
};

export const AutocompleteWrapper = (props: any) => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    label,
    formControlProps,
    options,
    textLabel,
    ListboxProps,
    renderInput,
    ...rest
  } = props;
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  const defaultRenderInput = (params: any) => { return ( <TextField {...params}  variant="standard" /> ); };

  return (
    <FormControl {...formControlProps} error={showError}>
      <InputLabel htmlFor={name}>{label}</InputLabel>

      {/* NOTE: Couldn't find a way to set the list box font size in the theme (in App.js) so having to set here... */}
      <Autocomplete
        {...rest}
        name={name}
        onChange={(e: any, data: any) => onChange(data ? data.value : 0)}
        options={options}
        value={options && value ? options.find((o: any) => o.value === value) : ''}
        ListboxProps={{ ...ListboxProps, sx: { fontSize: '13px' } }}
        isOptionEqualToValue={(option: any, value: any) => option.value === value}
        renderInput={renderInput ?? defaultRenderInput}
      />

      <FormHelperText>{showError ? meta.error || meta.submitError : ' '}</FormHelperText>
    </FormControl>
  );
};

export const RadioWrapper = (props: any) => {
  const {
    input: {checked, value, name, onChange, ...restInput},
    meta,
    ...rest
  } = props;

  return (
	  <Radio
		  {...rest}
		  name={name}
		  inputProps={restInput}
		  onChange={onChange}
		  checked={checked}
		  value={value}
	  />
  );
};

export const RadioGroupWrapper = (props: any) => {
  const {
    input: { value, name, onChange, ...restInput },
    meta,
    radioButtons,
    ...rest
  } = props;

  return (
    <RadioGroup
      {...rest}
      name={name}
      onChange={onChange}
      value={value}
    >
      {radioButtons()}
    </RadioGroup>
  );
};

export const CheckboxWrapper = (props: any) => {
  const {
    input: {value, name, onChange, ...restInput},
    meta,
    label,
    disabled,
    ...rest
  } = props;

  return (
    <FormControlLabel control={
	    <Checkbox
		    {...rest}
		    name={name}
		    inputProps={restInput}
		    onChange={onChange}
        checked={value}
        disabled={disabled}
	    />
    } label={label} />
  );
};

export const DatePickerWrapper = (props: any) => {
  const {
    input: { value, name, onChange, ...restInput },
    meta,
    formControlProps,
    label,
    disabled,
    inputFormat,
    renderInput,
    ...rest
  } = props;

  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  const defaultRenderInput = (params: any) => { return (<TextField {...params} variant="standard" />); };
  const defaultInputFormat = "DD/MM/yyyy";

  return (
    <FormControl {...formControlProps} error={showError}>
      <InputLabel htmlFor={name}>{label}</InputLabel>

      <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        {...rest}
        name={name}
        inputProps={restInput}
        onChange={onChange}
        value={value}
        disabled={disabled}
        renderInput={renderInput ?? defaultRenderInput}
        inputFormat={inputFormat ?? defaultInputFormat}
        />
      </LocalizationProvider>
      <FormHelperText>{showError ? meta.error || meta.submitError : ' '}</FormHelperText>
    </FormControl>
  );
};
