import * as React from "react";
import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { Grow } from "@mui/material";

import { useGetUserSecurablesQuery } from '../api/apiSlice';
import { Role, RoleSecurable } from '../api/types';
import { hasReadPermission, SECURABLE_NAME } from '../userProfile/securableHelper'


export const UserHome = (props: any) => {
  const [canReadUser, setCanReadUser] = useState(false);
  const [canReadRole, setCanReadRole] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanReadUser(hasReadPermission(userSecurables, [SECURABLE_NAME.User]));
      setCanReadRole(hasReadPermission(userSecurables, [SECURABLE_NAME.Role]));
    }
  }, [userSecurables])

  const handleNavigate = (target: string) => {
    console.log("handleNavigate: " + target);
    const to = '/user/' + target;
    if (window.location.pathname !== to) {
      navigate(to);
    }
  };

  const cardStyle = { border: '#e0e0e0 solid 1px', boxShadow: 'none', minWidth: "230px", minHeight: "60px", backgroundColor: "#f0f0f0", margin: "0 2em 2em 0" };

  return (
    <>
      <Container fluid style={{paddingLeft: '20px', height: 'calc(100vh - 40px)'}}>
        <Row>
          <span className='pageHeader' style={{ marginBottom: '10px' }}>User Management</span>
        </Row>
        <Row>
          <Stack direction="horizontal">
            {canReadUser ?
              <Grow in={true}>
                <Card sx={cardStyle}>
                  <CardActionArea onClick={() => handleNavigate('userManagement')}>
                    <CardContent>
                      <i className="fa fa-fw fa-users" style={{ fontSize: '1.5em', color: '#404040' }} />
                      <span style={{ marginLeft: "10px" }}>Users</span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grow>
              : <></>
            }
            {canReadRole ?
              <Grow in={true}>
                <Card sx={cardStyle}>
                  <CardActionArea onClick={() => handleNavigate('roles')}>
                    <CardContent>
                      <i className="fa fa-fw fa-user-tag" style={{ fontSize: '1.5em', color: '#404040' }} />
                      <span style={{ marginLeft: "10px" }}>Roles</span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grow>
              : <></>
            }
            {canReadRole ?
              <Grow in={true}>
                <Card sx={cardStyle}>
                  <CardActionArea onClick={() => handleNavigate('roleSecurables')}>
                    <CardContent>
                      <i className="fa fa-fw fa-user-lock" style={{ fontSize: '1.5em', color: '#404040' }} />
                      <span style={{ marginLeft: "10px" }}>Role Permissions</span>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grow>
              : <></>
            }
          </Stack>
        </Row>
      </Container>
    </>
  );
}