import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import { AlertColor } from "@mui/material/Alert";
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent } from '@mui/x-data-grid';
import * as moment from 'moment';
import { Moment } from "moment";

import { VehicleEdit } from './VehicleEdit';
import { VehicleUnavailabilityEdit } from './VehicleUnavailabilityEdit';
import { VehicleUnavailabilityDelete } from './VehicleUnavailabilityDelete';
import { VehicleAssignmentEdit } from './VehicleAssignmentEdit';
import { VehicleUnassign } from './VehicleUnassign';
import { Vehicle, VehicleUnavailability, VehicleAssignment } from '../api/types';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';
import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';

import { useGetUserSecurablesQuery, useGetVehiclesQuery, useGetVehicleUnavailabilitiesQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'

export const VehicleUnavailabilityHome = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawVehicles } = useGetVehiclesQuery();
  const { data: rawVehicleUnavailabilities, error, isLoading } = useGetVehicleUnavailabilitiesQuery(parseInt(id ?? '0'));

  const [canRead, setCanRead] = useState<boolean>(false);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [vehicle, setVehicle] = useState<Vehicle | null>();
  const [vehicleAssignment, setVehicleAssignment] = useState<Partial<VehicleAssignment>>({});
  const [selectedItem, setSelectedItem] = useState<Partial<VehicleUnavailability> | null>();
  const [showItemDelete, setShowItemDelete] = useState<boolean>(false);
  const [showItemEdit, setShowItemEdit] = useState<boolean>(false);
  const [showVehicleEdit, setShowVehicleEdit] = useState<boolean>(false);
  const [showVehicleAssignmentEdit, setShowVehicleAssignmentEdit] = useState<boolean>(false);
  const [showVehicleUnassign, setShowVehicleUnassign] = useState<boolean>(false);

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanRead(hasReadPermission(userSecurables, [SECURABLE_NAME.VehicleRegister]));
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.VehicleRegister]));
      setCanDelete(hasDeletePermission(userSecurables, [SECURABLE_NAME.VehicleRegister]));
    }
  }, [userSecurables])

  const vehicles = useMemo(() => {
    let records: Vehicle[] = [];
    if (rawVehicles) {
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = rawVehicles.map((tr) => {
        let newTr = { ...tr };
        if (newTr.year) newTr.year = moment.parseZone(newTr.year.toString());
        if (newTr.purchaseDate) newTr.purchaseDate = moment.parseZone(newTr.purchaseDate.toString());
        if (newTr.assignedDate) newTr.assignedDate = moment.parseZone(newTr.assignedDate.toString());
        if (newTr.changeDate) newTr.changeDate = moment.parseZone(newTr.changeDate.toString());
        return newTr;
      });
    }
    return records;
  }, [rawVehicles]);

  useEffect(() => {
    if (vehicles && vehicles.length > 0 && id) {
      setVehicle(vehicles.find((v) => v.id === parseInt(id ?? '0')));
    }
  }, [vehicles, id])

  useEffect(() => {
    if (vehicle) {
      //  Create a vehicle assignment from the relevant vehicle properties
      setVehicleAssignment({
        id: vehicle.vehicleAssignmentID ?? 0,
        vehicleID: vehicle.id,
        employeeID: vehicle.employeeID ?? 0,
        vehiclePrivateUsageID: vehicle.vehiclePrivateUsageID ?? 0,
        fromDate: vehicle.assignedDate,
      });
    }
  }, [vehicle])

  const vehicleUnavailabilities = useMemo(() => {
    let records: VehicleUnavailability[] = [];
    if (rawVehicleUnavailabilities) {
      records = rawVehicleUnavailabilities.filter((r) => r.active === true);
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = records.filter((r) => r.vehicleUnavailabilityType.toLowerCase().includes(filter.toLowerCase()))
        .map((tr) => {
          let newTr = { ...tr };
          if (newTr.fromDate) newTr.fromDate = moment.parseZone(newTr.fromDate.toString());
          if (newTr.toDate) newTr.toDate = moment.parseZone(newTr.toDate.toString());
          if (newTr.createDate) newTr.createDate = moment.parseZone(newTr.createDate.toString());
          if (newTr.changeDate) newTr.changeDate = moment.parseZone(newTr.changeDate.toString());
          return newTr;
        });
    }
    return records;
  }, [rawVehicleUnavailabilities, filter]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleAdd = () => {
    setSelectedItem({ id: 0, vehicleID: parseInt(id ?? '0'), vehicleUnavailabilityTypeID: 0, fromDate: null, toDate: null });
    setShowItemEdit(true);
  };

  const handleEdit = (item: VehicleUnavailability) => {
    if (item) {
      setSelectedItem(item);
      setShowItemEdit(true);
    }
  };

  const handleDelete = (item: VehicleUnavailability) => {
    if (item) {
      setSelectedItem(item);
      setShowItemDelete(true);
    }
  };

  const handleEditVehicle = () => {
    if (vehicle) {
      setShowVehicleEdit(true);
    }
  };

  const handleEditVehicleAssignment = () => {
    if (vehicleAssignment) {
      setShowVehicleAssignmentEdit(true);
    }
  };

  const handleAssignVehicle = () => {
    if (vehicle && vehicle.vehicleAssignmentID > 0) {
      // It's an unassign
      setShowVehicleUnassign(true);
      // TODO

    } else {
      // It's an assign
      if (vehicleAssignment) {
        setShowVehicleAssignmentEdit(true);
      }
    }
  };

  const handleViewAssignmentHistory = () => {
    if (id) {
      const to = '/vehicle/vehicleAssignmentHistory/' + parseInt(id ?? '0');
      if (window.location.pathname !== to) {
        navigate(to);
      }
    }
  };

  const columns = [
    {
      field: 'fromDate', type: 'date'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY");
      }
      , headerName: 'Start Date', width: 120
    },
    {
      field: 'toDate', type: 'date'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY");
      }
      , headerName: 'End Date', width: 120
    },
    { field: 'vehicleUnavailabilityType', headerName: 'Reason', width: 260 },
    {
      field: 'createDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Entered on', width: 180
    },
    {
      field: 'changeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'changeUser', headerName: 'Updated By', width: 220 },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 100, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Stack direction='row' gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <IconButton disabled={!canUpdate} color="primary" size="small"
              onClick={() => handleEdit(params.row)}>
              <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={params.row.active ? 'Delete' : 'Activate'} enterDelay={1000}>
            <IconButton disabled={params.row.active ? !canDelete : !canUpdate} color="primary" size="small"
              onClick={() => handleDelete(params.row)}>
              <i className={params.row.active ? 'fa fa-regular fa-trash-can' : 'fa fa-solid fa-trash-arrow-up'} style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>

        </Stack>
      ),
    },
  ];

  return (
    <>
      <Grid container sx={{ height: 'calc(100vh - 40px)', padding: 0 }}>
        <Grid xs sx={{ margin: '8px 16px' }}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <span className='pageHeader'>Vehicle Unavailability</span>
            </Grid>
            <Grid sx={{ display: 'flex ' }} xs={6}>
              <TextField sx={{ verticalAlign: 'bottom', marginTop: '4px', minWidth: '280px' }}
                placeholder="search"
                value={filter}
                onChange={(e: any) => setFilter(e.target.value)}
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ margin: '4px' }}>
                      <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    < InputAdornment position="end" >
                      <IconButton size='small'
                        onClick={() => setFilter('')}
                      >
                        <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.7em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid sx={{ display: 'flex ' }} xs={6}>
              <Button variant="outlined" className="gridButton" style={{ marginLeft: 'auto' }}
                disabled={!canUpdate} onClick={handleAdd}>Add</Button>
              {/*  <Button variant="contained" className="gridButton" style={{ marginLeft: '12px' }}*/}
              {/*    onClick={() => navigate(-1)}>Back</Button>*/}
            </Grid>
            <Grid xs={12}>
              {error ? (
                <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
              ) : isLoading ? (
                <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
              ) : vehicleUnavailabilities ? (
                <div style={{ display: 'flex', height: 'calc(100vh - 180px)' }}>
                  <div style={{ flexGrow: '1' }}>
                    <DataGrid rows={vehicleUnavailabilities} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                      columnBuffer={10} />
                  </div>
                </div>
              ) : null}
            </Grid>


          </Grid>
        </Grid>
        <Grid sx={{ backgroundColor: 'transparent', borderLeft: '#e8e8e8 1px solid', margin: '0 8px', padding: '16px 0 0 16px', width: '260px' }} xs="auto">
          <Grid container spacing={1}>
            <Grid sx={{ display: 'flex' }} xs={8}>
              <Typography sx={{ alignSelf: 'center', fontWeight: '700' }}>VEHICLE DETAILS</Typography>
            </Grid>
            <Grid sx={{ display: 'flex', padding: 0 }} xs={4}>
              <Tooltip title="Edit vehicle" enterDelay={1000}>
                <IconButton sx={{ marginLeft: 'auto', marginRight: '8px' }} disabled={!canUpdate} color="primary" size="small"
                  onClick={handleEditVehicle}>
                  <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
                </IconButton>
              </Tooltip>
              {/*  <Button className="gridButton" sx={{ marginLeft: 'auto' }}*/}
              {/*    disabled={!canUpdate} onClick={handleEditVehicle}>edit</Button>*/}
            </Grid>
            <Grid xs={12}>
              <Divider sx={{ marginRight: '8px' }} />
            </Grid>
            <Grid xs={12}>
              <Stack>
                <Typography>Registration</Typography>
                <Typography sx={{ fontWeight: '700' }}>{vehicle?.registration}</Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack>
                <Typography>Year</Typography>
                <Typography sx={{ fontWeight: '700' }}>{vehicle?.year?.format('YYYY')}</Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack>
                <Typography>Make</Typography>
                <Typography sx={{ fontWeight: '700' }}>{vehicle?.vehicleMake}</Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack>
                <Typography>Model</Typography>
                <Typography sx={{ fontWeight: '700' }}>{vehicle?.vehicleModel}</Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack>
                <Typography>Status</Typography>
                <Typography sx={{ fontWeight: '700' }}>{vehicle?.active ? 'Active' : 'Inactive'}</Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack>
                <Typography>Purchased</Typography>
                <Typography sx={{ fontWeight: '700' }}>{vehicle?.purchaseDate?.format('DD/MM/YYYY')}</Typography>
              </Stack>
            </Grid>
            <Grid sx={{ marginBottom: '24px' }} xs={12}>
              <Stack>
                <Typography>Fleet / Asset No.</Typography>
                <Typography sx={{ fontWeight: '700' }}>{vehicle?.assetNumber}</Typography>
              </Stack>
            </Grid>

            <Grid sx={{ display: 'flex' }} xs={5}>
              <Typography sx={{ alignSelf: 'center', fontWeight: '700' }}>ASSIGNED</Typography>
            </Grid>
            <Grid sx={{ display: 'flex', padding: 0 }} xs={7}>
              <Tooltip title="View assignment history" enterDelay={1000}>
                <IconButton sx={{ marginLeft: 'auto' }} disabled={!canRead} color="primary" size="small"
                  onClick={handleViewAssignmentHistory}>
                  <i className="fa fa-fw fa-book-open" style={{ fontSize: '0.9em' }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Edit assignment" enterDelay={1000}>
                <IconButton sx={{ marginLeft: '12px' }} disabled={!canUpdate || !vehicle?.assignedTo} color="primary" size="small"
                  onClick={handleEditVehicleAssignment}>
                  <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
                </IconButton>
              </Tooltip>

              <Tooltip title={vehicle?.assignedTo ? "Unassign vehicle" : "Assign vehicle"} enterDelay={1000}>
                <IconButton sx={{ marginLeft: '12px', marginRight: '8px' }} disabled={!canUpdate} color="primary" size="small"
                  onClick={handleAssignVehicle}>
                  <i className={vehicle?.assignedTo ? "fa fa-fw fa-user-slash" : "fa fa-fw fa-user-plus"} style={{ fontSize: '0.9em' }} />
                </IconButton>
              </Tooltip>

              {/*<Button className="gridButton" sx={{ marginLeft: 'auto' }}*/}
              {/*  disabled={!canUpdate || !vehicle?.assignedTo} onClick={handleEditVehicleAssignment}>edit</Button>*/}
              {/*  <Button className="gridButton" sx={{ marginLeft: '4px' }}*/}
              {/*    disabled={!canUpdate} onClick={handleAssignVehicle}>{vehicle?.assignedTo ? 'unassign' : 'assign'}</Button>*/}
            </Grid>
            <Grid xs={12}>
              <Divider sx={{ marginRight: '8px' }} />
            </Grid>

            <Grid xs={12}>
              <Stack>
                <Typography>Assigned To</Typography>
                <Typography sx={{ fontWeight: '700' }}>{vehicle?.assignedTo}</Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack>
                <Typography>Date Assigned</Typography>
                <Typography sx={{ fontWeight: '700' }}>{vehicle?.assignedDate?.format('DD/MM/YYYY')}</Typography>
              </Stack>
            </Grid>
            <Grid sx={{ marginBottom: '12px' }} xs={12}>
              <Stack>
                <Typography>Private Use</Typography>
                <Typography sx={{ fontWeight: '700' }}>{vehicle?.vehiclePrivateUsage}</Typography>
              </Stack>
            </Grid>

            {/*<Grid sx={{ marginBottom: '8px' }} xs={12}>*/}
            {/*  <Button variant="outlined" className="gridButton"*/}
            {/*    disabled={!canRead} onClick={handleViewAssignmentHistory}>View Assignment History</Button>*/}
            {/*</Grid>*/}

          </Grid>
        </Grid>
      </Grid>

      <VehicleUnavailabilityEdit open={showItemEdit} onClose={() => setShowItemEdit(false)} vehicleUnavailability={selectedItem} />
      <VehicleUnavailabilityDelete open={showItemDelete} onClose={() => setShowItemDelete(false)} vehicleUnavailability={selectedItem} />

      <VehicleEdit open={showVehicleEdit} onClose={() => setShowVehicleEdit(false)} vehicle={vehicle} />
      <VehicleAssignmentEdit open={showVehicleAssignmentEdit} onClose={() => setShowVehicleAssignmentEdit(false)} vehicleAssignment={vehicleAssignment} />
      <VehicleUnassign open={showVehicleUnassign} onClose={() => setShowVehicleUnassign(false)} vehicleAssignment={vehicleAssignment} />

    </>
  );
};