import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { WorkVisaType } from '../api/types';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';
import { useGetUserSecurablesQuery, useUpdateWorkVisaTypeMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Configuration.module.css'
import { Stack } from "@mui/material";

export const WorkVisaTypeEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);
  const [workVisaType, setWorkVisaType] = useState<Partial<WorkVisaType>>();

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const [updateWorkVisaType, { isLoading: isUpdating }] = useUpdateWorkVisaTypeMutation();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.WorkVisa_Configuration]));
    }
  }, [userSecurables])

  useEffect(() => {
    setWorkVisaType(props.workVisaType);
  }, [props.workVisaType])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Formik validation schema
  const validationSchema = yup.object().shape({
    name: yup.string()
      .required('Name is required'),
  });

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<WorkVisaType>) => {
    if (newValues) {
      const res: any = await updateWorkVisaType(newValues);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        displayToastMessage("info", "Success", "Work visa type saved");
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving work visa type", errorText);
      }
    }
  }

  const captionStyle = { margin: '6px 0 4px 0' };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "360px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.workVisaType && props.workVisaType.id && props.workVisaType.id > 0 ? (
            <span className="dialogHeader">Update Work Visa Type</span>
          )
            : (
              <span className="dialogHeader">Add Work Visa Type</span>
            )
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={props.workVisaType} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Work Visa Type</span>
                        <TextField value={values.name}
                          name="name"
                          onChange={handleChange}
                          disabled={!canUpdate}
                          variant="standard"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel label="Expiry Date Required" control={
                        <Checkbox
                          name="isExpiryDateRequired"
                          checked={values.isExpiryDateRequired}
                          onChange={handleChange}
                          disabled={!canUpdate}
                        />
                      } />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '8px' }}>

                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!canUpdate || isSubmitting || !isValid || !dirty}
                          >
                            {props.workVisaType && props.workVisaType.id && props.workVisaType.id > 0 ? (
                              <span>Update</span>
                            )
                              : (
                                <span>Add</span>
                              )
                            }
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};