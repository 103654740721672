import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Form, Field, FieldRenderProps } from 'react-final-form'
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';

import { TravelRecord, TravelAllowance, Job, EmployeeSummary, StandardDataItem } from '../api/types';
import { useGetOperationalLocationsQuery, useAddTravelRecordMutation, useGetDepartmentsQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { AutocompleteWrapper, DatePickerWrapper } from '../uiHelpers/FormFieldWrappers';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Travel.module.css'

export const TravelRecordAdd = (props: any) => {
  const dispatch = useDispatch();
  const [travelRecord, setTravelRecord] = useState<Partial<TravelRecord> | null>(null);

  const [locationSearchText, setLocationSearchText] = useState("");

  //const [updateTravelAllowance] = useUpdateTravelAllowanceMutation();
  const { data: rawLocations } = useGetOperationalLocationsQuery();
  const { data: rawDepartments } = useGetDepartmentsQuery(); // @T4920A
  const [addTravelRecord] = useAddTravelRecordMutation();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newRecord: Partial<TravelRecord>) => {
    if (newRecord) {
      const res: any = await addTravelRecord(newRecord);
      // If all is well there should be some data returned (with a return code)
      const travelId = res.data;
      if (travelId !== undefined) {
        props.onClose(travelId);
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving travel record", errorText);
      }
    }
  };

  useEffect(() => {
    console.log("UseEffect is setting travelAllowance...");
    // Note initial travel date set to yesterday
    setTravelRecord({ id: 0, locationID: 0, travelDate: moment().add(-1, 'days').startOf('day') } as Partial<TravelRecord>);
  }, [props.dateFilter]);

  const containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const locations = useMemo(() => {
    if (rawLocations) {
      return rawLocations.map((l: StandardDataItem ) => {
        return (
          { label: l.name, value: l.id }
        );
      })
    }
    return [];
  }, [rawLocations]);

  // @T4920A
  const departments = useMemo(() => {
    if (rawDepartments) {
      return rawDepartments.map((l: StandardDataItem) => {
        return (
          { label: l.name, value: l.id }
        );
      })
    }
    return [];
  }, [rawDepartments]);
  
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.travelDate) {
      errors.travelDate = 'Required';
    } else {
      // Further date validation
      const now = moment().startOf('day');
      const travelDate = moment.parseZone(values.travelDate.toString()).startOf('day');
      if (!travelDate.isBefore(now)) {
        errors.travelDate = 'Value must be a past date';
      }
    }
    if (!values.locationID || values.locationID <= 0) {
      errors.locationID = 'Required';
    }
    if (!values.departmentID || values.departmentID <= 0) {
      errors.departmentID = 'Required';
    }
    return errors;
  };


  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "360px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="dialogHeader">Add Travel</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Form
              onSubmit={handleSave}
              initialValues={travelRecord}
              validate={validate}
              render={({ handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container alignItems="flex-start" spacing={0}>
                    <Grid item xs={12}>
                      <span>Travel Date</span>
                    </Grid>
                    <Grid style={{ paddingTop: 8 }} item xs={12}>
                      <Field
                        style={{ marginTop: '0' }}
                        fullWidth
                        required
                        name="travelDate"
                        component={DatePickerWrapper}
                        variant="standard"
                        formControlProps={{ fullWidth: true }}
                      >
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <span>Location</span>
                    </Grid>
                    <Grid style={{ paddingTop: 4 }} item xs={12}>
                      <Field
                        style={{ marginTop: '0' }}
                        fullWidth
                        required
                        name="locationID"
                        component={AutocompleteWrapper}
                        formControlProps={{ fullWidth: true }}
                        options={locations}
                        ListboxProps={{ sx: { maxHeight: '10rem' } }}
                      >
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <span>Department</span>
                    </Grid>
                    <Grid style={{ paddingTop: 4 }} item xs={12}>
                      <Field
                        style={{ marginTop: '0' }}
                        fullWidth
                        required
                        name="departmentID"
                        component={AutocompleteWrapper}
                        formControlProps={{ fullWidth: true }}
                        options={departments}
                        ListboxProps={{ sx: { maxHeight: '10rem' } }}
                      >
                      </Field>
                    </Grid>

                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          type="button"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={submitting || pristine}
                        >
                          <span>Add</span>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};