import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent, GridCellValue } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AlertColor } from "@mui/material/Alert";
import * as moment from 'moment';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Moment } from "moment";

import { Vehicle } from '../api/types';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import { useGetUserSecurablesQuery, useGetVehiclesQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'

import { RenderCellExpand } from '../uiHelpers/GridCellExpand';
import { VehicleAdd } from './VehicleAdd';
import { VehicleEdit } from './VehicleEdit'; // @T4810A
import { VehicleDelete } from './VehicleDelete';

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
//import styles from './Vehicle.module.css'

export const VehicleHome = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [canRead, setCanRead] = useState<boolean>(false);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<Partial<Vehicle> | null>();
  const [showItemDelete, setShowItemDelete] = useState<boolean>(false);
  const [showItemAdd, setShowItemAdd] = useState<boolean>(false);
  const [showItemEdit, setShowItemEdit] = useState<boolean>(false); // @T4810A
  const [includeInactive, setIncludeInactive] = useState<boolean>(false);

  const [anyAssignedEmployeesInactive, setAnyAssignedEmployeesInactive] = useState<boolean>(false); // @4820A

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawVehicles, error, isLoading } = useGetVehiclesQuery();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanRead(hasReadPermission(userSecurables, [SECURABLE_NAME.VehicleRegister]));
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.VehicleRegister]));
      setCanDelete(hasDeletePermission(userSecurables, [SECURABLE_NAME.VehicleRegister]));
    }
  }, [userSecurables])

  useEffect(() => {
    if (rawVehicles && rawVehicles.length > 0) {
      setAnyAssignedEmployeesInactive(rawVehicles.filter((v) => v.isAssigneeActive === false).length > 0);
    }
  }, [rawVehicles])

  const vehicles = useMemo(() => {
    let records: Vehicle[] = [];
    if (rawVehicles) {
      records = rawVehicles.filter((r) => includeInactive || r.active === true);
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = records.filter((r) => r.assetNumber.toLowerCase().includes(filter.toLowerCase())
        || r.registration.toLowerCase().includes(filter.toLowerCase()))
        .map((tr) => {
          let newTr = { ...tr };
          if (newTr.year) newTr.year = moment.parseZone(newTr.year.toString());
          if (newTr.purchaseDate) newTr.purchaseDate = moment.parseZone(newTr.purchaseDate.toString());
          if (newTr.assignedDate) newTr.assignedDate = moment.parseZone(newTr.assignedDate.toString());
          if (newTr.changeDate) newTr.changeDate = moment.parseZone(newTr.changeDate.toString());
          return newTr;
        });
    }
    return records;
  }, [rawVehicles, filter, includeInactive]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleAdd = () => {
    setShowItemAdd(true);
  };

  // @T4810A
  const handleEdit = (item: Partial<Vehicle>) => {
    setSelectedItem(item);
    setShowItemEdit(true);
  };

  const handleView = (item: Vehicle) => {
    if (item && item.id > 0) {
      const to = '/vehicle/vehicleUnavailability/' + item.id;
      if (window.location.pathname !== to) {
        navigate(to);
      }
    }
  };

  const handleDelete = (item: Vehicle) => {
    if (item) {
      setSelectedItem(item);
      setShowItemDelete(true);
    }
  };

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    event.defaultMuiPrevented = true;
  }

  const columns = [
    { field: 'assetNumber', headerName: 'Asset No.', width: 120 },
    { field: 'registration', headerName: 'Registration', width: 120 },
    {
      field: 'year'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("YYYY");
      }
      , headerName: 'Year', width: 100
    },
    { field: 'vehicleMake', headerName: 'Make', width: 160 },
    { field: 'vehicleModel', headerName: 'Model', width: 160 },
    { field: 'active', headerName: 'Active', type: 'boolean', width: 100 },
    {
      field: 'purchaseDate', type: 'date'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY");
      }
      , headerName: 'Purchase Date', width: 140
    },
    { field: 'notes', headerName: 'Notes', renderCell: RenderCellExpand, width: 220 },
    {
      field: 'assignedTo'
      , renderCell: (params: GridRenderCellParams<boolean>) => (
        <>
          {params.row.isAssigneeActive === false ?
            <Tooltip title="Vehicle assigned to an inactive employee" enterDelay={1000}>
              <i style={{ color: 'red', fontSize: '1.15em', marginRight: '4px' }} className="fa-solid fa-circle-exclamation"></i>
            </Tooltip>
            : <></>
          }
          <span>{params.value}</span>
        </>
      )
      , headerName: 'Assigned To', width: 180
    },
    {
      field: 'assignedDate', type: 'date'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY");
      }
      , headerName: 'Date Assigned', width: 140
    },
    { field: 'vehiclePrivateUsage', headerName: 'Private Use', width: 160 },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 140, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Stack direction='row' gap={2}>
          <Tooltip title="View" enterDelay={1000}>
            <IconButton disabled={!canRead} color="primary" size="small"
              onClick={() => handleView(params.row)}>
              <i className="fa fa-fw fa-eye" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit" enterDelay={1000}>
            <IconButton disabled={!canUpdate} color="primary" size="small"
              onClick={() => handleEdit(params.row)}>
              <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={params.row.active ? 'Delete' : 'Activate'} enterDelay={1000}>
            <IconButton disabled={params.row.active ? !canDelete : !canUpdate} color="primary" size="small"
              onClick={() => handleDelete(params.row)}>
              <i className={params.row.active ? 'fa fa-regular fa-trash-can' : 'fa fa-solid fa-trash-arrow-up'} style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Grid sx={{ margin: '0 12px' }} container spacing={2}>
        <Grid xs={6}>
          <span className='pageHeader'>Company Vehicle Register</span>
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={6}>
          {anyAssignedEmployeesInactive ?
            <strong style={{ padding: '4px', backgroundColor: 'orange', borderRadius: '4px', margin: '4px 0 0 auto' }}>
              WARNING: One or more vehicles assigned to inactive employees
            </strong>
            : <></>}
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={6}>
          <TextField sx={{ verticalAlign: 'bottom', marginTop: '4px', minWidth: '280px' }}
            placeholder="search by asset no. or rego"
            value={filter}
            onChange={(e: any) => setFilter(e.target.value)}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ margin: '4px' }}>
                  <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                </InputAdornment>
              ),
              endAdornment: (
                < InputAdornment position="end" >
                  <IconButton size='small'
                    onClick={() => setFilter('')}
                  >
                    <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.7em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <FormControlLabel
            sx={{ marginLeft: '16px' }}
            value="activeOnly"
            control={<Switch color="primary" checked={includeInactive}
              onChange={(val: any) => setIncludeInactive(val.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />}
            label="Show inactive"
            labelPlacement="start"
          />
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={6}>
          <Button variant="outlined" className="gridButton" style={{ marginLeft: 'auto' }}
            disabled={!canUpdate} onClick={handleAdd}>Add Vehicle</Button>
        </Grid>
        <Grid xs={12}>
          {error ? (
            <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
          ) : isLoading ? (
            <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
          ) : vehicles ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 180px)' }}>
              <div style={{ flexGrow: '1' }}>
                <DataGrid rows={vehicles} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                  columnBuffer={10}
                  onCellClick={handleGridCellClick} />
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <VehicleAdd open={showItemAdd} onClose={() => setShowItemAdd(false)} />
      <VehicleEdit open={showItemEdit} onClose={() => setShowItemEdit(false)} vehicle={selectedItem} />
      <VehicleDelete open={showItemDelete} onClose={() => setShowItemDelete(false)} vehicle={selectedItem} />
    </>
  );
}