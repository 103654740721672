import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useParams, useNavigate } from 'react-router-dom';
import * as moment from 'moment';

import { Moment } from "moment";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import { AlertColor } from "@mui/material/Alert";
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';

import { useGetUsersWithRolesQuery, useGetUserSecurablesQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'
import { UserWithRoles, User, UserSecurable, UserRole } from '../api/types';
import { ToastMessage, ToastMessageValue } from '../uiHelpers/ToastMessage';
import { UserRoleDelete } from './UserRoleDelete';
import { UserRoleEdit } from './UserRoleEdit';

import { setToastMessage } from '../uiHelpers/toastSlice';

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './UserAccess.module.css'

export const UserRoles = (props: any) => {
  const dispatch = useDispatch();
  const [showUserRoleEdit, setShowUserRoleEdit] = useState(false);
  const [showUserRoleDelete, setShowUserRoleDelete] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState<Partial<UserRole> | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  const [roleNameFilter, setRoleNameFilter] = useState<string>('');

  const [canUpdateUser, setCanUpdateUser] = useState(false);
  const [canDeleteUser, setCanDeleteUser] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: usersWithRoles, error, isLoading, refetch } = useGetUsersWithRolesQuery();

  const navigate = useNavigate();
  const { id } = useParams();

  const memoUserRoles = useMemo(() => {
    let userRoles: UserRole[] = [];
    if (id && usersWithRoles && usersWithRoles.length > 0) {
      const user = usersWithRoles.find((u) => u.id === parseInt(id));
      if (user) {
        setUserName(user.name);
        userRoles = user.roles;
        if (userRoles) {
          // Filter if required
          if (roleNameFilter && roleNameFilter.length > 0) {
            userRoles = userRoles.filter((r) => r.role.toLowerCase().includes(roleNameFilter.toLowerCase()));
          }
          // Set location to ALL for null/-1 locationIds
          userRoles = userRoles.map((ur) => {
            let newUr = { ...ur };
            if (newUr.locationID === null || (newUr.locationID ?? 0) <= 0) newUr.location = 'ALL';
            if (newUr.changeDate) newUr.changeDate = moment.parseZone(newUr.changeDate.toString());
            return newUr;
          });
        }
      }
    }
    return userRoles;
  }, [usersWithRoles, id, roleNameFilter])

  // When user securables change (e.g. when they are first retrieved), set UI visibility variables from the securables
  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdateUser(hasUpdatePermission(userSecurables, [SECURABLE_NAME.User]));
      setCanDeleteUser(hasDeletePermission(userSecurables, [SECURABLE_NAME.User]));
    }
  }, [userSecurables])

  useEffect(() => {
    if (id && usersWithRoles && usersWithRoles.length > 0) {
      const user = usersWithRoles.find((u) => u.id === parseInt(id));
      if (user) {
        setUserRoles(user.roles);
        setUserName(user.name);
      }
    }
  }, [usersWithRoles, id])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleFilterChanged = (e: any) => {
    setRoleNameFilter(e.target.value);
  }

  const handleAdd = () => {
    setCurrentUserRole({ id: 0, userID: id, roleID: 0, locataionID: null } as Partial<UserRole>);
    setShowUserRoleEdit(true);
  };


  const handleEdit = (userRole: UserRole) => {
    setCurrentUserRole(userRole);
    setShowUserRoleEdit(true);
  };

  const handleDelete = (userRole: UserRole) => {
    setCurrentUserRole(userRole);
    setShowUserRoleDelete(true);
  };

  const columns = [
    { field: 'role', headerName: 'Role Name', width: 200 },
    { field: 'location', headerName: 'Location', width: 200 },
    {
      field: 'changeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'changeUser', headerName: 'Updated By', width: 150 },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 100, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Stack direction="horizontal" gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <IconButton disabled={!canUpdateUser} color="primary" size="small"
              onClick={() => handleEdit(params.row)}>
              <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete" enterDelay={1000}>
            <IconButton disabled={!canDeleteUser} color="primary" size="small"
              onClick={() => handleDelete(params.row)}>
              <i className="fa fa-regular fa-trash-can" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Container fluid>
        <Row>
          <span className='pageHeader'>User Roles for {userName}</span>
        </Row>
        <Row>
          <Stack direction="horizontal" gap={3}>
            <div style={{marginTop: '10px', marginBottom: '6px'}}>
              <TextField
                size="small"
                placeholder="search user role"
                onChange={handleFilterChanged}
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="ms-auto">
              <Button className={styles.gridButton} variant="outlined" disabled={!canUpdateUser} onClick={() => handleAdd()}>Add</Button>
            </div>
            <div>
            {/*  <Button className={styles.gridButton} variant="contained" onClick={() => navigate(-1)}>Close</Button>*/}
            </div>
          </Stack>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <div style={{ display: 'flex', height: 'calc(100vh - 150px)' }}>
            <div style={{ flexGrow: '1' }}>
              <DataGrid rows={memoUserRoles} columns={columns} rowHeight={GridRowHeight} getRowId={(row) => row.id} sx={sxNoCellBorder} />
            </div>
          </div>
        </Row>
      </Container>

      <UserRoleEdit open={showUserRoleEdit} onClose={() => setShowUserRoleEdit(false)} userRole={currentUserRole} />
      <UserRoleDelete open={showUserRoleDelete} onClose={() => setShowUserRoleDelete(false)} userRole={currentUserRole} />
    </>
  );
}

/*
         <Row style={{ marginTop: '10px' }}>
          {error ? (
            <>Oh no, there was an error</>
          ) : isLoading ? (
            <>Loading...</>
          ) : userRoles ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 140px)' }}>
              <div style={{ flexGrow: '1' }}>
                <DataGrid rows={userRoles} columns={columns} getRowId={(row) => row.id} />
              </div>
            </div>
          ) : null}
        </Row>

 */