import * as moment from 'moment';
import { Moment } from 'moment';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequestMahiTahi, msalConfig } from "../../auth/authConfig";
import {
  UserSecurable, TravelRecord, TravelAllowance, Role, RoleSecurable, User, UserRole, UserWithRoles, StandardDataItem
  , Job, TravelAllowanceDeleteParm, EmployeeSummary, TravelAllowanceExport, Licence, LicenceClass, LicenceEndorsement
  , PropertyCategory, PropertyItem, PropertyAssigned, VehicleMake, VehicleModel, Vehicle, VehicleUnavailability
  , VehicleUnavailabilityType, VehicleAssignment, Country, Location, WorkVisa, WorkVisaType
  , NOTIFICATION_TYPES, NotificationItem, Allowance, MetricTravelAmount, LocationNotification, EmployeeDetail
} from './types'

const msalInstance = new PublicClientApplication(msalConfig);


export const getAccessToken = async () => {
  const accounts = msalInstance.getAllAccounts();

  const request = {
    ...loginRequestMahiTahi,
    account: accounts[0]
  };

  const response = await msalInstance.acquireTokenSilent(request);

  console.log("Access token for MahiTahi call: " + response.accessToken);
  return response.accessToken;
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/data/',
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  tagTypes: ['RoleSecurable', 'User', 'TravelRecord', 'TravelAllowance', 'DriverLicence', 'PropertyCategory', 'PropertyItem', 'PropertyAssigned'
    , 'VehicleMake', 'VehicleModel', 'Vehicle', 'VehicleUnavailability', 'VehicleUnavailabilityType', 'Country', 'Location', 'WorkVisa'
    , 'WorkVisaType', 'VehicleAssignment', 'Job', 'Allowance', 'Employee'],
  endpoints: (builder) => ({
    getUserSecurables: builder.query<UserSecurable[], void>({
      query: () => 'getUserSecurables',
    }),

    //#region Travel
    getTravelRecords: builder.query<TravelRecord[], number | void>({
      query: (dateFilter) => `getTravelRecords?dateFilter=${dateFilter}`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'TravelRecord', id } as const)),
            { type: 'TravelRecord', id: 'NEWID' }, // -1 for newly added records
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'TravelRecord', id: 'NEWID' }],
      //  // Transform the response data to ensure 'notes' is not null...
      //  transformResponse: (data: TravelRecord[]) => {
      //    return data.map((tr: TravelRecord) => {
      //      tr.notes = tr.notes ? tr.notes : '';
      //      return tr;
      //    });
      //  },
    }),

    getTravelAllowances: builder.query<TravelAllowance[], number | void>({
      query: (travelRecordId) => `getTravelAllowances?travelRecordId=${travelRecordId}`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'TravelAllowance', id } as const)),
            { type: 'TravelAllowance', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'TravelAllowance', id: 'NEWID' }],
    }),

    updateTravelAllowanceReturnStatus: builder.mutation<void, Partial<TravelAllowance>>({
      query: (allowance) => ({
        url: `updateTravelAllowanceReturnStatus/${allowance.id}?isReturning=${(allowance.isReturning ?? false)}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        //body: { id: 4, name: "Testing" },
      }),
      invalidatesTags: (_result, _error, { id, travelRecordID }) => [{ type: 'TravelAllowance', id }, { type: 'TravelAllowance', id: 'NEWID' }
        , { type: 'TravelRecord', id: travelRecordID }],
    }),

    updateTravelAllowanceJob: builder.mutation<void, Partial<TravelAllowance>>({
      query: (allowance) => ({
        url: `updateTravelAllowanceJob/${allowance.id}?jobId=${allowance.jobID}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id, travelRecordID }) => [{ type: 'TravelAllowance', id }, { type: 'TravelAllowance', id: 'NEWID' }
        , { type: 'TravelRecord', id: travelRecordID }],
    }),

    updateTravelAllowanceType: builder.mutation<void, Partial<TravelAllowance>>({
      query: (allowance) => ({
        url: `updateTravelAllowanceType/${allowance.id}?allowanceTypeId=${allowance.allowanceTypeID}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id, travelRecordID }) => [{ type: 'TravelAllowance', id }, { type: 'TravelAllowance', id: 'NEWID' }
        , { type: 'TravelRecord', id: travelRecordID }],
    }),

    updateTravelRecordNotes: builder.mutation<void, Partial<TravelRecord>>({
      query: (record) => ({
        url: `updateTravelRecordNotes/${record.id}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: record.notes,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'TravelRecord', id }, { type: 'TravelRecord', id: 'NEWID' }],
    }),

    updateTravelRecordStatus: builder.mutation<void, Partial<TravelRecord>>({
      query: (record) => ({
        url: `updateTravelRecordStatus/${record.id}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: record.statusID,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'TravelRecord', id }, { type: 'TravelRecord', id: 'NEWID' }],
    }),

    addTravelAllowance: builder.mutation<void, Partial<TravelAllowance>>({
      query: (record) => ({
        url: `addTravelAllowance`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: record,
      }),
      invalidatesTags: (_result, _error, { travelRecordID }) => [{ type: 'TravelRecord', id: travelRecordID }, { type: 'TravelAllowance', id: 'NEWID' }],
    }),

    deleteTravelAllowance: builder.mutation<void, TravelAllowanceDeleteParm>({
      query: (record) => ({
        url: `deleteTravelAllowance/${record.id}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: record.closeEmployeeTravel,
      }),
      invalidatesTags: (_result, _error, { id, travelRecordID }) => [{ type: 'TravelRecord', id: travelRecordID }, { type: 'TravelAllowance', id }],
    }),

    deleteTravelRecord: builder.mutation<void, Partial<TravelRecord>>({
      query: (record) => ({
        url: `deleteTravelRecord/${record.id}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'TravelRecord', id }],
    }),

    addTravelRecord: builder.mutation<void, Partial<TravelRecord>>({
      query: (record) => ({
        url: `addTravelRecord`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: record,
      }),
      invalidatesTags: (_result, _error) => [{ type: 'TravelRecord', id: 'NEWID' }],
    }),

    getTravelRecordsEligibleForExport: builder.query<TravelRecord[], void>({
      query: () => 'getTravelRecordsEligibleForExport',
    }),

    getTravelAllowancesForExport: builder.query<TravelAllowanceExport[], void>({
      query: () => 'getTravelAllowancesForExport',
    }),

    getNextTravelExportPayCycleNumber: builder.query<number, void>({
      query: () => 'getNextTravelExportPayCycleNumber',
    }),

    setTravelRecordsExported: builder.mutation<void, number>({
      query: (exportId) => ({
        url: `setTravelRecordsExported/${exportId}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error) => [{ type: 'TravelRecord', id: 'NEWID' }],
    }),
    //#endregion Travel

    //#region Allowances
    getAllowances: builder.query<Allowance[], void>({
      query: () => 'getAllowances',
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Allowance', id } as const)),
            { type: 'Allowance', id: 'NEWID' }, // -1 for newly added records
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'Allowance', id: 'NEWID' }],
    }),

    updateAllowance: builder.mutation<void, Partial<Allowance>>({
      query: (item) => ({
        url: 'updateAllowance',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Allowance', id }, { type: 'Allowance', id: 'NEWID' }],
    }),

    toggleDeleteAllowance: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteAllowance/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Allowance', id }],
    }),
    //#endregion Allowances

    //#region User, Role and Securable
    getRoleSecurables: builder.query<Role[], void>({
      query: (dateFilter) => 'getRoleSecurables',
      // Transform the response data to ensure Date objects are really Dates and not strings
      //transformResponse: (data: Role[]) => {
      //  return data.map((r: Role) => {
      //    if(r.createDate) r.createDate = moment.parseZone(r.createDate.toString());
      //    if (r.changeDate) r.changeDate = moment.parseZone(r.changeDate.toString());
      //    r.roleSecurables.map((rs: RoleSecurable) => {
      //      if (rs.changeDate) rs.changeDate = moment.parseZone(rs.changeDate.toString());
      //      return rs;
      //    });
      //    return r;
      //  });
      //},
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'RoleSecurable', id } as const)),
            { type: 'RoleSecurable', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'RoleSecurable', id: 'NEWID' }],
    }),

    updateRole: builder.mutation<void, Partial<Role>>({
      query: (role) => ({
        url: 'updateRole',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        //body: { id: 4, name: "Testing" },
        body: role,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'RoleSecurable', id }, { type: 'RoleSecurable', id: 'NEWID' }],
    }),

    deleteRole: builder.mutation<void, number>({
      query: (id) => ({
        url: 'toggleDeleteRole/' + id,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'RoleSecurable', id }],
    }),

    updateRoleSecurable: builder.mutation<RoleSecurable, Partial<RoleSecurable>>({
      query: (roleSec) => ({
        url: 'UpdateRoleSecurable',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        //body: { id: 4, name: "Testing" },
        body: roleSec,
      }),
      //transformResponse: (roleSec: RoleSecurable) => {
      //  if (roleSec.changeDate) roleSec.changeDate = moment.parseZone(roleSec.changeDate.toString());
      //  return roleSec;
      //},
      invalidatesTags: (_result, _error, { roleID }) => [{ type: 'RoleSecurable', roleID }, { type: 'RoleSecurable', id: 'NEWID' }],
    }),

    getUsersWithRoles: builder.query<UserWithRoles[], void>({
      query: () => 'getUsersWithRoles',
      // Transform the response data to ensure Date objects are really Dates and not strings
      //transformResponse: (data: UserWithRoles[]) => {
      //  return data.map((ur: UserWithRoles) => {
      //    if (ur.lastChangeDate) ur.lastChangeDate = moment.parseZone(ur.lastChangeDate.toString());
      //    if (ur.lastLoginDate) ur.lastLoginDate = moment.parseZone(ur.lastLoginDate.toString());
      //    if (ur.roles) {
      //      ur.roles.map((r: UserRole) => {
      //        if (r.changeDate) r.changeDate = moment.parseZone(r.changeDate.toString());
      //        return r;
      //      });
      //    }
      //    return ur;
      //  });
      //},
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'User', id } as const)),
            { type: 'User', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'User', id: 'NEWID' }],
    }),

    deleteUserRole: builder.mutation<void, UserRole>({
      query: (userRole) => ({
        url: 'deleteUserRole',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: userRole
      }),
      invalidatesTags: (_result, _error, userRole) => [{ type: 'User', id: userRole.userID }],
    }),

    removeUserFromRole: builder.mutation<void, Partial<UserRole>>({
      query: (userRole) => ({
        url: 'removeUserFromRole/' + userRole.roleID + '?userId=' + userRole.userID,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { userID, roleID }) => [{ type: 'User', id: userID }, { type: 'RoleSecurable', id: roleID }],
    }),

    deleteAllUserRoles: builder.mutation<void, number>({
      query: (id) => ({
        url: 'deleteAllUserRoles/' + id,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'User', id }],
    }),

    updateUserRole: builder.mutation<UserRole, Partial<UserRole>>({
      query: (userRole) => ({
        url: 'updateUserRole',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        //body: { id: 4, name: "Testing" },
        body: userRole,
      }),
      invalidatesTags: (_result, _error, { userID, roleID }) => [{ type: 'User', id: userID }, { type: 'User', id: 'NEWID' }, { type: 'RoleSecurable', id: roleID }],
    }),
    //#endregion User, Role and Securable

    //#region Licences
    getEmployeeLicence: builder.query<Licence, number | void>({
      query: (employeeId) => `getEmployeeLicence?employeeId=${employeeId}`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            { type: 'DriverLicence', id: result.id },
            { type: 'DriverLicence', id: 'NEWID' }, // -1 for newly added records
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'DriverLicence', id: 'NEWID' }],
    }),

    updateEmployeeLicence: builder.mutation<number, Partial<Licence>>({
      query: (licence) => ({
        url: 'updateEmployeeLicence',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: licence,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'DriverLicence', id }, { type: 'DriverLicence', id: 'NEWID' }],
    }),

    updateEmployeeLicenceClass: builder.mutation<number, Partial<LicenceClass>>({
      query: (licenceClass) => ({
        url: 'updateEmployeeLicenceClass/' + licenceClass.licenceID,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: licenceClass
      }),
      invalidatesTags: (_result, _error, { licenceID }) => [{ type: 'DriverLicence', id: licenceID }, { type: 'DriverLicence', id: 'NEWID' }],
    }),

    updateEmployeeLicenceEndorsement: builder.mutation<number, Partial<LicenceEndorsement>>({
      query: (licenceEndorsement) => ({
        url: 'updateEmployeeLicenceEndorsement/' + licenceEndorsement.licenceID,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: licenceEndorsement
      }),
      invalidatesTags: (_result, _error, { licenceID }) => [{ type: 'DriverLicence', id: licenceID }, { type: 'DriverLicence', id: 'NEWID' }],
    }),

    deleteEmployeeLicenceClass: builder.mutation<void, Partial<LicenceClass>>({
      query: (record) => ({
        url: `deleteEmployeeLicenceClass/${record.licenceClassID}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { licenceID }) => [{ type: 'DriverLicence', id: licenceID }],
    }),

    deleteEmployeeLicenceEndorsement: builder.mutation<void, Partial<LicenceEndorsement>>({
      query: (record) => ({
        url: `deleteEmployeeLicenceEndorsement/${record.licenceEndorsementID}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { licenceID }) => [{ type: 'DriverLicence', id: licenceID }],
    }),
    //#endregion Licences

    //#region Property
    getPropertyCategories: builder.query<PropertyCategory[], void>({
      query: () => `getPropertyCategories`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'PropertyCategory', id } as const)),
            { type: 'PropertyCategory', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'PropertyCategory', id: 'NEWID' }` is invalidated
          [{ type: 'PropertyCategory', id: 'NEWID' }],
    }),

    getPropertyItems: builder.query<PropertyItem[], void>({
      query: () => `getPropertyItems`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'PropertyItem', id } as const)),
            { type: 'PropertyItem', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'PropertyItem', id: 'NEWID' }],
    }),

    getPropertyAssigned: builder.query<PropertyAssigned[], number | void>({
      query: (employeeId) => `getPropertyAssigned/${employeeId}`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'PropertyAssigned', id } as const)),
            { type: 'PropertyAssigned', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'PropertyAssigned', id: 'NEWID' }],
    }),

    updatePropertyAssigned: builder.mutation<void, Partial<PropertyAssigned>>({
      query: (property) => ({
        url: 'updatePropertyAssigned',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: property,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'PropertyAssigned', id }, { type: 'PropertyAssigned', id: 'NEWID' }],
    }),

    updatePropertyAssignedReturnedStatus: builder.mutation<void, Partial<PropertyAssigned>>({
      query: (property) => ({
        url: 'updatePropertyAssignedReturnedStatus',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: property,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'PropertyAssigned', id }, { type: 'PropertyAssigned', id: 'NEWID' }],
    }),

    deletePropertyAssigned: builder.mutation<void, Partial<PropertyAssigned>>({
      query: (record) => ({
        url: `deletePropertyAssigned/${record.id}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'PropertyAssigned', id }],
    }),

    updatePropertyCategory: builder.mutation<void, Partial<PropertyCategory>>({
      query: (category) => ({
        url: 'updatePropertyCategory',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: category,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'PropertyCategory', id }, { type: 'PropertyCategory', id: 'NEWID' }],
    }),

    updatePropertyItem: builder.mutation<void, Partial<PropertyItem>>({
      query: (item) => ({
        url: 'updatePropertyItem',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'PropertyItem', id }, { type: 'PropertyItem', id: 'NEWID' }],
    }),

    toggleDeletePropertyCategory: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeletePropertyCategory/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'PropertyCategory', id }],
    }),

    toggleDeletePropertyItem: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeletePropertyItem/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'PropertyItem', id }],
    }),

    //#endregion Property

    //#region Vehicles
    getVehicleMakes: builder.query<VehicleMake[], void>({
      query: () => `getVehicleMakes`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'VehicleMake', id } as const)),
            { type: 'VehicleMake', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'VehicleMake', id: 'NEWID' }` is invalidated
          [{ type: 'VehicleMake', id: 'NEWID' }],
    }),

    updateVehicleMake: builder.mutation<void, Partial<VehicleMake>>({
      query: (category) => ({
        url: 'updateVehicleMake',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: category,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'VehicleMake', id }, { type: 'VehicleMake', id: 'NEWID' }],
    }),

    toggleDeleteVehicleMake: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteVehicleMake/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'VehicleMake', id }],
    }),

    getVehicleModels: builder.query<VehicleModel[], void>({
      query: () => `getVehicleModels`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'VehicleModel', id } as const)),
            { type: 'VehicleModel', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'VehicleModel', id: 'NEWID' }],
    }),

    updateVehicleModel: builder.mutation<void, Partial<VehicleModel>>({
      query: (item) => ({
        url: 'updateVehicleModel',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'VehicleModel', id }, { type: 'VehicleModel', id: 'NEWID' }],
    }),

    toggleDeleteVehicleModel: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteVehicleModel/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'VehicleModel', id }],
    }),

    getVehicles: builder.query<Vehicle[], void>({
      query: () => `getVehicles`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Vehicle', id } as const)),
            { type: 'Vehicle', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'VehicleMake', id: 'NEWID' }` is invalidated
          [{ type: 'Vehicle', id: 'NEWID' }],
    }),

    getVehicleAssignments: builder.query<VehicleAssignment[], number>({
      query: (vehicleId) => `getVehicleAssignments/${vehicleId}`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'VehicleAssignment', id } as const)),
            { type: 'VehicleAssignment', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'VehicleAssignment', id: 'NEWID' }` is invalidated
          [{ type: 'VehicleAssignment', id: 'NEWID' }],
    }),

    updateVehicleAndAssignment: builder.mutation<void, Partial<Vehicle>>({
      query: (property) => ({
        url: 'updateVehicleAndAssignment',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: property,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Vehicle', id }, { type: 'Vehicle', id: 'NEWID' }],
    }),

    toggleDeleteVehicle: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteVehicle/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Vehicle', id }],
    }),

    getVehicleUnavailabilities: builder.query<VehicleUnavailability[], number | void>({
      query: (vehicleId) => `getVehicleUnavailabilities/${vehicleId}`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'VehicleUnavailability', id } as const)),
            { type: 'VehicleUnavailability', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'VehicleUnavailability', id: 'NEWID' }],
    }),

    updateVehicleUnavailability: builder.mutation<void, Partial<VehicleUnavailability>>({
      query: (property) => ({
        url: 'updateVehicleUnavailability',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: property,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'VehicleUnavailability', id }, { type: 'VehicleUnavailability', id: 'NEWID' }],
    }),

    toggleDeleteVehicleUnavailability: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteVehicleUnavailability/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'VehicleUnavailability', id }],
    }),

    updateVehicleAssignment: builder.mutation<void, Partial<VehicleAssignment>>({
      query: (item) => ({
        url: 'updateVehicleAssignment',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id, vehicleID }) => [{ type: 'VehicleAssignment', id }, { type: 'Vehicle', id: vehicleID }],
    }),

    unassignVehicle: builder.mutation<void, Partial<VehicleAssignment>>({
      query: (item) => ({
        url: 'unassignVehicle',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id, vehicleID }) => [{ type: 'VehicleAssignment', id }, { type: 'Vehicle', id: vehicleID }],
    }),

    getVehicleUnavailabilityTypes: builder.query<VehicleUnavailabilityType[], void>({
      query: () => `getVehicleUnavailabilityTypes`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'VehicleUnavailabilityType', id } as const)),
            { type: 'VehicleUnavailabilityType', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'VehicleUnavailabilityType', id: 'NEWID' }` is invalidated
          [{ type: 'VehicleUnavailabilityType', id: 'NEWID' }],
    }),

    updateVehicleUnavailabilityType: builder.mutation<void, Partial<VehicleUnavailabilityType>>({
      query: (item) => ({
        url: 'updateVehicleUnavailabilityType',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'VehicleUnavailabilityType', id }, { type: 'VehicleUnavailabilityType', id: 'NEWID' }],
    }),

    toggleDeleteVehicleUnavailabilityType: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteVehicleUnavailabilityType/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'VehicleUnavailabilityType', id }],
    }),

    //#endregion Vehicles

    //#region Countries and Locations
    getCountries: builder.query<Country[], void>({
      query: () => `getCountries`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Country', id } as const)),
            { type: 'Country', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'Country', id: 'NEWID' }` is invalidated
          [{ type: 'Country', id: 'NEWID' }],
    }),

    updateCountry: builder.mutation<void, Partial<Country>>({
      query: (item) => ({
        url: 'updateCountry',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Country', id }, { type: 'Country', id: 'NEWID' }],
    }),

    toggleDeleteCountry: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteCountry/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Country', id }],
    }),

    getLocations: builder.query<Location[], void>({
      query: () => `getLocations`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Location', id } as const)),
            { type: 'Location', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'Location', id: 'NEWID' }` is invalidated
          [{ type: 'Location', id: 'NEWID' }],
    }),

    updateLocation: builder.mutation<void, Partial<Location>>({
      query: (item) => ({
        url: 'updateLocation',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Location', id }, { type: 'Location', id: 'NEWID' }],
    }),

    toggleDeleteLocation: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteLocation/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Location', id }],
    }),
    //#endregion Countries and Locations

    //#region Work Visas
    getWorkVisas: builder.query<WorkVisa[], boolean>({
      query: (includeInactive) => `getWorkVisas?includeInactive=${includeInactive}`,

      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'WorkVisa', id } as const)),
            { type: 'WorkVisa', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'Country', id: 'NEWID' }` is invalidated
          [{ type: 'WorkVisa', id: 'NEWID' }],
    }),

    updateWorkVisa: builder.mutation<void, Partial<WorkVisa>>({
      query: (item) => ({
        url: 'updateWorkVisa',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'WorkVisa', id }, { type: 'WorkVisa', id: 'NEWID' }],
    }),

    toggleDeleteWorkVisa: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteWorkVisa/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'WorkVisa', id }],
    }),

    getVisaTypes: builder.query<WorkVisaType[], void>({
      query: () => `getVisaTypes`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'WorkVisaType', id } as const)),
            { type: 'WorkVisaType', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'WorkVisaType', id: 'NEWID' }` is invalidated
          [{ type: 'WorkVisaType', id: 'NEWID' }],
    }),

    updateWorkVisaType: builder.mutation<void, Partial<WorkVisaType>>({
      query: (category) => ({
        url: 'updateWorkVisaType',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: category,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'WorkVisaType', id }, { type: 'WorkVisaType', id: 'NEWID' }],
    }),

    toggleDeleteWorkVisaType: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteWorkVisaType/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'WorkVisaType', id }],
    }),
    //#endregion Work Visas

    //#region Notifications
    getWorkVisaExpiryNotification: builder.query<NotificationItem, void>({
      query: () => `getNotification/${NOTIFICATION_TYPES.WorkVisaExpiry}`,
    }),

    updateWorkVisaExpiryNotification: builder.mutation<void, Partial<NotificationItem>>({
      query: (item) => ({
        url: 'updateWorkVisaExpiryNotification',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
    }),

    getLocationNotifications: builder.query<LocationNotification[], number>({
      query: (typeId) => `getLocationNotifications/${typeId}`,
    }),

    updateLocationNotifications: builder.mutation<void, Partial<LocationNotification>[]>({
      query: (items) => ({
        url: 'updateLocationNotifications',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: items,
      }),
    }),
    //#endregion Notifications

    //#region Jobs
    getJobs: builder.query<Job[], void>({
      query: () => 'getJobs',
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Job', id } as const)),
            { type: 'Job', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'Location', id: 'NEWID' }` is invalidated
          [{ type: 'Job', id: 'NEWID' }],
    }),

    updateJob: builder.mutation<void, Partial<Job>>({
      query: (item) => ({
        url: 'updateJob',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Job', id }, { type: 'Job', id: 'NEWID' }],
    }),

    toggleDeleteJob: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteJob/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Job', id }],
    }),
    //#endregion Jobs

    //#region Metrics
    getMetricTravelAmounts: builder.query<MetricTravelAmount[], number>({
      query: (age) => `getMetricTravelAmounts/${age}`,
    }),

    getMetricOutOfPortEmployees: builder.query<MetricTravelAmount[], number>({
      query: (age) => `getMetricOutOfPortEmployees/${age}`,
    }),

    getMetricAverageDailyTravelCost: builder.query<number, number>({
      query: (age) => `getMetricAverageDailyTravelCost/${age}`,
    }),

    getMetricTravelPendingApproval: builder.query<number, number>({
      query: (age) => `getMetricTravelPendingApproval/${age}`,
    }),
    //#endregion Metrics


    //#region Employees
    getEmployeesDetail: builder.query<EmployeeDetail[], void>({
      query: () => 'getEmployeesDetail',
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Employee', id } as const)),
            { type: 'Employee', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when 'NEWID' is invalidated
          [{ type: 'Employee', id: 'NEWID' }],

    }),

    updateEmployee: builder.mutation<void, Partial<EmployeeDetail>>({
      query: (item) => ({
        url: 'updateEmployee',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Employee', id }, { type: 'Employee', id: 'NEWID' }],
    }),
    //#endregion Employees

    //#region General lists
    getOperationalLocations: builder.query<StandardDataItem[], void>({
      query: () => 'getOperationalLocations',
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Location', id } as const)),
            { type: 'Location', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'Location', id: 'NEWID' }` is invalidated
          [{ type: 'Location', id: 'NEWID' }],

    }),

    getAllowanceTypes: builder.query<StandardDataItem[], void>({
      query: () => 'getAllowanceTypes',
    }),

    getAllowanceLevels: builder.query<StandardDataItem[], void>({
      query: () => 'getAllowanceLevels',
    }),

    //getActiveEmployees: builder.query<EmployeeSummary[], void>({
    //  query: () => 'getActiveEmployees',
    //}),

    getEmployees: builder.query<EmployeeSummary[], any>({
      query: ({ includeInactive, maxInactiveDays }) => `getEmployees?includeInactive=${includeInactive}&maxInactiveDays=${maxInactiveDays}`,
    }),

    getVehiclePrivateUsageTypes: builder.query<StandardDataItem[], void>({
      query: () => 'getVehiclePrivateUsageTypes',
    }),

    getDepartments: builder.query<StandardDataItem[], void>({
      query: () => 'getDepartments',
    }),
    //#endregion General lists
  }),
});

export const { useGetUserSecurablesQuery
  , useGetTravelRecordsQuery
  , useGetTravelAllowancesQuery
  , useAddTravelAllowanceMutation
  , useDeleteTravelAllowanceMutation
  , useUpdateTravelAllowanceReturnStatusMutation
  , useUpdateTravelAllowanceJobMutation
  , useUpdateTravelAllowanceTypeMutation
  , useUpdateTravelRecordNotesMutation
  , useUpdateTravelRecordStatusMutation
  , useDeleteTravelRecordMutation
  , useGetTravelRecordsEligibleForExportQuery
  , useGetTravelAllowancesForExportQuery
  , useGetNextTravelExportPayCycleNumberQuery
  , useSetTravelRecordsExportedMutation
  , useAddTravelRecordMutation
  , useGetRoleSecurablesQuery
  , useUpdateRoleMutation
  , useDeleteRoleMutation
  , useUpdateRoleSecurableMutation
  , useGetUsersWithRolesQuery
  , useDeleteUserRoleMutation
  , useRemoveUserFromRoleMutation
  , useDeleteAllUserRolesMutation
  , useUpdateUserRoleMutation
  , useGetOperationalLocationsQuery
  , useGetJobsQuery
  , useUpdateJobMutation
  , useToggleDeleteJobMutation
  , useGetAllowanceTypesQuery
  , useGetAllowanceLevelsQuery
  , useGetAllowancesQuery
  , useUpdateAllowanceMutation
  , useToggleDeleteAllowanceMutation
  //, useGetActiveEmployeesQuery
  , useGetEmployeesQuery
  , useGetEmployeeLicenceQuery
  , useUpdateEmployeeLicenceMutation
  , useUpdateEmployeeLicenceClassMutation
  , useUpdateEmployeeLicenceEndorsementMutation
  , useDeleteEmployeeLicenceClassMutation
  , useDeleteEmployeeLicenceEndorsementMutation
  , useGetPropertyCategoriesQuery
  , useGetPropertyItemsQuery
  , useGetPropertyAssignedQuery
  , useUpdatePropertyAssignedMutation
  , useUpdatePropertyAssignedReturnedStatusMutation
  , useDeletePropertyAssignedMutation
  , useToggleDeletePropertyCategoryMutation
  , useToggleDeletePropertyItemMutation
  , useUpdatePropertyCategoryMutation
  , useUpdatePropertyItemMutation
  , useGetVehicleMakesQuery
  , useUpdateVehicleMakeMutation
  , useToggleDeleteVehicleMakeMutation
  , useGetVehicleModelsQuery
  , useUpdateVehicleModelMutation
  , useToggleDeleteVehicleModelMutation
  , useGetVehiclesQuery
  , useGetVehicleAssignmentsQuery
  , useUpdateVehicleAndAssignmentMutation
  , useGetVehiclePrivateUsageTypesQuery
  , useToggleDeleteVehicleMutation
  , useGetVehicleUnavailabilitiesQuery
  , useUpdateVehicleUnavailabilityMutation
  , useToggleDeleteVehicleUnavailabilityMutation
  , useGetVehicleUnavailabilityTypesQuery
  , useUpdateVehicleUnavailabilityTypeMutation
  , useToggleDeleteVehicleUnavailabilityTypeMutation
  , useUpdateVehicleAssignmentMutation
  , useUnassignVehicleMutation
  , useGetCountriesQuery
  , useUpdateCountryMutation
  , useToggleDeleteCountryMutation
  , useGetLocationsQuery
  , useUpdateLocationMutation
  , useToggleDeleteLocationMutation
  , useGetWorkVisasQuery
  , useUpdateWorkVisaMutation
  , useToggleDeleteWorkVisaMutation
  , useGetVisaTypesQuery
  , useUpdateWorkVisaTypeMutation
  , useToggleDeleteWorkVisaTypeMutation
  , useGetWorkVisaExpiryNotificationQuery
  , useUpdateWorkVisaExpiryNotificationMutation
  , useGetMetricTravelAmountsQuery
  , useGetMetricOutOfPortEmployeesQuery
  , useGetMetricAverageDailyTravelCostQuery
  , useGetMetricTravelPendingApprovalQuery
  , useGetLocationNotificationsQuery
  , useUpdateLocationNotificationsMutation
  , useGetEmployeesDetailQuery
  , useUpdateEmployeeMutation
  , useGetDepartmentsQuery
} = apiSlice;
