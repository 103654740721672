import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel'; import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { LicenceEndorsement } from '../api/types';
import { useGetUserSecurablesQuery, useUpdateEmployeeLicenceEndorsementMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Licence.module.css'

export const LicenceEndorsementEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdateLicence, setCanUpdateLicence] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const [updateEmployeeLicenceEndorsement, { isLoading: isUpdating }] = useUpdateEmployeeLicenceEndorsementMutation();

  // Formik validation schema 
  const validationSchema = yup.object().shape({
    expiryDate: yup.date()
      .required('Expiry date is required'),
  });

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdateLicence(hasUpdatePermission(userSecurables, [SECURABLE_NAME.DriverLicence]));
    }
  }, [userSecurables])

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<LicenceEndorsement>) => {
    if (props.licenceEndorsement && newValues) {
      // Create the parameter for the update call
      const editedLicenceEndorsement = { licenceID: props.licenceId, endorsementID: props.licenceEndorsement.endorsementID, expiryDate: newValues.expiryDate };

      const res: any = await updateEmployeeLicenceEndorsement(editedLicenceEndorsement);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving licence endorsement", errorText);
      }
    }
  };

  return (
    <Dialog
      PaperProps={{ sx: { width: "440px" } }}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.licenceEndorsement ? (
          props.licenceEndorsement.licenceEndorsementID ? (
            <span className="dialogHeader">Update {props.licenceEndorsement.endorsementShortName} Endorsement</span>
          ) : (
              <span className="dialogHeader">Add {props.licenceEndorsement.endorsementShortName} Endorsement</span>
          )
        ) : <></>
        }
      </DialogTitle>
      <DialogContent>
        <div style={{ margin: 'auto' }}>
          <Formik initialValues={props.licenceEndorsement} onSubmit={handleSave} validationSchema={validationSchema}>
            {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <span>Expiry Date</span>
                  </Grid>
                  <Grid style={{ paddingTop: 8 }} item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker value={values.expiryDate}
                        onChange={(value) => setFieldValue("expiryDate", value, true)}
                        renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                        inputFormat="DD/MM/yyyy"
                        disabled={!canUpdateLicence}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '26px' }}>
                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                      <Grid item style={{ marginLeft: '16px' }}>
                        <Button
                          className={styles.gridButton}
                          type="button"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item style={{ marginLeft: '16px' }}>
                        <Button
                          className={styles.gridButton}
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting || !isValid || !dirty}
                        >
                          {props.licenceEndorsement && props.licenceEndorsement.licenceEndorsementID ? (
                            <span>Update</span>
                          )
                            : (
                              <span>Add</span>
                            )
                          }
                        </Button>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
}