import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createFilterOptions } from '@mui/material/Autocomplete';
import * as moment from 'moment';
import { Moment } from "moment";

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Vehicle, VehicleMake, VehicleModel, EmployeeSummary, StandardDataItem } from '../api/types';
import {
  useGetUserSecurablesQuery, useGetVehicleMakesQuery, useGetVehicleModelsQuery, useGetEmployeesQuery, useUpdateVehicleAndAssignmentMutation
  , useGetVehiclePrivateUsageTypesQuery
} from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';

import { setToastMessage } from '../uiHelpers/toastSlice';

//import styles from './Licence.module.css'
import { Stack } from "@mui/material";

interface EditStageIndicatorProps {
  currentEditStage: number,
  activeEditStage: number,
  title: string
}

const EditStageIndicator = (props: EditStageIndicatorProps) => {
  return (
    <Stack direction="row">
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px', height: '20px', borderRadius: 10, color: 'white'
        , backgroundColor: (props.currentEditStage === props.activeEditStage ? '#1565c0' : '#d0d0d0')
      }}>
        <span style={{ margin: 0, padding: 0 }}>{props.activeEditStage}</span>
      </div>
      <span style={{ margin: '0 24px 0 12px', color: (props.currentEditStage === props.activeEditStage ? '#1565c0' : '#d0d0d0') }}>{props.title}</span>
    </Stack>
  );
}

export const VehicleAdd = (props: any) => {
  const dispatch = useDispatch();
  //const [vehicle, setvehicle] = useState<Partial<vehicle>>({});

  const newVehicle = {
    id: 0,
    registration: '',
    year: null,
    vehicleMakeID: 0,
    vehicleModelID: 0,
    purchaseDate: null,
    assetNumber: '',
    assignedDate: null,
    employeeID: 0,
    privateUse: false,
    notes: '',
  };

  const [canUpdate, setCanUpdate] = useState(false);
  const [vehicle, setVehicle] = useState<Partial<Vehicle>>(newVehicle);
  const [editStage, setEditStage] = useState<number>(1);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawVehicleModels } = useGetVehicleModelsQuery();
  const { data: rawVehicleMakes } = useGetVehicleMakesQuery();
  const { data: privateUsageTypes } = useGetVehiclePrivateUsageTypesQuery();
  // Note use of same parameters as in the EmployeeList so that we benefit from RTKQuery caching...
  const { data: rawEmployees, error, isLoading } = useGetEmployeesQuery({ includeInactive: true, maxInactiveDays: 90 });

  const [updateVehicleAndAssignment, { isLoading: isUpdating }] = useUpdateVehicleAndAssignmentMutation();

  useEffect(() => {
    if (props.open) {
      setVehicle(newVehicle);
      setEditStage(1);
    }
  }, [props.open])

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.VehicleRegister]));
    }
  }, [userSecurables])

  const vehicleMakes = useMemo(() => {
    let records: VehicleMake[] = [];
    if (rawVehicleMakes) {
      records = rawVehicleMakes.filter((r) => r.active === true);
    }
    return records;
  }, [rawVehicleMakes]);

  const vehicleModels = useMemo(() => {
    let records: VehicleModel[] = [];
    if (rawVehicleModels) {
      records = rawVehicleModels.filter((r) => r.active === true);
    }
    return records;
  }, [rawVehicleModels]);

  const employees = useMemo(() => {
    let records: EmployeeSummary[] = [];

    if (rawEmployees) {
      records = rawEmployees.filter((r) => r.active === true);
    }
    return records;
  }, [rawEmployees]);

  // Formik validation schema 
  const validationSchema1 = yup.object().shape({
    year: yup.date()
      .required('Year is required'),
    registration: yup.string()
      .required('Registration is required'),
    vehicleModelID: yup.number()
      .min(1)
      .required('Make and Model are required'),
  });

  const validationSchema2 = yup.object().shape({
    purchaseDate: yup.date()
      .required('Purchase Date is required'),
    assetNumber: yup.string()
      .required('Asset Number is required'),
  });

  const validationSchema3 = yup.object().shape({
    //  purchaseDate: yup.date()
    //    .required('Purchase Date is required'),
    //  assetNumber: yup.string()
    //    .required('Asset Number is required'),
  });

  const validationSchema4 = yup.object().shape({
    //  purchaseDate: yup.date()
    //    .required('Purchase Date is required'),
    //  assetNumber: yup.string()
    //    .required('Asset Number is required'),
  });

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleNext = async (newValues: Partial<Vehicle>) => {
    if (vehicle && newValues) {
      if (editStage === 1) {
        setVehicle({
          ...vehicle,
          registration: newValues.registration,
          year: newValues.year,
          vehicleMakeID: newValues.vehicleMakeID,
          vehicleModelID: newValues.vehicleModelID
        });
        setEditStage(editStage + 1);
      }
      else if (editStage === 2) {
        setVehicle({
          ...vehicle,
          purchaseDate: newValues.purchaseDate,
          assetNumber: newValues.assetNumber,
        });
        setEditStage(editStage + 1);
      }
      else if (editStage === 3) {
        setVehicle({
          ...vehicle,
          assignedDate: newValues.assignedDate,
          employeeID: newValues.employeeID,
          vehiclePrivateUsageID: newValues.vehiclePrivateUsageID,
        });
        setEditStage(editStage + 1);
      }
      else if (editStage === 4) {
        setVehicle({
          ...vehicle,
          notes: newValues.notes,
        });
        setEditStage(editStage + 1);
      }
    }
  };

  const handleBack = async (newValues: Partial<Vehicle>) => {
    if (vehicle && newValues) {
      if (editStage === 2) {
        setVehicle({
          ...vehicle,
          purchaseDate: newValues.purchaseDate,
          assetNumber: newValues.assetNumber,
        });
        setEditStage(editStage - 1);
      }
      else if (editStage === 3) {
        setVehicle({
          ...vehicle,
          assignedDate: newValues.assignedDate,
          employeeID: newValues.employeeID,
          vehiclePrivateUsageID: newValues.vehiclePrivateUsageID,
        });
        setEditStage(editStage - 1);
      }
      else if (editStage === 4) {
        setVehicle({
          ...vehicle,
          notes: newValues.notes,
        });
        setEditStage(editStage - 1);
      }
    }
  };

  const handleSave = async (newValues: Partial<Vehicle>) => {
    if (vehicle && newValues) {
      const res: any = await updateVehicleAndAssignment(newValues);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        // Look for a warning message
        if (returnCode.warning && returnCode.warning.length > 0) {
          displayToastMessage("warning", "Vehicle saved", returnCode.warning);
        } else {
          displayToastMessage("info", "Success", "Vehicle saved");
        }
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving vehicle", errorText);
      }

    }
  };

  const captionStyle = { margin: '6px 0 4px 0' };

  // @T4888A
  const employeeFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.label + ' ' + option.additionalFilterData,
  });

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "480px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row">
            <EditStageIndicator currentEditStage={editStage} activeEditStage={1} title='Vehicle' />
            <EditStageIndicator currentEditStage={editStage} activeEditStage={2} title='Accounting' />
            <EditStageIndicator currentEditStage={editStage} activeEditStage={3} title='Assigned' />
            <EditStageIndicator currentEditStage={editStage} activeEditStage={4} title='Notes' />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            {editStage === 1 ?
              <Formik validateOnMount={true} initialValues={vehicle} onSubmit={handleNext} validationSchema={validationSchema1}>
                {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                  <Form>
                    <Stack>
                      <div style={{ height: '320px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <span className="dialogHeader">Vehicle Details</span>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack>
                              <span style={captionStyle}>Registration</span>
                              <TextField value={values.registration}
                                name="registration"
                                onChange={handleChange}
                                disabled={!canUpdate}
                                variant="standard"
                                sx={{ width: '180px' }}
                              />
                            </Stack>
                          </Grid>

                          <Grid item xs={12}>
                            <Stack>
                              <span style={captionStyle}>Year</span>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker value={values.year}
                                  onChange={(value) => setFieldValue("year", value, true)}
                                  openTo="year"
                                  views={["year"]}
                                  renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                                  inputFormat="yyyy"
                                  disabled={!canUpdate}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>

                          <Grid item xs={5}>
                            <Stack>
                              <span style={captionStyle}>Make</span>
                              <AutocompleteWrapper
                                value={values?.vehicleMakeID}
                                onChange={(e: any, value: any) => {
                                  if (value) {
                                    setFieldValue("vehicleMakeID", value, true);
                                    setFieldValue("vehicleModelID", 0, true);
                                  }
                                }
                                }
                                renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                                options={vehicleMakes ? vehicleMakes.map((j) => { return { label: j.name, value: j.id }; }) : []}
                                disabled={!canUpdate}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={7}>
                            <Stack>
                              <span style={captionStyle}>Model</span>
                              <AutocompleteWrapper
                                value={values?.vehicleModelID}
                                onChange={(e: any, value: any) => { if (value) setFieldValue("vehicleModelID", value, true); }}
                                renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                                options={values.vehicleMakeID && vehicleModels ?
                                  vehicleModels.filter((p) => p.vehicleMakeID === values.vehicleMakeID).map((j) => { return { label: j.name, value: j.id }; })
                                  : []}
                                disabled={!canUpdate}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </div>
                      <div style={{ height: '42px' }}>
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                          <Grid item style={{ marginLeft: '16px' }}>
                            <Button
                              className="gridButton"
                              type="button"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item style={{ marginLeft: '16px' }}>
                            <Button
                              className="gridButton"
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting || !isValid}
                            >
                              <span>Next</span>
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Stack>
                  </Form>
                )}
              </Formik>
              : <></>}

            {editStage === 2 ?
              <Formik validateOnMount={true} initialValues={vehicle} onSubmit={handleNext} validationSchema={validationSchema2}>
                {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                  <Form>
                    <Stack>
                      <div style={{ height: '320px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <span className="dialogHeader">Accounts</span>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack>
                              <span style={captionStyle}>Date Purchased</span>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker value={values.purchaseDate}
                                  onChange={(value) => setFieldValue("purchaseDate", value, true)}
                                  renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                                  inputFormat="DD/MM/yyyy"
                                  disabled={!canUpdate}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack>
                              <span style={captionStyle}>Asset Number</span>
                              <TextField value={values.assetNumber}
                                name="assetNumber"
                                onChange={handleChange}
                                disabled={!canUpdate}
                                variant="standard"
                                sx={{ width: '180px' }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </div>
                      <div style={{ height: '42px' }}>
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                          <Grid item style={{ marginLeft: '16px' }}>
                            <Button
                              className="gridButton"
                              type="button"
                              onClick={(p) => handleBack(values)}
                            >
                              Back
                            </Button>
                          </Grid>
                          <Grid item style={{ marginLeft: '16px' }}>
                            <Button
                              className="gridButton"
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting || !isValid}
                            >
                              <span>Next</span>
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Stack>
                  </Form>
                )}
              </Formik>
              : <></>}

            {editStage === 3 ?
              <Formik initialValues={vehicle} onSubmit={handleNext} validationSchema={validationSchema3}>
                {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                  <Form>
                    <Stack>
                      <div style={{ height: '320px' }}>
                        <Grid container spacing={2}>

                          <Grid item xs={12}>
                            <span className="dialogHeader">Assigned To</span>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack>
                              <span style={captionStyle}>Date Assigned</span>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker value={values.assignedDate}
                                  onChange={(value) => setFieldValue("assignedDate", value, true)}
                                  renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                                  inputFormat="DD/MM/yyyy"
                                  disabled={!canUpdate}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack>
                              <span style={captionStyle}>Employee</span>
                              <AutocompleteWrapper
                                value={values?.employeeID}
                                onChange={(e: any, value: any) => {
                                  if (value) {
                                    setFieldValue("employeeID", value, true);
                                  }
                                }
                                }
                                renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                                options={employees ? employees.map((j) => {
                                  return {
                                    label: (j.name + ' [' + j.employeeNumber + ']') // Display data
                                    , value: j.id
                                    , additionalFilterData: j.legalName // Additional filterable data - @T4888A
                                  };
                                }) : []}
                                filterOptions={employeeFilterOptions}
                                disabled={!canUpdate}
                              />
                            </Stack>
                          </Grid>

                          <Grid item xs={12}>
                            <Stack>
                              <span style={captionStyle}>Private Use</span>
                              <AutocompleteWrapper
                                value={values?.vehiclePrivateUsageID}
                                onChange={(e: any, value: any) => { if (value) { setFieldValue("vehiclePrivateUsageID", value, true); } }}
                                renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                                options={privateUsageTypes ? privateUsageTypes.map((j) => { return { label: j.name, value: j.id }; }) : []}
                                disabled={!canUpdate}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </div>
                      <div style={{ height: '42px' }}>
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                          <Grid item style={{ marginLeft: '16px' }}>
                            <Button
                              className="gridButton"
                              type="button"
                              onClick={(p) => handleBack(values)}
                            >
                              Back
                            </Button>
                          </Grid>
                          <Grid item style={{ marginLeft: '16px' }}>
                            <Button
                              className="gridButton"
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting || !isValid}
                            >
                              <span>Next</span>
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Stack>

                  </Form>
                )}
              </Formik>
              : <></>}

            {editStage === 4 ?
              <Formik initialValues={vehicle} onSubmit={handleSave} validationSchema={validationSchema4}>
                {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                  <Form>
                    <Stack>
                      <div style={{ height: '320px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <span className="dialogHeader">Vehicle Notes</span>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack>
                              <TextField value={values.notes}
                                sx={{ width: '100%' }}
                                name="notes"
                                multiline
                                rows={3}
                                onChange={handleChange}
                                disabled={!canUpdate}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </div>
                      <div style={{ height: '42px' }}>
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                          <Grid item style={{ marginLeft: '16px' }}>
                            <Button
                              className="gridButton"
                              type="button"
                              onClick={(p) => handleBack(values)}
                            >
                              Back
                            </Button>
                          </Grid>
                          <Grid item style={{ marginLeft: '16px' }}>
                            <Button
                              className="gridButton"
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting || !isValid}
                            >
                              <span>Save</span>
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Stack>
                  </Form>
                )}
              </Formik>
              : <></>}

          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};