import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Allowance } from '../api/types';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';
import { useGetUserSecurablesQuery, useUpdateAllowanceMutation, useGetAllowanceTypesQuery, useGetAllowanceLevelsQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Configuration.module.css'
import { Stack } from "@mui/material";

export const AllowanceEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);
  const [Allowance, setAllowance] = useState<Partial<Allowance>>();

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: allowanceTypes } = useGetAllowanceTypesQuery();
  const { data: allowanceLevels } = useGetAllowanceLevelsQuery();
  const [updateAllowance, { isLoading: isUpdating }] = useUpdateAllowanceMutation();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.Travel_Configuration]));
    }
  }, [userSecurables])

  useEffect(() => {
    setAllowance(props.allowance);
  }, [props.allowance])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Formik validation schema
  const validationSchema = yup.object().shape({
    name: yup.string()
      .required('Name is required'),
    shortName: yup.string()
      .required('Short name is required'),
    paymentCode: yup.string()
      .required('Payment Code is required'),
    allowanceRate: yup.number()
      .min(0.01)
      .required('Rate is required'),
    allowanceLevelID: yup.number()
      .min(1)
      .required('Level is required'),
    allowanceTypeID: yup.number()
      .min(1)
      .required('Type is required'),
  });

  const allowance: Partial<Allowance> = props.allowance ? { ...props.allowance, allowanceRate: props.allowance.allowanceRate.toFixed(2) } : {};

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<Allowance>) => {
    if (newValues) {
      const res: any = await updateAllowance(newValues);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        displayToastMessage("info", "Success", "Allowance saved");
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving allowance", errorText);
      }
    }
  }

  const captionStyle = { margin: '6px 0 4px 0' };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "360px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {allowance && allowance.id && allowance.id > 0 ? (
            <span className="dialogHeader">Update Travel Allowance</span>
          )
            : (
              <span className="dialogHeader">Add Travel Allowance</span>
            )
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={allowance} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Name</span>
                        <TextField value={values.name}
                          name="name"
                          onChange={handleChange}
                          disabled={!canUpdate}
                          variant="standard"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Short Name</span>
                        <TextField value={values.shortName}
                          name="shortName"
                          onChange={handleChange}
                          disabled={!canUpdate}
                          variant="standard"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={7}>
                      <Stack>
                        <span style={captionStyle}>Jemini Payment Code</span>
                        <TextField value={values.paymentCode}
                          name="paymentCode"
                          onChange={handleChange}
                          disabled={!canUpdate}
                          variant="standard"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={5}>
                      <Stack>
                        <span style={{ textAlign: "right", ...captionStyle }}>Rate</span>
                        <TextField value={values.allowanceRate}
                          InputProps={{
                            sx: {
                              "& input": {
                                textAlign: "right"
                              }
                            }
                          }}
                          name="allowanceRate"
                          onChange={handleChange}
                          disabled={!canUpdate}
                          variant="standard"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Level</span>
                        <AutocompleteWrapper
                          value={values?.allowanceLevelID}
                          onChange={(e: any, value: any) => { if (value) setFieldValue("allowanceLevelID", value, true); }}
                          renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                          options={allowanceLevels ? allowanceLevels.map((j) => { return { label: j.name, value: j.id }; }) : []}
                          disabled={!canUpdate}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Type</span>
                        <AutocompleteWrapper
                          value={values?.allowanceTypeID}
                          onChange={(e: any, value: any) => { if (value) setFieldValue("allowanceTypeID", value, true); }}
                          renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                          options={allowanceTypes ? allowanceTypes.map((j) => { return { label: j.name, value: j.id }; }) : []}
                          disabled={!canUpdate}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '8px' }}>

                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!canUpdate || isSubmitting || !isValid || !dirty}
                          >
                            {allowance && allowance.id && allowance.id > 0 ? (
                              <span>Update</span>
                            )
                              : (
                                <span>Add</span>
                              )
                            }
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};