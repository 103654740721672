import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import { DataGrid, GridRowModel, GridRenderCellParams, GridCellParams, MuiEvent, GridAlignment } from '@mui/x-data-grid';

import { Formik, Form, FieldArray } from 'formik';
import * as yup from 'yup';

import { NOTIFICATION_TYPES, LocationNotification } from '../api/types';
import {
  useGetUserSecurablesQuery, useGetLocationNotificationsQuery, useUpdateLocationNotificationsMutation
} from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';
import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';

import { setToastMessage } from '../uiHelpers/toastSlice';
import styles from './Configuration.module.css'

//import styles from './Licence.module.css'

interface NotificationFormValues {
  locationNotifications: LocationNotification[]
};

export const TravelAllowanceNotificationEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: locationNotifications, error, refetch, isLoading } = useGetLocationNotificationsQuery(NOTIFICATION_TYPES.TravelAllowanceApproval);

  const [updateLocationNotifications, { isLoading: isUpdating }] = useUpdateLocationNotificationsMutation();

  useEffect(() => {
    if (props.open) {
      refetch();
    }
  }, [props.open]);

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.Travel_Configuration]));
    }
  }, [userSecurables])

  const emptyNotifications: LocationNotification[] = [];

  const initialFormValues: NotificationFormValues = {
    locationNotifications: locationNotifications ?? emptyNotifications
  };

  // Formik validation schema
  const validationSchema = yup.object().shape({ 
    locationNotifications: yup.array().of(
      yup.object().shape({
          emailAddress: yup.array().transform((value, originalValue, context) => {
            if (context.isType(value) && value !== null) {
              return value;
            }
            // Split the string at semicolons to get an array of email addresses
            return originalValue ? String(originalValue).split(/[\s;]+/) : [];
          }).of(yup.string().email())
      })
    ),
  });

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: NotificationFormValues) => {
    if (newValues) {
      const res: any = await updateLocationNotifications(newValues.locationNotifications);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        displayToastMessage("info", "Success", "Travel allowance notification saved");
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving travel allowance notification", errorText);
      }

    }
  };

  const captionStyle = { margin: '6px 0 4px 0' };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { height: "calc(80vh)" } }}
        fullWidth={true}
        maxWidth="md"
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="dialogHeader">Approve Travel Notifications</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={initialFormValues} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <span>Travel notifications will be sent at 0800 each day to all recipients for each location.</span>
                    </Grid>
                    <Grid item xs={4}>
                      <span style={{ fontWeight: 'bold'}}>Location</span>
                    </Grid>
                    <Grid item xs={8}>
                      <span style={{ fontWeight: 'bold' }}>Recipient(s) - semi colon separated for multiple addresses</span>
                    </Grid>
                    <Grid item xs={12} sx={{ maxHeight: "calc(80vh - 220px)", overflow: "auto"}}>
                      <FieldArray name="locationNotifications">
                        {() => (values.locationNotifications.map((locNotification, i) => {
                          const itemErrors = errors.locationNotifications?.length && errors.locationNotifications[i] || {};
                          const itemTouched = touched.locationNotifications?.length && touched.locationNotifications[i] || {};
                          return (
                            <Grid key={i} container alignItems="flex-start" spacing={1} sx={{ marginBottom: '4px'} }>
                              <Grid item xs={4} sx={{ bottom: '0', borderBottom: 'solid #909090 1px' }}>
                                <Typography sx={{ marginTop: '4px', marginBottom: '3px'} }>{values.locationNotifications[i].location}</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <TextField sx={{ width: '100%' }} value={values.locationNotifications[i].emailAddress}
                                  id={`emailAddress${i}`}
                                  name={`locationNotifications.${i}.emailAddress`}
                                  onChange={handleChange}
                                  disabled={!canUpdate}
                                  variant="standard"
                                />
                              </Grid>
                              </Grid>
                          );
                        }))}
                      </FieldArray>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '8px' }}>

                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!canUpdate || isSubmitting || !isValid || !dirty}
                          >
                            {props.propertyItem && props.propertyItem.id && props.propertyItem.id > 0 ? (
                              <span>Update</span>
                            )
                              : (
                                <span>Save</span>
                              )
                            }
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};