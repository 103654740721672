import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Form, Field, FieldRenderProps } from 'react-final-form'
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { TravelAllowance, TravelAllowanceDeleteParm } from '../api/types';
import { useDeleteTravelAllowanceMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { CheckboxWrapper } from '../uiHelpers/FormFieldWrappers';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Travel.module.css'

export const TravelAllowanceDelete = (props: any) => {
  const dispatch = useDispatch();
  const [travelAllowance, setTravelAllowance] = useState<TravelAllowance | null>(null);
  const [travelAllowanceDeleteParm, setTravelAllowanceDeleteParm] = useState<TravelAllowanceDeleteParm | null>(null);

  const [deleteTravelAllowance] = useDeleteTravelAllowanceMutation();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();
  const handleSave = async (delTravelAllowance: TravelAllowanceDeleteParm) => {
    if (delTravelAllowance) {
      const res: any = await deleteTravelAllowance(delTravelAllowance);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        displayToastMessage("info", "", "Travel allowance deleted");
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error deleting travel allowance", errorText);
      }
    }
  };

  useEffect(() => {
    console.log("UseEffect is setting travelAllowance...");
    setTravelAllowance(props.travelAllowance);
  }, [props.travelAllowance]);

  useEffect(() => {
    console.log("UseEffect in travelAllowanceDeleteParm...");
    if (travelAllowance) {
      console.log("UseEffect is setting travelAllowanceDeleteParm...");
      setTravelAllowanceDeleteParm({
        id: travelAllowance.id, travelRecordID: travelAllowance.travelRecordID, closeEmployeeTravel: true
      } as TravelAllowanceDeleteParm );
    }
  }, [travelAllowance]);

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          <span className="dialogHeader">Delete Allowance</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '16px 0', margin: 'auto' }}>
            <Form
              onSubmit={handleSave}
              initialValues={travelAllowanceDeleteParm}
              render={({ handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid style={{ paddingTop: 4 }} item xs={12}>
                      <Field
                        style={{ marginTop: '0' }}
                        name="closeEmployeeTravel"
                        label="Close employee travel record"
                        component={CheckboxWrapper}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <span>Are you sure you want to delete this allowance?</span>
                    </Grid>
                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          type="button"
                          onClick={handleClose}
                        >
                          <span>Cancel</span>
                        </Button>
                      </Grid>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          variant="contained"
                          type="submit"
                          disabled={submitting}
                        >
                          <span>Delete</span>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};