import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';
import * as moment from 'moment';
import { Moment } from "moment";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import { AlertColor } from "@mui/material/Alert";

import { useGetRoleSecurablesQuery, useGetUserSecurablesQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'
import { Role, RoleSecurable } from '../api/types';
import { RoleEdit } from "./RoleEdit";
import { RoleDelete } from "./RoleDelete";
import { RoleUsers } from "./RoleUsers";
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './UserAccess.module.css'

const initialRole: Partial<Role> = { id: -1 };

export const Roles = (props: any) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<string>('');
  const [showRoleEdit, setShowRoleEdit] = useState(false);
  const [showRoleDelete, setShowRoleDelete] = useState(false);
  const [showRoleUsers, setShowRoleUsers] = useState(false);
  const [currentRole, setCurrentRole] = useState(initialRole);

  const [roles, setRoles] = useState([] as Role[]);
  const [canUpdateRole, setCanUpdateRole] = useState(false);
  const [canDeleteRole, setCanDeleteRole] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawRoleSecurables, error, isLoading, refetch } = useGetRoleSecurablesQuery();

  const roleSecurables = useMemo(() => {
    let roles: Role[] = [];
    if (rawRoleSecurables) {
      roles = rawRoleSecurables;
      // Filter if required
      if (filter && filter.length > 0) {
        roles = roles.filter((r) => r.name.toLowerCase().includes(filter.toLowerCase()));
      }
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      roles = roles.map((r: Role) => {
        let newUr = { ...r };
        if (newUr.createDate) newUr.createDate = moment.parseZone(newUr.createDate.toString());
        if (newUr.changeDate) newUr.changeDate = moment.parseZone(newUr.changeDate.toString());
        newUr.roleSecurables = r.roleSecurables.map((rs: RoleSecurable) => {
          let newRs = { ...rs };
          if (newRs.changeDate) newRs.changeDate = moment.parseZone(newRs.changeDate.toString());
          return newRs;
        });
        return newUr;
      });
    }
    return roles;
  }, [rawRoleSecurables, filter]);

  useEffect(() => {
    if (roleSecurables) {
      setRoles(roleSecurables.filter((r) => r.active === true));
    } else {
      setRoles([] as Role[]);
    }
  }, [roleSecurables]);

  // When user securables change (e.g. when they are first retrieved), set UI visibility variables from the securables
  // Note the use of the non-existent -1 securable - this ensures only user with an "isSysAdmin" role will be given permission
  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdateRole(hasUpdatePermission(userSecurables, [-1]));
      setCanDeleteRole(hasDeletePermission(userSecurables, [-1]));
    }
  }, [userSecurables])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleAdd = () => {
    setCurrentRole({ id: 0, name: '' } as Partial<Role>);
    setShowRoleEdit(true);
    //displayToastMessage("warning", "Add Role", "This feature is not yet implemented");
  };

  const handleEdit = (role: Partial<Role>) => {
    setCurrentRole(role);
    setShowRoleEdit(true);
  };

  const handleDelete = (role: Partial<Role>) => {
    setCurrentRole(role);
    setShowRoleDelete(true);
  };

  const handleShowUsers = (role: Partial<Role>) => {
    setCurrentRole(role);
    setShowRoleUsers(true);
  };

  const columns = [
    { field: 'name', headerName: 'Role Name', width: 220 },
    //{ field: 'userCount', headerName: 'User Count', width: 140 },
    {
      field: 'userCount', headerName: 'User Count', sortable: false, filterable: false, disableColumnMenu: true, width: 140
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Button disabled={params.row.userCount < 1} onClick={() => handleShowUsers(params.row)}>
          {params.row.userCount > 0 ? <u>{params.row.userCount}</u> : params.row.userCount}
        </Button>
      ),
    },
    {
      field: 'changeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'changeUser', headerName: 'Updated By', width: 220 },
    { field: 'active', headerName: 'Active', type: 'boolean', width: 150 },
    {
      field: ' ', headerName: 'Actions', width: 150, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Stack direction="horizontal" gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <IconButton disabled={!canUpdateRole} color="primary" size="small"
              onClick={() => handleEdit(params.row)}>
              <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={params.row.active ? 'Delete' : 'Activate'} enterDelay={1000}>
            <IconButton disabled={params.row.active ? !canDeleteRole : !canUpdateRole} color="primary" size="small"
              onClick={() => handleDelete(params.row)}>
              <i className={params.row.active ? 'fa fa-regular fa-trash-can' : 'fa fa-solid fa-trash-arrow-up'} style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

   return (
    <>
       <Container fluid style={{ paddingLeft: '20px' }}>
        <Row>
          <span className="pageHeader">Roles</span>
        </Row>
        <Row>
          <Stack direction="horizontal" gap={3}>
             <div style={{ marginTop: '6px' }}>
               <TextField sx={{ verticalAlign: 'bottom', marginTop: '4px', minWidth: '240px' }}
                 placeholder="search role"
                 value={filter}
                 onChange={(e: any) => setFilter(e.target.value)}
                 variant="standard"
                 InputProps={{
                   startAdornment: (
                     <InputAdornment position="start" sx={{ margin: '4px' }}>
                       <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                     </InputAdornment>
                   ),
                   endAdornment: (
                     < InputAdornment position="end" >
                       <IconButton size='small'
                         onClick={() => setFilter('')}
                       >
                         <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.7em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                       </IconButton>
                     </InputAdornment>
                   )
                 }}
               />
             </div>
            <div className="ms-auto">
               <Button disabled={!canUpdateRole} className={styles.gridButton} variant="outlined" onClick={() => handleAdd()}>Add</Button>
            </div>
          </Stack>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          {error ? (
            <>Oh no, there was an error</>
          ) : isLoading ? (
            <>Loading...</>
          ) : roleSecurables ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 140px)' }}>
              <div style={{ flexGrow: '1' }}>
                     <DataGrid rows={roleSecurables} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} />
              </div>
            </div>
          ) : null}
        </Row>
      </Container>

       <RoleEdit open={showRoleEdit} onClose={() => setShowRoleEdit(false)} role={currentRole} />
       <RoleDelete open={showRoleDelete} onClose={() => setShowRoleDelete(false)} role={currentRole} />
       <RoleUsers open={showRoleUsers} onClose={() => setShowRoleUsers(false)} role={currentRole} />
    </>
  );
}