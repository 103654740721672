import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent } from '@mui/x-data-grid';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import * as moment from 'moment';
import { Moment } from "moment";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import { AlertColor } from "@mui/material/Alert";
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';

import { useGetUsersWithRolesQuery, useGetUserSecurablesQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'
import { UserWithRoles, User, UserSecurable } from '../api/types';
import { ToastMessage, ToastMessageValue } from '../uiHelpers/ToastMessage';
import { UserDelete } from './UserDelete';

import { setToastMessage } from '../uiHelpers/toastSlice';

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './UserAccess.module.css'
import { STATUS_COLOURS } from '../uiHelpers/StatusColours';

export const GetStatusColours = (status: string) => {
  let colour = STATUS_COLOURS.Closed;
  if (status === 'Access') {
    colour = STATUS_COLOURS.Open;
  }
  else if (status === 'SysAdmin Access') {
    colour = STATUS_COLOURS.Waiting;
  }

  return { color: colour + 'ff', backgroundColor: colour + '20' };
};

export const UserManagement = (props: any) => {
  const dispatch = useDispatch();

  const [showUserDelete, setShowUserDelete] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserWithRoles | null>(null);

  const [filter, setFilter] = useState<string>('');

  const [canUpdateUser, setCanUpdateUser] = useState(false);
  const [canDeleteUser, setCanDeleteUser] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: userRoles, error, isLoading, refetch } = useGetUsersWithRolesQuery();

  const navigate = useNavigate();

  const memoUsers = useMemo(() => {
    let users: UserWithRoles[] = [];
    if (userRoles) {
      // Always filter out inactive users
      users = userRoles.filter((ur) => ur.active === true);
      // Filter if required
      if (filter && filter.length > 0) {
        users = users.filter((r) => r.name.toLowerCase().includes(filter.toLowerCase())
          || r.userName.toLowerCase().includes(filter.toLowerCase()));
      }
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      users = users.map((ur) => {
        let newUr = { ...ur };
        if (newUr.lastChangeDate) newUr.lastChangeDate = moment.parseZone(newUr.lastChangeDate.toString());
        if (newUr.lastLoginDate) newUr.lastLoginDate = moment.parseZone(newUr.lastLoginDate.toString());
        return newUr;
      });
    }
    return users;
  }, [userRoles, filter])

  // When user securables change (e.g. when they are first retrieved), set UI visibility variables from the securables
  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdateUser(hasUpdatePermission(userSecurables, [SECURABLE_NAME.User]));
      setCanDeleteUser(hasDeletePermission(userSecurables, [SECURABLE_NAME.User]));
    }
  }, [userSecurables])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleEdit = (user: UserWithRoles) => {
    if (user) {
      const to = '/user/userManagement/userRoles/' + user.id;
      if (window.location.pathname !== to) {
        navigate(to);
      }
    }
  };

  const handleDelete = (user: UserWithRoles) => {
    setCurrentUser(user);
    setShowUserDelete(true);
  };

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    if (canUpdateUser && params.colDef && params.colDef.headerName !== 'Actions') {
      handleEdit(params.row);
    }
    event.defaultMuiPrevented = true;
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 220 },
    { field: 'userName', headerName: 'Email', width: 220 },
    {
      field: 'lastLoginDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Login', width: 180
    },
    {
      field: 'lastChangeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'lastChangeUser', headerName: 'Updated By', width: 220 },
    {
      field: 'userStatus', headerName: 'Status', width: 150
      , renderCell: (params: GridRenderCellParams<string>) => (
        <div className="status" style={GetStatusColours(params.row.userStatus)} >
          {params.value}
        </div>

      )
    },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 100, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Stack direction="horizontal" gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <IconButton disabled={!canUpdateUser} color="primary" size="small"
              onClick={() => handleEdit(params.row)}>
              <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove access" enterDelay={1000}>
            <IconButton disabled={!canDeleteUser} color="primary" size="small"
              onClick={() => handleDelete(params.row)}>
              <i className="fa fa-fw fa-user-xmark" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Container fluid style={{ paddingLeft: '20px' }}>
        <Row>
          <span className='pageHeader'>User Management</span>
        </Row>
        <Row>
          <Stack direction="horizontal" gap={3}>
            <div style={{ marginTop: '6px' }}>
              <TextField sx={{ verticalAlign: 'bottom', marginTop: '4px', minWidth: '240px' }}
                placeholder="search user"
                value={filter}
                onChange={(e: any) => setFilter(e.target.value)}
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ margin: '4px' }}>
                      <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    < InputAdornment position="end" >
                      <IconButton size='small'
                        onClick={() => setFilter('')}
                      >
                        <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.7em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className="ms-auto">
            </div>
          </Stack>
        </Row>
        <Row style={{ marginTop: '6px' }}>
          {error ? (
            <>Oh no, there was an error</>
          ) : isLoading ? (
            <>Loading...</>
          ) : userRoles ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 160px)' }}>
              <div style={{ flexGrow: '1' }}>
                    <DataGrid rows={memoUsers} columns={columns} rowHeight={GridRowHeight} getRowId={(row) => row.id}
                  onCellClick={handleGridCellClick}
                  sx={sxNoCellBorder} />
              </div>
            </div>
          ) : null}
        </Row>
      </Container>

      <UserDelete open={showUserDelete} onClose={() => setShowUserDelete(false)} user={currentUser} />

    </>
  );
}