import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel'
import { AlertColor } from "@mui/material/Alert";
import * as moment from 'moment';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Moment } from "moment";

import { EmployeeDetail } from '../api/types';
import { timezones } from '../api/timezones';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import { EmployeeEdit } from './EmployeeEdit';

import { useGetUserSecurablesQuery, useGetEmployeesDetailQuery } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './Configuration.module.css'

export const EmployeeHome = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<Partial<EmployeeDetail> | null>();
  const [showItemEdit, setShowItemEdit] = useState<boolean>(false);
  const [includeInactive, setIncludeInactive] = useState<boolean>(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawEmployees, error, isLoading } = useGetEmployeesDetailQuery();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.Employee]));
    }
  }, [userSecurables])

  const employees = useMemo(() => {
    let records: EmployeeDetail[] = [];
    if (rawEmployees) {
      records = rawEmployees.filter((r) => includeInactive || r.active === true);
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = records.filter((r) => r.firstName.toLowerCase().includes(filter.toLowerCase())
        || r.preferredName?.toLowerCase().includes(filter.toLowerCase())
        || r.lastName.toLowerCase().includes(filter.toLowerCase())
        || r.employeeNumber.toLowerCase().includes(filter.toLowerCase())
      )
        .map((tr) => {
          let newTr = { ...tr };
          if (newTr.changeDate) newTr.changeDate = moment.parseZone(newTr.changeDate.toString());
          if (newTr.bankAccount?.length === 15) {
            // Split bank account into its components
            newTr.bankCompany = newTr.bankAccount.substr(0, 2);
            newTr.bankBranch = newTr.bankAccount.substr(2, 4);
            newTr.bankAccountNumber = newTr.bankAccount.substr(6, 7);
            newTr.bankAccountSuffix = newTr.bankAccount.substr(13, 2);
            newTr.bankAccountDisplay = newTr.bankCompany + '-' + newTr.bankBranch + '-' + newTr.bankAccountNumber + '-' + newTr.bankAccountSuffix;
          }
          return newTr;
        });
    }
    return records;
  }, [rawEmployees, filter, includeInactive, timezones]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleEdit = (item: EmployeeDetail) => {
    if (item) {
      setSelectedItem(item);
      setShowItemEdit(true);
    }
  };

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    event.defaultMuiPrevented = true;
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    { field: 'employeeNumber', headerName: 'Emp No.', width: 120 },
    { field: 'firstName', headerName: 'First Name', width: 140 },
    { field: 'preferredName', headerName: 'Preferred Name', width: 140 },
    { field: 'lastName', headerName: 'Last Name', width: 160 },
    { field: 'location', headerName: 'Location', width: 160 },
    { field: 'department', headerName: 'Department', width: 220 },
    { field: 'jobTitle', headerName: 'Job Title', width: 220 },
    { field: 'keySkill', headerName: 'Key Skill', width: 220 },
    { field: 'union', headerName: 'Union', width: 120 },
    { field: 'payroll', headerName: 'Payroll Company', width: 160 },
    { field: 'bankAccountDisplay', headerName: 'Bank Account', width: 170 },
    { field: 'email', headerName: 'Email', width: 220 },
    { field: 'homePhone', headerName: 'Home Phone', width: 160 },
    { field: 'mobilePhone', headerName: 'Mobile Phone', width: 160 },
    { field: 'active', headerName: 'Active', type: 'boolean', width: 100 },
    {
      field: 'changeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'changeUser', headerName: 'Updated By', width: 160 },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 100, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
        <Stack direction='row' gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <IconButton disabled={!canUpdate} color="primary" size="small"
              onClick={() => handleEdit(params.row)}>
              <i className="fa fa-fw fa-pen-to-square" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Grid sx={{ margin: '0 8px' }} container spacing={2}>
        <Grid xs={12}>
          <span className='pageHeader'>Employees</span>
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={6}>
          <TextField sx={{ verticalAlign: 'bottom', marginTop: '4px', minWidth: '280px' }}
            placeholder="search employee name or number"
            value={filter}
            onChange={(e: any) => setFilter(e.target.value)}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ margin: '4px' }}>
                  <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                </InputAdornment>
              ),
              endAdornment: (
                < InputAdornment position="end" >
                  <IconButton size='small'
                    onClick={() => setFilter('')}
                  >
                    <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.7em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <FormControlLabel
            sx={{ marginLeft: '16px' }}
            value="activeOnly"
            control={<Switch color="primary" checked={includeInactive}
              onChange={(val: any) => setIncludeInactive(val.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />}
            label="Show inactive"
            labelPlacement="start"
          />
        </Grid>
        <Grid xs={12}>
          {error ? (
            <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
          ) : isLoading ? (
            <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
            ) : employees ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 180px)' }}>
              <div style={{ flexGrow: '1' }}>
                    <DataGrid rows={employees} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                  onCellClick={handleGridCellClick} />
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <EmployeeEdit open={showItemEdit} onClose={() => setShowItemEdit(false)} employee={selectedItem} />

    </>
  );
}