import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grow } from "@mui/material";
import { styled } from '@mui/material/styles';

import { useGetUserSecurablesQuery } from '../api/apiSlice';
import { hasReadPermission, SECURABLE_NAME } from '../userProfile/securableHelper'
import styles from './Configuration.module.css'

import { WorkVisaExpiryNotificationEdit } from './WorkVisaExpiryNotificationEdit';
import { TravelAllowanceNotificationEdit } from './TravelAllowanceNotificationEdit';

export const ConfigurationHome = (props: any) => {
  const [canReadTravelConfig, setCanTravelConfig] = useState(false);
  const [canReadLicenceConfig, setCanReadLicenceConfig] = useState(false);
  const [canReadPPEConfig, setCanReadPPEConfig] = useState(false);
  const [canReadVehicleConfig, setCanReadVehicleConfig] = useState(false);
  const [canReadCountryConfig, setCanReadCountryConfig] = useState(false);
  const [canReadLocationConfig, setCanReadLocationConfig] = useState(false);
  const [canReadWorkVisaConfig, setCanReadWorkVisaConfig] = useState(false);
  const [canReadEmployeeConfig, setCanReadEmployeeConfig] = useState(false);

  const [showWorkVisaExpiryConfiguration, setShowWorkVisaExpiryConfiguration] = useState<boolean>(false);
  const [showTravelAllowanceNotificationEdit, setShowTravelAllowanceNotificationEdit] = useState<boolean>(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();

  const navigate = useNavigate();


  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanTravelConfig(hasReadPermission(userSecurables, [SECURABLE_NAME.Travel_Configuration]));
      setCanReadLicenceConfig(hasReadPermission(userSecurables, [SECURABLE_NAME.DriverLicence_Configuration]));
      setCanReadPPEConfig(hasReadPermission(userSecurables, [SECURABLE_NAME.PPE_Configuration]));
      setCanReadVehicleConfig(hasReadPermission(userSecurables, [SECURABLE_NAME.VehicleRegister_Configuration]));
      setCanReadCountryConfig(hasReadPermission(userSecurables, [SECURABLE_NAME.Country]));
      setCanReadLocationConfig(hasReadPermission(userSecurables, [SECURABLE_NAME.Location]));
      setCanReadWorkVisaConfig(hasReadPermission(userSecurables, [SECURABLE_NAME.WorkVisa_Configuration]));
      setCanReadEmployeeConfig(hasReadPermission(userSecurables, [SECURABLE_NAME.Employee]));
    }
  }, [userSecurables])

  const handleNavigate = (target: string) => {
    console.log("handleNavigate: " + target);
    const to = '/configuration/' + target;
    if (window.location.pathname !== to) {
      navigate(to);
    }
  };

  const cardStyle = { border: '#e0e0e0 solid 1px', boxShadow: 'none', minWidth: "260px", minHeight: "260px", backgroundColor: "#f0f0f0", margin: "0 2em 2em 0" };

  const StyleCaption = styled('span')({ textAlign: "center", marginBottom: "8px", fontWeight: '700' });

  return (
    <>
      <Grid container sx={{ display: 'flex', marginLeft: '20px', marginTop: '8px' }}>
        <Grid sx={{ marginBottom: '12px' }} xs={12}>
          <span className='pageHeader'>Configuration</span>
        </Grid>
        <Grid>
          {canReadTravelConfig ?
            <Grow in={true}>
              <Card sx={cardStyle}>
                <CardContent>
                  <Stack>
                    <StyleCaption>Travel</StyleCaption>
                    <Button className={styles.gridButton} onClick={() => handleNavigate("jobs")}>Travel Jobs</Button>
                    <Button className={styles.gridButton} onClick={() => handleNavigate("allowances")}>Allowances</Button>
                    <Button className={styles.gridButton} onClick={() => setShowTravelAllowanceNotificationEdit(true)}>Travel Notification</Button>
                  </Stack>
                </CardContent>
              </Card>
            </Grow>
            : <></>
          }
        </Grid>
        <Grid>
          {canReadLicenceConfig ?
            <Grow in={true}>
              <Card sx={cardStyle}>
                <CardContent>
                  <Stack>
                    <StyleCaption>Driver Licence</StyleCaption>
                  </Stack>
                </CardContent>
              </Card>
            </Grow>
            : <></>
          }
        </Grid>
        <Grid>
          {canReadPPEConfig ?
            <Grow in={true}>
              <Card sx={cardStyle}>
                <CardContent>
                  <Stack>
                    <StyleCaption>PPE & Company Property</StyleCaption>
                    <Button className={styles.gridButton} onClick={() => handleNavigate("propertyCategories")}>Property Categories</Button>
                    <Button className={styles.gridButton} onClick={() => handleNavigate("propertyItems")}>Property Items</Button>
                  </Stack>
                </CardContent>
              </Card>
            </Grow>
            : <></>
          }
        </Grid>
        <Grid>
          {canReadVehicleConfig ?
            <Grow in={true}>
              <Card sx={cardStyle}>
                <CardContent>
                  <Stack>
                    <StyleCaption>Vehicle Register</StyleCaption>
                    <Button className={styles.gridButton} onClick={() => handleNavigate("vehicleMakes")}>Vehicle Makes</Button>
                    <Button className={styles.gridButton} onClick={() => handleNavigate("vehicleModels")}>Vehicle Models</Button>
                    <Button className={styles.gridButton} onClick={() => handleNavigate("vehicleUnavailabilityTypes")}>Unavailability Types</Button>
                  </Stack>
                </CardContent>
              </Card>
            </Grow>
            : <></>
          }
        </Grid>
        <Grid>
          {canReadCountryConfig || canReadLocationConfig ?
            <Grow in={true}>
              <Card sx={cardStyle}>
                <CardContent>
                  <Stack>
                    <StyleCaption>Locations</StyleCaption>
                    {canReadCountryConfig ?
                      <Button className={styles.gridButton} onClick={() => handleNavigate("countries")}>Countries</Button>
                      : <></>}
                    {canReadLocationConfig ?
                      <Button className={styles.gridButton} onClick={() => handleNavigate("locations")}>Locations</Button>
                      : <></>}
                  </Stack>
                </CardContent>
              </Card>
            </Grow>
            : <></>
          }
        </Grid>
        <Grid>
          {canReadWorkVisaConfig ?
            <Grow in={true}>
              <Card sx={cardStyle}>
                <CardContent>
                  <Stack>
                    <StyleCaption>Work Visa</StyleCaption>
                    <Button className={styles.gridButton} onClick={() => handleNavigate("visaTypes")}>Work Visa Types</Button>
                    <Button className={styles.gridButton} onClick={() => setShowWorkVisaExpiryConfiguration(true)}>Visa Expiry Notification</Button>
                  </Stack>
                </CardContent>
              </Card>
            </Grow>
            : <></>
          }
        </Grid>
        <Grid>
          {canReadEmployeeConfig ?
            <Grow in={true}>
              <Card sx={cardStyle}>
                <CardContent>
                  <Stack>
                    <StyleCaption>Employees</StyleCaption>
                    <Button className={styles.gridButton} onClick={() => handleNavigate("employees")}>Employee Details</Button>
                  </Stack>
                </CardContent>
              </Card>
            </Grow>
            : <></>
          }
        </Grid>

      </Grid>

      <WorkVisaExpiryNotificationEdit open={showWorkVisaExpiryConfiguration} onClose={() => setShowWorkVisaExpiryConfiguration(false)} />
      <TravelAllowanceNotificationEdit open={showTravelAllowanceNotificationEdit} onClose={() => setShowTravelAllowanceNotificationEdit(false)} />

    </>
  );
}