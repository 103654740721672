import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent } from '@mui/x-data-grid';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import * as moment from 'moment';
import { Moment } from "moment";
import fileDownload from 'js-file-download';
import axios from "axios";

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { TRAVEL_STATUS, TravelAllowanceExport, TravelRecord } from '../api/types';
import {
  useGetTravelRecordsEligibleForExportQuery, useSetTravelRecordsExportedMutation, getAccessToken
  , useGetNextTravelExportPayCycleNumberQuery
} from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './Travel.module.css'
import { GetStatusColours } from './TravelHome';

// @T4837A
interface TravelExportRecord {
  payCycleNumber: number;
}

export const TravelExport = (props: any) => {
  const dispatch = useDispatch();

  const [travelExportRecord, setTravelExportRecord] = useState<Partial<TravelExportRecord>>({ payCycleNumber: 1 }); // @T4837A

  const { data: rawTravelRecords, error: errorTravelRecords, isLoading: isLoadingTravelRecords, refetch: refetchTravelRecords } = useGetTravelRecordsEligibleForExportQuery();
  const { data: nextPayCycleNumber, refetch: refetchNextPayCycleNumber } = useGetNextTravelExportPayCycleNumberQuery(); // @T4837A
  const [setTravelRecordsExported] = useSetTravelRecordsExportedMutation();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  useEffect(() => {
    if (props.open) {
      refetchTravelRecords(); // Bypass RTK-Query caching so we always get up-to-date data
      refetchNextPayCycleNumber(); // @T4837A
    }
  }, [props.open])

  // @T4837A
  useEffect(() => {
    setTravelExportRecord({ payCycleNumber: nextPayCycleNumber });
  }, [nextPayCycleNumber])

  const travelRecords = useMemo(() => {
    let records: TravelRecord[] = [];
    if (rawTravelRecords) {
      records = rawTravelRecords;
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = records.map((tr) => {
        let newTr = { ...tr };
        if (newTr.changeDate) newTr.changeDate = moment.parseZone(newTr.changeDate.toString());
        if (newTr.travelDate) newTr.travelDate = moment.parseZone(newTr.travelDate.toString());
        return newTr;
      });
    }
    return records;
  }, [rawTravelRecords]);

  const getClassName = (statusId: number) => {
    let className = '';
    switch (statusId) {
      case 1: { className = styles.statusNew; break; }
      case 2: { className = styles.statusApproved; break; }
      case 3: { className = styles.statusExported; break; }
    }
    return className;
  }


  // Formik validation schema - @T4837A
  const validationSchema = yup.object().shape({
    payCycleNumber: yup.number()
      .min(1)
      .required('Pay cycle number is required'),
  });

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleExport = async (newValues: Partial<TravelExportRecord>) => {
    //
    // NOTE. We use js-file-download to export the data to two files...
    //

    // We're now using Axios to run the export record query directly - this way the query is called only once and so only
    // one TravelExport record is created.
    if (newValues) {
      const accessToken = await getAccessToken();
      const payCycleNumber = newValues.payCycleNumber;

      axios.get('/api/data/getTravelAllowancesForExport?payCycle=' + payCycleNumber, {
        headers: { 'Authorization': `Bearer ${accessToken}` }
      }).then(async (response) => {
        const exportAllowances: TravelAllowanceExport[] = response.data;
        if (exportAllowances && exportAllowances.length > 0) {
          // Get travel exportId
          const travelExportId = exportAllowances[0].travelExportID;

          let nimbusData = 'Daily Batch Report for Batch ID:,,,,,,,,' + payCycleNumber + '\n';
          nimbusData += 'Job Number,,Employee ID,,,,,Allowance Date,Amount\n';
          //let jeminiData = 'JemID (Employee ID),Date,Pay Cycle Name,Trans Code,Amount,Quantity\n'; // @T4836D
          let jeminiData = ''; // Remove header line for Jemini - @T4836A
          let bankFileData = ''; // @T4848A

          exportAllowances.forEach((a) => {
            const travelDate = moment.parseZone(a.travelDate.toString());

            nimbusData += a.jobNumber + ',,' + a.employeeNumber + ',,,,,' + travelDate.format('DD/MM/YYYY') + ','
              + a.allowanceRate.toFixed(2) + '\n'; // @T4835C

            jeminiData += a.employeeNumber + ',' + travelDate.format('DD-MM-YYYY') + ',OOP' + payCycleNumber + ',' + a.paymentCode + ','
              + a.allowanceRate + ',1\n';

            bankFileData += a.allowanceRate.toFixed(2) + "," + a.bankAccount + "," + a.employeeName + ",,,,OOP Allowance,,\n"; // @T4848A

          });
          fileDownload(nimbusData, 'OOPNimbus.csv');
          //fileDownload(jeminiData, 'OOPJemini.csv'); // @T4850D
          fileDownload(bankFileData, 'OOPBank.txt'); // @T4848A

          // Set travel records' status to exported
          console.log("Calling 'setTravelRecordsExported'");
          const res: any = await setTravelRecordsExported(travelExportId);
          // If all is well there should be some data returned
          if (res.data !== undefined) {
            props.onClose();
            displayToastMessage("info", "Travel Export", "Travel allowances successfully exported");
          } else {
            const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
            displayToastMessage("error", "Error updating travel record export status", errorText);
          }
        }
      }).catch((error) => {
        displayToastMessage("error", "Error getting travel allowances for export", error.message);
      });
    }
  };

  const columns = [
    { field: 'id', headerName: 'Travel ID', flex: 1 },
    {
      field: 'travelDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("ddd Do MMM YYYY");
      }
      , headerName: 'Travel Date', flex: 2
    },
    { field: 'location', headerName: 'Port', flex: 2 },
    {
      field: 'status', headerName: 'Status', flex: 1.5
      , renderCell: (params: GridRenderCellParams<string>) => (
        <div className="status" style={GetStatusColours(params.row.statusID)} >
          {params.value}
        </div>
      )
    },
  ];

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "50%" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          <span className="dialogHeader">Export Travel</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '16px 0', margin: 'auto' }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid style={{ paddingTop: 4 }} item xs={12}>
              </Grid>
              <Grid item xs={12}>
                {errorTravelRecords ? (
                  <>Oh no, there was an error</>
                ) : isLoadingTravelRecords ? (
                  <>Loading...</>
                ) : travelRecords ? (
                  <div style={{ display: 'flex', height: 'calc(50vh)' }}>
                    <div style={{ flexGrow: '1' }}>
                      <DataGrid rows={travelRecords} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id} />
                    </div>
                  </div>
                ) : null}
              </Grid>
              <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                <Formik initialValues={travelExportRecord} enableReinitialize={true} onSubmit={handleExport} validationSchema={validationSchema}>
                  {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                    <Form>
                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item>
                          <TextField value={values.payCycleNumber}
                            label="Pay Cycle"
                            name="payCycleNumber"
                            onChange={handleChange}
                            variant="standard"
                            sx={{ width: '180px' }} />
                        </Grid>
                        <Grid item style={{ marginLeft: '20px', marginTop: '10px' }}>
                          <Button
                            className={styles.gridButton}
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '10px', marginTop: '10px' }}>
                          <Button
                            className={styles.gridButton}
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting || !isValid}
                          >
                            <span>Export ({travelRecords && travelRecords.length})</span>
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};