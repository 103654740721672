import * as React from "react";
import { useState, useEffect, useMemo, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
//import Stack from 'react-bootstrap/Stack';
import Grid from "@mui/material/Grid";
import List from '@mui/material/List';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { FixedSizeList, VariableSizeList, ListChildComponentProps } from 'react-window';

import { useGetEmployeesQuery } from '../api/apiSlice';
import { EmployeeSummary } from '../api/types';

export const EmployeeList = (props: any) => {
  const [showActiveSwitch, setShowActiveSwitch] = useState<boolean>(true);
  const [includeInactive, setIncludeInactive] = useState<boolean>(false);
  const [showPreferredName, setShowPreferredName] = useState<boolean>(false);
  const [listHeight, setListHeight] = useState(400);
  const [listWidth, setListWidth] = useState(360);
  const [employeeFilter, setEmployeeFilter] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeSummary>();
  const [sortedEmployees, setSortedEmployees] = useState<EmployeeSummary[]>([]);

  const [listDivHeight, setListDivHeight] = useState<string>('calc(100vh - 128px)');

  //const { data: rawEmployees, error, isLoading } = useGetEmployeesQuery({ includeInactive: includeInactive, maxInactiveDays: 90 });
  const { data: rawEmployees, error, isLoading } = useGetEmployeesQuery({ includeInactive: true, maxInactiveDays: 90 });

  const ref = useRef<HTMLDivElement | null>(null);

  const resizeHandler = () => {
    setListHeight(ref.current?.clientHeight ? ref.current?.clientHeight : 400);
    setListWidth(ref.current?.clientWidth ? ref.current?.clientWidth - 10 : 270);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    // Cleanup function
    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, []);

  useEffect(() => {
    setShowPreferredName(props.showPreferredName ? true : false);
  }, [props.showPreferredName]);

  useEffect(() => {
    setShowActiveSwitch(props.showActiveSwitch ? true : false);
  }, [props.showActiveSwitch]);

  useEffect(() => {
    // Set the height of the div containing the employee list...
    setListDivHeight(props.showActiveSwitch ? 'calc(100vh - 128px)' : 'calc(100vh - 96px)');
    // ...and then set the initial dimensions of the list itself
    setListHeight(ref.current?.clientHeight ? ref.current?.clientHeight : 400);
    setListWidth(ref.current?.clientWidth ? ref.current?.clientWidth - 10 : 270);
  }, [showActiveSwitch]);

  useEffect(() => {
    if (rawEmployees) {
      let records: EmployeeSummary[] = [...rawEmployees];

      setSortedEmployees(
        records.sort((a, b) => {
            if ((showPreferredName ? a.name : a.legalName) < (showPreferredName ? b.name : b.legalName)) {
              return -1
            }
            if ((showPreferredName ? a.name : a.legalName) > (showPreferredName ? b.name : b.legalName)) {
              return 1
            }
            return 0
          })
      );
    }
  }, [rawEmployees, showPreferredName]);

  const employees = useMemo(() => {
    let records: EmployeeSummary[] = [];

    if (sortedEmployees) {

      records = sortedEmployees.filter((r) => includeInactive || r.active === true);
      // Filter the employee list, if required
      if (employeeFilter && employeeFilter.length > 0) {
        records = records
          .filter((r) => r.name.toLowerCase().includes(employeeFilter.toLowerCase())
          || r.legalName.toLowerCase().includes(employeeFilter.toLowerCase())
          || r.employeeNumber.toLowerCase().includes(employeeFilter.toLowerCase()));
      }
    }
    return records;
  }, [sortedEmployees, employeeFilter, includeInactive]);

  const handleFilterChanged = (e: any) => {
    setEmployeeFilter(e.target.value);
  }

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;

    if (employees && employees.length > index) {
      const employee = employees[index];
      return (
        <ListItem style={style} key={index} component="div" disablePadding>
          <ListItemButton style={{ maxHeight: '34px' }} selected={selectedEmployee && employee.id === selectedEmployee.id} onClick={(event) => handleListItemClick(event, employee)}>
            <ListItemText>
              <Stack direction="row">
                <Typography sx={{ width: '40px' }}>{employee.employeeNumber}</Typography>
                <Typography noWrap={true} sx={{ marginLeft: '4px' }}>{showPreferredName ? employee.name : employee.legalName}</Typography>
              </Stack>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      );
    } else {
      return (<></>);
    }
  }

  const handleListItemClick = (e: any, employee: EmployeeSummary) => {
    //console.log("Employee " + employee.name + " selected");
    setSelectedEmployee(employee);
    if (props.onSelected) {
      props.onSelected(employee);
    }
  };

  return (
    <>
      <Grid container sx={{ backgroundColor: 'transparent', width: '280px', marginLeft: 0, paddingLeft: 0 }}>
        <Grid item xs={12}>
          <div style={{ marginTop: '6px', marginLeft: '12px' }}>
            <TextField sx={{ width: 'calc(100% - 12px)', backgroundColor: 'transparent' }}
              placeholder="search employee"
              value={employeeFilter}
              onChange={handleFilterChanged}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ margin: '4px' }}>
                    <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  < InputAdornment position="end" >
                    <IconButton size="small"
                      onClick={() => setEmployeeFilter('')}
                    >
                      <i className="fa fa-fw fa-xmark" style={{ fontSize: '0.7em', color: employeeFilter && employeeFilter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
        </Grid>
        {showActiveSwitch ?
          <Grid item xs={12} sx={{ marginBottom: 0 }}>
            <div style={{ margin: '12px 8px 8px 8px' }}>
              <FormControlLabel
                sx={{ marginLeft: '4px', '.MuiFormControlLabel-label': { fontWeight: '500' } }}
                value="activeOnly"
                control={<Switch size="small" sx={{ marginLeft: '12px' }} color="primary" checked={includeInactive}
                  onChange={(val: any) => setIncludeInactive(val.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
                label="Show inactive employees"
                labelPlacement="start"
              />
            </div>
          </Grid>
          : <></>}
        <Grid item xs={12}>
          <div ref={ref} style={{
            marginTop: showActiveSwitch ? 0 : '12px', height: listDivHeight, width: '100%'
            , backgroundColor: 'transparent'
          }}>
            {error ? (
              <Typography sx={{ marginLeft: '18px' }}>Oh no, there was an error</Typography>
            ) : isLoading ? (
              <Typography sx={{ marginLeft: '18px' }}>Loading...</Typography>
            ) : employees ? (
              <FixedSizeList
                height={listHeight}
                width={listWidth}
                itemSize={34}
                itemCount={employees.length}
                overscanCount={5}

              >
                {renderRow}
              </FixedSizeList>
            ) : null}
          </div>
        </Grid>
      </Grid>

    </>
  );

  /*
  return (
    <>
      <Container fluid style={{ backgroundColor: '#e0e0e0' }}>
        <Row>
          <div style={{ marginTop: '12px', marginLeft: '12px' }}>
            <TextField sx={{ width: 'calc(100% - 20px)', backgroundColor: 'white' }}
            placeholder="search employee"
            value={employeeFilter}
            onChange={handleFilterChanged}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ margin: '4px' }}>
                  <i className="fa fa-fw fa-magnifying-glass" style={{ fontSize: '1.0em', color: '#c0c0c0' }} />
                </InputAdornment>
              ),
              endAdornment: (
                < InputAdornment position="end" >
                  <IconButton
                    onClick={() => setEmployeeFilter('')}
                  >
                    <i className="fa fa-fw fa-xmark" style={{ fontSize: '1.0em', color: '#909090' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          </div>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          {error ? (
            <>Oh no, there was an error</>
          ) : isLoading ? (
            <>Loading...</>
          ) : employees ? (
            <Box
              sx={{ width: '100%', height: 'calc(100vh - 94px)', maxWidth: 380, bgcolor: '#e0e0e0' }}
            >
              <AutoSizer>
                {({ height, width }) => (
                  <FixedSizeList
                    height={height}
                    width={width}
                    itemSize={46}
                    itemCount={employees.length}
                    overscanCount={5}
                  >
                    {renderRow}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Box>
          ) : null}
        </Row>
      </Container>

    </>
  );
  */
}