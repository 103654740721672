import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as moment from 'moment';
import { Moment } from "moment";

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { WORK_VISA_NOTIFICATION_UNITS, NotificationItem } from '../api/types';
import {
  useGetUserSecurablesQuery, useGetWorkVisaExpiryNotificationQuery, useUpdateWorkVisaExpiryNotificationMutation
} from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';

import { setToastMessage } from '../uiHelpers/toastSlice';

//import styles from './Licence.module.css'

export const WorkVisaExpiryNotificationEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawWorkVisaExpiryNotification, refetch } = useGetWorkVisaExpiryNotificationQuery();

  const [updateWorkVisaExpiryNotification, { isLoading: isUpdating }] = useUpdateWorkVisaExpiryNotificationMutation();

  useEffect(() => {
    if (props.open) {
      refetch();
    }
  }, [props.open]);

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.WorkVisa_Configuration]));
    }
  }, [userSecurables])

  const workVisaExpiryNotification = useMemo(() => {
    let item: Partial<NotificationItem> = {};

    if (rawWorkVisaExpiryNotification) {
      item = { ...rawWorkVisaExpiryNotification };
    }
    return item;
  }, [rawWorkVisaExpiryNotification]);

  // Formik validation schema
  const validationSchema = yup.object().shape({
    timespan: yup.number()
      .min(1)
      .required('Timespan is required'),
    timespanUnit: yup.number()
      .min(1)
      .required('Timespan unit is required'),
    emailAddress: yup.array().transform((value, originalValue, context) => {
        if (context.isType(value) && value !== null) {
          return value;
        }
        // Split the string at semicolons to get an array of email addresses
        return originalValue ? String(originalValue).split(/[\s;]+/) : [];
      }).of(yup.string().email()),
  });

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<NotificationItem>) => {
    if (newValues) {
      const res: any = await updateWorkVisaExpiryNotification(newValues);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        displayToastMessage("info", "Success", "Work visa expiry notification saved");
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving work visa expiry notification", errorText);
      }

    }
  };

  const captionStyle = { margin: '6px 0 4px 0' };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "800px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="dialogHeader">Work Visa Expiry Notification</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={workVisaExpiryNotification} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Stack>
                    <div>
                      <Grid container spacing={2}>

                        <Grid item xs={12}>
                          <Stack direction="row">
                            <span style={{ margin: '4px 12px 0 0' }}>Send expiry alerts</span>
                            <div style={{ marginRight: '12px' }}>
                              <TextField value={values.timespan}
                                name="timespan"
                                onChange={handleChange}
                                disabled={!canUpdate}
                                variant="standard"
                                inputProps={{ sx: { marginRight: '12px', width: '40px', textAlign: 'center' } }}
                              />
                            </div>
                            <AutocompleteWrapper
                              value={values?.timespanUnit}
                              onChange={(e: any, value: any) => { if (value) { setFieldValue("timespanUnit", value, true); } }}
                              renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '120px' }} variant="standard" />); }}
                              options={[
                                { label: 'Day(s)', value: WORK_VISA_NOTIFICATION_UNITS.Days },
                                { label: 'Month(s)', value: WORK_VISA_NOTIFICATION_UNITS.Months },
                                { label: 'Years(s)', value: WORK_VISA_NOTIFICATION_UNITS.Years },
                              ]}
                              disabled={!canUpdate}
                            />
                            <span style={{ margin: '4px 0 0 12px' }}>before visa expiry date.</span>
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>Recipient email addresses (semicolon separated)</span>
                            <TextField value={values.emailAddress}
                              sx={{ width: '100%' }}
                              name="emailAddress"
                              multiline
                              rows={3}
                              onChange={handleChange}
                              disabled={!canUpdate}
                            />
                          </Stack>
                        </Grid>

                      </Grid>
                    </div>
                    <div style={{ height: '42px', marginTop: '12px' }}>
                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className="gridButton"
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className="gridButton"
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!canUpdate || isSubmitting || !isValid || !dirty}
                          >
                            <span>Save</span>
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Stack>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};