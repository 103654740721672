import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Routes, Route, useNavigate } from 'react-router-dom';
import SideNav, { NavItem, NavIcon, NavText, SideNavStyles } from '@trendmicro/react-sidenav';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import styles from './PageLayout.module.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import { TravelHome } from "./travel/TravelHome";
import { TravelAllowances } from "./travel/TravelAllowances";
import { UserHome } from "./userAccess/UserHome";
import { Dashboard } from "./dashboard/Dashboard";
import { ProfileData } from "./userProfile/ProfileData";
import { UserManagement } from './userAccess/UserManagement';
import { Roles } from './userAccess/Roles';
import { RoleSecurables } from './userAccess/RoleSecurables';
import { UserRoles } from './userAccess/UserRoles';
import { LicenceHome } from "./licence/LicenceHome";
import { PropertyHome } from "./property/PropertyHome";
import { ConfigurationHome } from "./configuration/ConfigurationHome";
import { PropertyCategoryHome } from './configuration/PropertyCategoryHome';
import { PropertyItemHome } from './configuration/PropertyItemHome';
import { VehicleHome } from './vehicle/VehicleHome';
import { WorkVisaHome } from './workVisa/WorkVisaHome';
import { VehicleMakeHome } from './configuration/VehicleMakeHome';
import { VehicleModelHome } from './configuration/VehicleModelHome';
import { VehicleUnavailabilityHome } from './vehicle/VehicleUnavailabilityHome';
import { VehicleAssignmentHistory } from './vehicle/VehicleAssignmentHistory';
import { CountryHome } from './configuration/CountryHome';
import { LocationHome } from './configuration/LocationHome';
import { WorkVisaTypeHome } from './configuration/WorkVisaTypeHome';
import { VehicleUnavailabilityTypeHome } from './configuration/VehicleUnavailabilityTypeHome';
import { JobHome } from "./configuration/JobHome";
import { AllowanceHome } from "./configuration/AllowanceHome";
import { EmployeeHome } from "./configuration/EmployeeHome";



import { useGetUserSecurablesQuery } from './api/apiSlice';
import { hasReadPermission, SECURABLE_NAME } from './userProfile/securableHelper'
import { ToastMessage } from './uiHelpers/ToastMessage';
import { selectToastMessage } from './uiHelpers/toastSlice';

const navWidthCollapsed = 44;
const navWidthExpanded = 220;

/*
 * Side-bar Component
 */
const SideNavbar = (props) => {
  const [open, setOpen] = useState(false);
  const [showTravelNav, setShowTravelNav] = useState(false);
  const [showUserNav, setShowUserNav] = useState(false);
  const [showLicenceNav, setShowLicenceNav] = useState(false);
  const [showPropertyNav, setShowPropertyNav] = useState(false);
  const [showVehicleNav, setShowVehicleNav] = useState(false);
  const [showVisaNav, setShowVisaNav] = useState(false);
  const [showConfigNav, setShowConfigNav] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen(!open)
    console.log("Open state set to: " + open);
  };

  const handleSelected = (selected) => {
    console.log("HandleSelected: " + selected);
    const to = '/' + selected;
    if (window.location.pathname !== to) {
      navigate(to);
    }
  };

  // When user securables change (e.g. when they are first retrieved), set Nav button visibility variables from the securables
  useEffect(() => {
    if (props.userSecurables && props.userSecurables.length > 0) {
      setShowTravelNav(hasReadPermission(props.userSecurables, [SECURABLE_NAME.Travel]));
      setShowUserNav(hasReadPermission(props.userSecurables, [SECURABLE_NAME.User, SECURABLE_NAME.Role]));
      setShowLicenceNav(hasReadPermission(props.userSecurables, [SECURABLE_NAME.DriverLicence]));
      setShowPropertyNav(hasReadPermission(props.userSecurables, [SECURABLE_NAME.PPE]));
      setShowConfigNav(hasReadPermission(props.userSecurables, [SECURABLE_NAME.Travel_Configuration, SECURABLE_NAME.DriverLicence_Configuration
        , SECURABLE_NAME.PPE_Configuration, SECURABLE_NAME.VehicleRegister_Configuration]));
      setShowVehicleNav(hasReadPermission(props.userSecurables, [SECURABLE_NAME.VehicleRegister]));
      setShowVisaNav(hasReadPermission(props.userSecurables, [SECURABLE_NAME.WorkVisa]));
    }
  }, [props.userSecurables])

  const iconStyle = { fontSize: '1.2em', color: 'gray' };
  const iconTextStyle = { fontSize: '13px', marginLeft: '8px', color: 'gray' };

  const toggleButton = (parm) => {
    const { style, onClick } = parm;
    return (
      <div style={{ display: 'flex', margin: 0, padding: '8px 4px' }}>
        <IconButton size="small"
          onClick={onClick}
        >
          {open ? 
            <i className="fa fa-fw fa-angle-left" style={iconStyle} />
            : 
            <i className="fa fa-fw fa-bars" style={iconStyle} />
          }
        </IconButton>
      </div>
    );
  };

  return (
    <div style={{ position: 'relative', height: 'calc(100vh - 40px)' }}>
      <SideNav expanded={open} onSelect={handleSelected} onToggle={handleToggle}
        style={{ minWidth: open ? navWidthExpanded : navWidthCollapsed, backgroundColor: "transparent", borderRight: '#e8e8e8 1px solid' }}>
        {/*<SideNav.Toggle style={{ width: navWidthCollapsed, height: '44px' }} />*/}
        <SideNav.Toggle style={{ width: navWidthCollapsed, height: '44px' }} componentClass={toggleButton}>
          {/*<Button sx={{ margin: 0, padding: 0, maxWidth: navWidthCollapsed, height: '44px' }}>X</Button>*/}
        </SideNav.Toggle>
        <SideNav.Nav style={{ width: open ? navWidthExpanded : navWidthCollapsed }} defaultSelected="home">
          <NavItem eventKey="">
            <NavIcon style={{ width: navWidthCollapsed, opacity: 1.0 }}>
              <i className="fa fa-fw fa-chart-simple" style={iconStyle} />
            </NavIcon>
            <NavText>
              <span style={iconTextStyle}>Home</span>
            </NavText>
          </NavItem>
          {showTravelNav ? (
            <NavItem eventKey="travel">
              <NavIcon style={{ width: navWidthCollapsed, opacity: 1.0 }}>
                <i className="fa fa-fw fa-plane" style={iconStyle} />
              </NavIcon>
              <NavText>
                <span style={iconTextStyle}>Travel</span>
              </NavText>
            </NavItem>
          ) : (<></>)
          }
          {showLicenceNav ? (
            <NavItem eventKey="licence">
              <NavIcon style={{ width: navWidthCollapsed, opacity: 1.0 }}>
                <i className="fa fa-solid fa-id-card" style={iconStyle} />
              </NavIcon>
              <NavText>
                <span style={iconTextStyle}>Licence</span>
              </NavText>
            </NavItem>
          ) : (<></>)
          }
          {showPropertyNav ? (
            <NavItem eventKey="property">
              <NavIcon style={{ width: navWidthCollapsed, opacity: 1.0 }}>
                <i className="fa fa-solid fa-helmet-safety" style={iconStyle} />
              </NavIcon>
              <NavText>
                <span style={iconTextStyle}>PPE & Property</span>
              </NavText>
            </NavItem>
          ) : (<></>)
          }
          {showVehicleNav ? (
            <NavItem eventKey="vehicle">
              <NavIcon style={{ width: navWidthCollapsed, opacity: 1.0 }}>
                <i className="fa fa-solid fa-car-side" style={iconStyle} />
              </NavIcon>
              <NavText>
                <span style={iconTextStyle}>Vehicle Register</span>
              </NavText>
            </NavItem>
          ) : (<></>)

          }
          {showVisaNav ? (
            <NavItem eventKey="visa">
              <NavIcon style={{ width: navWidthCollapsed, opacity: 1.0 }}>
                <i className="fa fa-solid fa-passport" style={iconStyle} />
              </NavIcon>
              <NavText>
                <span style={iconTextStyle}>Work Visa</span>
              </NavText>
            </NavItem>
          ) : (<></>)

          }
          {showUserNav ? (
            <NavItem eventKey="user">
              <NavIcon style={{ width: navWidthCollapsed, opacity: 1.0 }}>
                <i className="fa fa-fw fa-user-shield" style={iconStyle} />
              </NavIcon>
              <NavText>
                <span style={iconTextStyle}>User Access</span>
              </NavText>
            </NavItem>
          ) : (<></>)
          }
          {showConfigNav ? (
            <NavItem eventKey="configuration">
              <NavIcon style={{ width: navWidthCollapsed, opacity: 1.0 }}>
                <i className="fa fa-fw fa-screwdriver-wrench" style={iconStyle} />
              </NavIcon>
              <NavText>
                <span style={iconTextStyle}>Configuration</span>
              </NavText>
            </NavItem>
          ) : (<></>)
          }
        </SideNav.Nav>
      </SideNav>
      <div style={{
        position: "relative", overflow: "hidden", transition: "all .15s",
        padding: "0", marginLeft: open ? navWidthExpanded : navWidthCollapsed + "px",
      }}>
        <Routes>
          <Route exact path='/' element={<Dashboard />} />
          <Route path='travel' element={<TravelHome />} />
          <Route path='travel/allowances/:id/:filter' element={<TravelAllowances />} />
          <Route path='licence' element={<LicenceHome />} />
          <Route path='property' element={<PropertyHome />} />
          <Route path='vehicle' element={<VehicleHome />} />
          <Route path='vehicle/vehicleUnavailability/:id' element={<VehicleUnavailabilityHome />} />
          <Route path='vehicle/vehicleAssignmentHistory/:id' element={<VehicleAssignmentHistory />} />
          
          <Route path='visa' element={<WorkVisaHome />} />

          <Route path='user' element={<UserHome />} />
          <Route path='user/userManagement' element={<UserManagement />} />
          <Route path='user/userManagement/userRoles/:id' element={<UserRoles />} />
          <Route path='user/roles' element={<Roles />} />
          <Route path='user/roleSecurables' element={<RoleSecurables />} />

          <Route path='configuration' element={<ConfigurationHome />} />
          <Route path='configuration/propertyCategories' element={<PropertyCategoryHome />} />
          <Route path='configuration/propertyItems' element={<PropertyItemHome />} />
          <Route path='configuration/vehicleMakes' element={<VehicleMakeHome />} />
          <Route path='configuration/vehicleModels' element={<VehicleModelHome />} />
          <Route path='configuration/countries' element={<CountryHome />} />
          <Route path='configuration/locations' element={<LocationHome />} />
          <Route path='configuration/visaTypes' element={<WorkVisaTypeHome />} />
          <Route path='configuration/vehicleUnavailabilityTypes' element={<VehicleUnavailabilityTypeHome />} />
          <Route path='configuration/jobs' element={<JobHome />} />
          <Route path='configuration/allowances' element={<AllowanceHome />} />
          <Route path='configuration/employees' element={<EmployeeHome />} />
          
        </Routes>

        {props.children}
      </div>
    </div>
  );
};

/*
 * Main Component
 */
export const PageLayout = (props) => {
  const { data } = useGetUserSecurablesQuery();
  const toastMessage = useSelector(selectToastMessage);

  useEffect(() => {
    console.log('PageLayout: A Toast message has been set... body = ' + toastMessage.body)
  }, [toastMessage]);

  return (
    <>
      <Navbar bg="dark" variant="dark" style={{ height: "40px" }}>
        <a className="navbar-brand" href="/"><span className={styles.heading}>mahi tahi{props.testSystemName ? ' - ' + props.testSystemName : ''}</span></a>
        <Nav className="me-auto">
        </Nav>
        <Nav>
          <Nav.Item>
            <ProfileData />
          </Nav.Item>
        </Nav>
      </Navbar>
      <SideNavbar userSecurables={data}>
        {props.children}
      </SideNavbar>

      <ToastMessage message={toastMessage} />
    </>
  );

};