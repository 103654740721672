import * as React from "react";
import { useState, useEffect } from "react";
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';

import { TRAVEL_STATUS } from '../api/types';

import { GetStatusColours } from '../travel/TravelHome';

import { useGetUserSecurablesQuery, useGetMetricTravelAmountsQuery } from '../api/apiSlice';
import { hasReadPermission, SECURABLE_NAME } from '../userProfile/securableHelper'
import { Container } from "@mui/material";


export const WidgetTravelAmounts = (props: any) => {
  const [canRead, setCanRead] = useState<boolean>(false);
  const [newTotal, setNewTotal] = useState<number>(0);
  const [approvedTotal, setApprovedTotal] = useState<number>(0);
  const [exportedTotal, setExportedTotal] = useState<number>(0);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: travelAmounts, refetch: refetchTravelAmounts } = useGetMetricTravelAmountsQuery(props.age);

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanRead(hasReadPermission(userSecurables, [SECURABLE_NAME.Travel]));
    }
  }, [userSecurables])

  useEffect(() => {
    refetchTravelAmounts();
  }, [props.age])

  useEffect(() => {
    let newTot: number = 0;
    let appTot: number = 0;
    let expTot: number = 0;

    if (travelAmounts && travelAmounts.length > 0) {
      travelAmounts.forEach((ta) => {
        newTot += ta.newAllowanceAmount ?? 0;
        appTot += ta.approvedAllowanceAmount ?? 0;
        expTot += ta.exportedAllowanceAmount ?? 0;
      });
    }

    setNewTotal(newTot);
    setApprovedTotal(appTot);
    setExportedTotal(expTot);

  }, [travelAmounts])

  const width: number = props.width ?? 400;
  const height: number = props.height ?? 300;

  const chartOptions: AgChartOptions = {
    autoSize: false,
    width: width - 30,
    height: height - 66,
    data: travelAmounts,
    type: 'bar',
    legend: { enabled: false },
    padding: { left: 10, top: 0 },
    series: [
      {
        xKey: 'location',
        yName: 'Exported Allowances ($)',
        yKey: 'exportedAllowanceAmount',
        stacked: true,
        fill: GetStatusColours(TRAVEL_STATUS.Exported)?.color,
        strokeWidth: 0,
        highlightStyle: { item: { fill: 'white', fillOpacity: 0.5 } },
        //label: { color: 'white', formatter: (p: any) => (p.value && p.value > 0) ? '' + p.value : '' }
      },
      {
        xKey: 'location',
        yName: 'Approved Allowances ($)',
        yKey: 'approvedAllowanceAmount',
        stacked: true,
        fill: GetStatusColours(TRAVEL_STATUS.Approved)?.color,
        strokeWidth: 0,
        highlightStyle: { item: { fill: 'white', fillOpacity: 0.5 } },
        //label: { color: 'white', formatter: (p: any) => (p.value && p.value > 0) ? '' + p.value : '' }
      },
      {
        xKey: 'location',
        yName: 'New Allowances ($)',
        yKey: 'newAllowanceAmount',
        stacked: true,
        fill: GetStatusColours(TRAVEL_STATUS.New)?.color,
        strokeWidth: 0,
        highlightStyle: { item: { fill: 'white', fillOpacity: 0.5 } },
        //label: { color: 'white', formatter: (p: any) => (p.value && p.value > 0) ? '' + p.value : '' }
      },
    ]
  };

  return (
    <>
      {
        canRead ?
          <div style={{ backgroundColor: '#ffffff', border: '#f0f0f0 solid 1px', borderRadius: '5px', width: `${width}px`, height: `${height}px` }}>
            <Stack>
              <Grid container>
                <Grid xs={1}>
                </Grid>
                <Grid xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <span className="pageHeader">Travel Costs Yesterday</span>
                </Grid>
                <Grid xs={1}>
                  <Tooltip title="Reload data" enterDelay={1000}>
                    <IconButton color="primary" size="small" sx={{ margin: '8px 0' }}
                      onClick={() => refetchTravelAmounts()}>
                      <i className="fa fa-fw fa-rotate" style={{ fontSize: '0.9em' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <span>Totals | Exported: <strong>${exportedTotal.toFixed(2)}</strong> | Approved: <strong>${approvedTotal.toFixed(2)}</strong> | New: <strong>${newTotal.toFixed(2)}</strong></span>
                </Grid>
              </Grid>
              {travelAmounts && travelAmounts.length > 0 ?
                <AgChartsReact options={chartOptions} />
                :
                <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                  <span>No data for yesterday, or awaiting update...</span>
                </Container>
              }
            </Stack>
          </div>
          : <></>
      }
    </>
  );
}

