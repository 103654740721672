import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form'
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { PropertyAssigned } from '../api/types';
import { useDeletePropertyAssignedMutation, useGetRoleSecurablesQuery } from '../api/apiSlice';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Property.module.css'

export const PropertyAssignDelete = (props: any) => {
  const dispatch = useDispatch();
  const [property, setProperty] = useState<PropertyAssigned | null>(null);

  const [deletePropertyAssigned, { isLoading: isUpdating }] = useDeletePropertyAssignedMutation();
  const { data: roleSecurables } = useGetRoleSecurablesQuery();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();
  const handleSave = async (delItem: Partial<PropertyAssigned>) => {
    if (delItem) {
      const res: any = await deletePropertyAssigned(delItem);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        //displayToastMessage("info", "", "Role deleted");
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error deleting property assignment", errorText);
      }
    }
  };

  useEffect(() => {
    setProperty(props.propertyAssigned);
  }, [props.propertyAssigned]);

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          <span className="dialogHeader">Delete Item</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '16px 0', margin: 'auto' }}>
            <Form
              onSubmit={handleSave}
              initialValues={property}
              render={({ handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <span>Are you sure you want to delete?</span>
                    </Grid>
                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          type="button"
                          color="primary"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          variant="contained"
                          type="submit"
                          disabled={submitting}
                        >
                          <span>Delete</span>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

  /*
    return (
      <>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ width: '350px' }}>
            Edit role
          </DialogTitle>
          <DialogContent>
            <Container fluid>
              <Row>
                <span style={{ fontSize: '1.3em', fontWeight: '450', marginTop: '8px' }}>Edit role: {role ? role.name : ''}</span>
              </Row>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave} autoFocus>Save</Button>
            <Button onClick={handleClose} autoFocus>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
    */
};