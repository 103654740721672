import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { PropertyAssigned, PropertyCategory, PropertyItem } from '../api/types';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';
import { useGetUserSecurablesQuery, useGetPropertyItemsQuery, useGetPropertyCategoriesQuery, useUpdatePropertyAssignedMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Property.module.css'
import { Stack } from "@mui/material";

export const PropertyAssignEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);
  const [propertyAssigned, setPropertyAssigned] = useState<Partial<PropertyAssigned>>();

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawPropertyCategories } = useGetPropertyCategoriesQuery();
  const { data: rawPropertyItems } = useGetPropertyItemsQuery();
  const [updatePropertyAssigned, { isLoading: isUpdating }] = useUpdatePropertyAssignedMutation();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.PPE]));
    }
  }, [userSecurables])

  useEffect(() => {
    setPropertyAssigned(props.propertyAssigned);
  }, [props.propertyAssigned])

  const propertyCategories = useMemo(() => {
    let records: PropertyCategory[] = [];
    if (rawPropertyCategories) {
      records = rawPropertyCategories.filter((r) => r.active === true);
    }
    return records;
  }, [rawPropertyCategories]);

  // @T4804A
  const propertyItems = useMemo(() => {
    let records: PropertyItem[] = [];
    if (rawPropertyItems) {
      records = rawPropertyItems.filter((r) => r.active === true);
    }
    return records;
  }, [rawPropertyItems]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Formik validation schema
  const validationSchema = yup.object().shape({
    dateIssued: yup.date()
      .required('Date issued is required'),
    propertyItemID: yup.number()
      .min(1)
      .required('Property item is required'),
    quantity: yup.number()
      .min(1)
      .required('Quantity is required'),
  });

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<PropertyAssigned>) => {
    if (newValues) {
      const res: any = await updatePropertyAssigned(newValues);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        displayToastMessage("info", "Success", "Assigned property saved");
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving assigned property", errorText);
      }

    //  console.log("Saving new values: dateIssued:" + newValues.dateIssued + " propertyCategoryID:" + newValues.propertyCategoryID
    //    + " propertyItemID:" + newValues.propertyItemID
    //    + " purchaseOrder:" + newValues.purchaseOrder);
    }
  }

  //const AutocompleteWrapper = (props: any) => {
  //  const { options, value, onChange, ListboxProps, renderInput, ...rest } = props;

  //  const defaultRenderInput = (params: any) => { return (<TextField {...params} variant="standard" />); };

  //  return (
  //    <Autocomplete
  //      {...rest}
  //      onChange={(e: any, data: any) => onChange(e, data ? data.value : 0)}
  //      options={options}
  //      value={options && value ? options.find((o: any) => o.value === value) : ''}
  //      ListboxProps={ListboxProps}
  //      isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
  //      renderInput={renderInput ?? defaultRenderInput}
  //    />);
  //}

  const captionStyle =  { margin: '6px 0 4px 0' };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "500px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.propertyAssigned && props.propertyAssigned.id && props.propertyAssigned.id > 0 ? (
            <span className="dialogHeader">Update Item</span>
          )
            : (
              <span className="dialogHeader">Add Item</span>
            )
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={props.propertyAssigned} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid style={{ paddingTop: 4 }} item xs={12}>
                    </Grid>
                    <Grid item xs={8}>
                      <Stack>
                        <span style={captionStyle}>Date Issued</span>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker value={values.dateIssued}
                            onChange={(value) => setFieldValue("dateIssued", value, true)}
                            renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                            inputFormat="DD/MM/yyyy"
                            disabled={!canUpdate}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack>
                        <Stack direction='row'>
                          <span style={captionStyle}>PO #</span>
                          <span style={{ margin: '10px 0 0 4px', fontSize: '9px', verticalAlign: 'bottom' }}>(optional)</span>
                        </Stack>
                        <TextField value={values.purchaseOrder}
                          name="purchaseOrder"
                          onChange={handleChange}
                          disabled={!canUpdate}
                          variant="standard"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={8}>
                      <Stack>
                        <span style={captionStyle}>Category</span>
                        <AutocompleteWrapper
                          value={values?.propertyCategoryID}
                          onChange={(e: any, value: any) => {
                            if (value) {
                              setFieldValue("propertyCategoryID", value, true);
                              setFieldValue("propertyItemID", 0, true);
                            }
                          }
                          }
                          renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '280px' }} variant="standard" />); }}
                          options={propertyCategories ? propertyCategories.map((j) => { return { label: j.name, value: j.id }; }) : []}
                          disabled={!canUpdate}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack>
                        <Stack direction='row'>
                          <span style={captionStyle}>Asset #</span>
                          <span style={{ margin: '10px 0 0 4px', fontSize: '9px', verticalAlign: 'bottom' }}>(optional)</span>
                        </Stack>
                        <TextField value={values.assetNumber}
                          name="assetNumber"
                          onChange={handleChange}
                          disabled={!canUpdate}
                          variant="standard"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={8}>
                      <Stack>
                        <span style={captionStyle}>Item</span>
                        <AutocompleteWrapper
                          value={values?.propertyItemID}
                          onChange={(e: any, value: any) => { if (value) setFieldValue("propertyItemID", value, true); }}
                          renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '280px' }} variant="standard" />); }}
                          options={values.propertyCategoryID && propertyItems ?
                            propertyItems.filter((p) => p.propertyCategoryID === values.propertyCategoryID).map((j) => { return { label: j.name, value: j.id }; })
                            : []}
                          disabled={!canUpdate}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack>
                        <span style={captionStyle}>Quantity</span>
                        <TextField value={values.quantity}
                          name="quantity"
                          onChange={handleChange}
                          disabled={!canUpdate}
                          variant="standard"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack>
                        <span style={captionStyle}>Notes</span>
                        <TextField value={values.notes}
                          sx={{ width: '100%' }}
                          name="notes"
                          multiline
                          rows={3}
                          onChange={handleChange}
                          disabled={!canUpdate}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel label="Item returned" control={
                        <Checkbox
                          name="isReturned"
                          checked={values.isReturned}
                          onChange={handleChange}
                          disabled={!canUpdate}
                        />
                      } />
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: '8px' }}>

                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className={styles.gridButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting || !isValid || !dirty}
                          >
                            {props.propertyAssigned && props.propertyAssigned.id && props.propertyAssigned.id > 0 ? (
                              <span>Update</span>
                            )
                              : (
                                <span>Add</span>
                              )
                            }
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};