import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { PageLayout } from "./features/PageLayout";
import { LandingPage } from "./features/landing/LandingPage"
import { loginRequestMSGraph, loginRequestMahiTahi } from "./auth/authConfig";

import { useDispatch, useSelector } from 'react-redux';
import { useIsAuthenticated } from "@azure/msal-react";

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import Button from "react-bootstrap/Button";


// This stuff will be removed from here and some of the logic moved elsewhere...
import { callMsGraph, callMsGraphUserPhoto } from "./features/userProfile/graph";

import { setProfile } from "./features/userProfile/profileSlice"
import { light } from "@mui/material/styles/createPalette";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    fontSize: 13
  },
  palette: {
    primary: {
      main: '#404040',
      light: '#b0b0b0',
      dark: '#202020'
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 13
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: 13
        },
        container: {
          fontSize: 13
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 13
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 13
        }
      }
    }
  },
});

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();

  const { testSystemName } = window['runConfig'];

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequestMSGraph,
        account: accounts[0]
      };

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request).then((response) => {
        console.log("Access token for MS-Graph call: " + response.accessToken);
        callMsGraph(response.accessToken).then(response => dispatch(setProfile(response)));
      }).catch((e) => {
        console.log("Silent request for access token for MS Graph failed: " + e);
        instance.acquireTokenPopup(request).then((response) => {
          console.log("Access token for MS-Graph call (catch): " + response.accessToken);
          callMsGraph(response.accessToken).then(response => dispatch(setProfile(response)));
        });
      });
    }
  }, [isAuthenticated, instance, accounts, dispatch]);

  /*
  return (
    <React.Fragment>
      <UnauthenticatedTemplate>
        <LandingPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <PageLayout>
            <ProfileContent />
            <MahiTahiContent />
        </PageLayout>
      </AuthenticatedTemplate>
    </React.Fragment>
  );
  */
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <UnauthenticatedTemplate>
            <LandingPage />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <PageLayout testSystemName={testSystemName}>
            </PageLayout>
          </AuthenticatedTemplate>
        </BrowserRouter>
      </ThemeProvider>
    </React.Fragment>
  );
}

/*
 *           <BrowserRouter>
          <Routes>
            <Route exact path='/' component={Dashboard} />
            <Route path='/travel' component={Travel} />
          </Routes>
        </BrowserRouter>
*/
export default App;