import React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import { useGetUserSecurablesQuery } from '../api/apiSlice';

import { WidgetTravelAmounts } from "../widget/WidgetTravelAmounts";
import { WidgetOutOfPortEmployees } from "../widget/WidgetOutOfPortEmployees";
import { WidgetAverageTravelCost } from "../widget/WidgetAverageTravelCost";
import { WidgetTravelPendingApproval } from "../widget/WidgetTravelPendingApproval";
import { WidgetEmployeesByLocation } from "../widget/WidgetEmployeesByLocation";
import { WidgetVehicleCount } from "../widget/WidgetVehicleCount";


export const Dashboard = (props) => {
  const [hasSecurables, setHasSecurables] = useState(false);
  const [securablesLoaded, setSecurablesLoaded] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();

  useEffect(() => {
    if (userSecurables) {
      setHasSecurables(userSecurables.length > 0);
      setSecurablesLoaded(true);
    }
  }, [userSecurables]);

  return (
    <>
      <Grid container alignItems="flex-start" spacing={2} style={{ paddingLeft: '20px' }}>
        <Grid item xs={12}>
          <Stack gap={0}>
            <span className="pageHeader">Welcome to Mahi Tahi</span>
            <div style={{ marginTop: '12px' }}>
              {
                securablesLoaded ?
                  (
                    hasSecurables ?
                      <span>Select an action from the lefthand sidebar.</span>
                      :
                      <span>Thanks for logging in to Mahi Tahi. The System Administrator has been notified of your request for access and will set you up shortly.</span>
                  )
                  : <span>Loading...</span>
              }
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12}>
        </Grid>
        <Grid item>
          <Stack>
            <WidgetTravelPendingApproval age={30} width={450} height={160} />
            <div style={{ marginTop: '20px' }}>
              <WidgetAverageTravelCost age={30} width={450} height={160} />
            </div>
          </Stack>
        </Grid>
        <Grid item>
          <WidgetTravelAmounts age={1} width={450} height={340} />
        </Grid>
        <Grid item>
          <WidgetOutOfPortEmployees age={1} width={450} height={340} />
        </Grid>
        <Grid item>
          <WidgetEmployeesByLocation width={450} height={340} />
        </Grid>
        <Grid item>
          <Stack>
            <WidgetVehicleCount width={450} height={160} />
            <div style={{ marginTop: '20px' }}>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

