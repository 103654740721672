import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as moment from 'moment';
import { Moment } from "moment";

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { VehicleUnavailability, VehicleUnavailabilityType } from '../api/types';
import {
  useGetUserSecurablesQuery, useUpdateVehicleUnavailabilityMutation, useGetVehicleUnavailabilityTypesQuery
} from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';

import { setToastMessage } from '../uiHelpers/toastSlice';

//import styles from './Licence.module.css'
import { Stack } from "@mui/material";

export const VehicleUnavailabilityEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawUnavailabilityTypes } = useGetVehicleUnavailabilityTypesQuery();

  const [updateVehicleUnavailability, { isLoading: isUpdating }] = useUpdateVehicleUnavailabilityMutation();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdate(hasUpdatePermission(userSecurables, [SECURABLE_NAME.DriverLicence]));
    }
  }, [userSecurables])

  const unavailabilityTypes = useMemo(() => {
    let records: VehicleUnavailabilityType[] = [];

    if (rawUnavailabilityTypes) {
      records = rawUnavailabilityTypes.filter((r) => r.active === true);
    }
    return records;
  }, [rawUnavailabilityTypes]);

  // Formik validation schema 
  const validationSchema = yup.object().shape({
    vehicleUnavailabilityTypeID: yup.number()
      .min(1)
      .required('Unavailability reason is required'),
    fromDate: yup.date()
      .required('Start Date is required'),
    toDate: yup.date()
      .min(yup.ref('fromDate'), 'End date cannot be before start date')
      .required('End Date is required'),
  });

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<VehicleUnavailability>) => {
    if (newValues) {
      // Force employeeID to 0 so that we don't change assignment on save
      const res: any = await updateVehicleUnavailability(newValues);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        displayToastMessage("info", "Success", "Vehicle unavailability saved");
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving vehicle unavailability", errorText);
      }

    }
  };

  const captionStyle = { margin: '6px 0 4px 0' };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.vehicleUnavailability && props.vehicleUnavailability.id && props.vehicleUnavailability.id > 0 ? (
            <span className="dialogHeader">Update Vehicle Unavailability</span>
          )
            : (
              <span className="dialogHeader">Add Vehicle Unavailability</span>
            )
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Formik initialValues={props.vehicleUnavailability} onSubmit={handleSave} validationSchema={validationSchema}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Stack>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>Start Date</span>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker value={values.fromDate}
                                onChange={(value) => setFieldValue("fromDate", value, true)}
                                renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                                inputFormat="DD/MM/yyyy"
                                disabled={!canUpdate}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>End Date</span>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker value={values.toDate}
                                onChange={(value) => setFieldValue("toDate", value, true)}
                                renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '180px' }} variant="standard" />); }}
                                inputFormat="DD/MM/yyyy"
                                disabled={!canUpdate}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack>
                            <span style={captionStyle}>Reason</span>
                            <AutocompleteWrapper
                              value={values?.vehicleUnavailabilityTypeID}
                              onChange={(e: any, value: any) => { if (value) { setFieldValue("vehicleUnavailabilityTypeID", value, true); } } }
                              renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                              options={unavailabilityTypes ? unavailabilityTypes.map((j) => { return { label: j.name, value: j.id }; }) : []}
                              disabled={!canUpdate}
                            />
                          </Stack>
                        </Grid>

                      </Grid>
                    </div>
                    <div style={{ height: '42px', marginTop: '12px' }}>
                      <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className="gridButton"
                            type="button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: '16px' }}>
                          <Button
                            className="gridButton"
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!canUpdate || isSubmitting || !isValid || !dirty}
                          >
                            {props.vehicleUnavailability && props.vehicleUnavailability.id && props.vehicleUnavailability.id > 0 ? (
                              <span>Update</span>
                            )
                              : (
                                <span>Add</span>
                              )
                            }
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Stack>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};