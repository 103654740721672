import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { useGetUserSecurablesQuery, useDeleteEmployeeLicenceEndorsementMutation } from '../api/apiSlice';
import { hasReadPermission, hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './Licence.module.css'

export const LicenceEndorsementDelete = (props: any) => {
  const dispatch = useDispatch();
  //const [licenceEndorsement, setLicenceEndorsement] = useState<Partial<LicenceEndorsement>>({});

  const [canDeleteLicence, setCanDeleteLicence] = useState(false);
  const [endorsementFullName, setEndorsementFullName] = useState<string>('');
  const [endorsementShortName, setEndorsementShortName] = useState<string>('');

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const [deleteEmployeeLicenceEndorsement, { isLoading: isDeleting }] = useDeleteEmployeeLicenceEndorsementMutation();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanDeleteLicence(hasDeletePermission(userSecurables, [SECURABLE_NAME.DriverLicence]));
    }
  }, [userSecurables])

  useEffect(() => {
    if (props.licenceEndorsement) {
      setEndorsementFullName(props.licenceEndorsement.endorsement);
      setEndorsementShortName(props.licenceEndorsement.endorsementShortName);
    }
  }, [props.licenceEndorsement]);

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleDelete = async () => {
    if (props.licenceEndorsement && props.licenceEndorsement.licenceEndorsementID) {
      // Create the parameter for the update call
      const delLicenceEndorsement = { licenceID: props.licenceId, licenceEndorsementID: props.licenceEndorsement.licenceEndorsementID };

      const res: any = await deleteEmployeeLicenceEndorsement(delLicenceEndorsement);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error deleting licence endorsement", errorText);
      }
    }
  };

  return (
    <Dialog
      PaperProps={{ sx: { width: "440px" } }}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" >
        <span className="dialogHeader">Delete Endorsement {endorsementShortName}</span>
      </DialogTitle>
      <DialogContent>
        <div style={{ padding: '16px 0', margin: 'auto' }}>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12}>
              <span>Are you sure you want to delete endorsement "{endorsementFullName}" from this employee?</span>
            </Grid>
            <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
              <Grid item style={{ marginLeft: '10px' }}>
                <Button
                  className={styles.gridButton}
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item style={{ marginLeft: '10px' }}>
                <Button
                  className={styles.gridButton}
                  type="button"
                  variant="contained"
                  onClick={handleDelete}
                  disabled={!canDeleteLicence}
                >
                  <span>Delete</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );

};