import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { AlertColor } from '@mui/material/Alert';
import * as moment from 'moment';
import { Moment } from "moment";

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './UserAccess.module.css'

import { Role, UserWithRoles, UserRole } from '../api/types';
import { useGetUsersWithRolesQuery, useGetUserSecurablesQuery, useRemoveUserFromRoleMutation } from '../api/apiSlice';
import { hasUpdatePermission, hasDeletePermission, SECURABLE_NAME } from '../userProfile/securableHelper'
import { ToastMessage, ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

export const RoleUsers = (props: any) => {

  const [canUpdate, setCanUpdate] = useState(false);
  const [role, setRole] = useState<Role>();
  const [currentUser, setCurrentUser] = useState<Partial<UserWithRoles> | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [canUpdateUser, setCanUpdateUser] = useState(false);
  const [canDeleteUser, setCanDeleteUser] = useState(false);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawUsersWithRoles, error, isLoading, refetch } = useGetUsersWithRolesQuery();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanUpdateUser(hasUpdatePermission(userSecurables, [SECURABLE_NAME.User]));
      setCanDeleteUser(hasDeletePermission(userSecurables, [SECURABLE_NAME.User]));
    }
  }, [userSecurables])

  useEffect(() => {
    setRole(props.role);
  }, [props.role])

  const users = useMemo(() => {
    let items: UserWithRoles[] = [];
    if (rawUsersWithRoles) {
      // Filter one users with the current role
      items = rawUsersWithRoles.filter((ur) => ur.active === true && ur.roles && ur.roles.some((r) => r.roleID === props.role?.id));
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      items = items.map((ur) => {
        let newUr = { ...ur };
        if (newUr.lastLoginDate) newUr.lastLoginDate = moment.parseZone(newUr.lastLoginDate.toString());
        return newUr;
      });
    }
    return items;
  }, [rawUsersWithRoles, props.role])

  const handleDelete = (user: UserWithRoles) => {
    setCurrentUser(user);
    setShowConfirmDelete(true);
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const columns = [
    { field: 'name', headerName: 'Name', width: 220 },
    {
      field: 'lastLoginDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: Moment = value;
        return date && date.format("DD/MM/YYYY HH:mm");
      }
      , headerName: 'Last Login', width: 150
    },
    {
      field: ' ', headerName: 'Actions', width: 120, type: 'actions'
      , renderCell: (params: GridRenderCellParams<string>) => (
          <Tooltip title="Remove user from role" enterDelay={1000}>
          <IconButton disabled={params.row.active ? !canDeleteUser : !canUpdateUser} color="primary" size="small"
              onClick={() => handleDelete(params.row)}>
            <i className="fa fa-fw fa-user-xmark" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
      ),
    },
  ];

  const ConfirmDeleteDialog = (props: any) => {
    const dispatch = useDispatch();
    const [removeUserFromRole] = useRemoveUserFromRoleMutation();

    const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
      // NOTE: The toast message belongs to the top level PageLayout component
      dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
    };

    const handleDeleteUserRole = async () => {
      if (props.user && props.role) {
        // Note that it's possible a user may have the same role more than once, with different locations; the following call
        // removes all instances of the role from the user.
        const res: any = await removeUserFromRole({ userID: props.user.id, roleID: props.role.id });
        // If all is well there should be some data returned (with a return code)
        const returnCode = res.data;
        if (returnCode !== undefined) {
          props.onClose();
        } else {
          const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
          displayToastMessage("error", "Error deleting user role", errorText);
        }
      }
    };

    return (
      <>
        <Dialog
          PaperProps={{ sx: { width: "600px" } }}
          open={props.open}
          onClose={() => props.onClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span className="dialogHeader">Confirm User Removal</span>
          </DialogTitle>
          <DialogContent>
            <div style={{ margin: 'auto' }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <span>Are you sure you want to remove user '{props.user?.name}' from role '{props.role?.name}'?</span>
                </Grid>
                  <Grid item xs={12}>
                  <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                    <Grid item style={{ marginLeft: '16px' }}>
                      <Button
                        className={styles.gridButton}
                        type="button"
                        onClick={() => props.onClose()}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                      <Button
                        className={styles.gridButton}
                        type="button"
                        variant="contained"
                        onClick={handleDeleteUserRole}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "540px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="dialogHeader">Users in role {role?.name}</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {error ? (
                  <>Oh no, there was an error</>
                ) : isLoading ? (
                  <>Loading...</>
                ) : users ? (
                  <div style={{ display: 'flex', height: 'calc(60vh - 200px)' }}>
                    <div style={{ flexGrow: '1' }}>
                      <DataGrid rows={users} columns={columns} rowHeight={GridRowHeight} getRowId={(row) => row.id}
                        sx={sxNoCellBorder} />
                    </div>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                  <Grid item style={{ marginLeft: '10px' }}>
                    <Button
                      className={styles.gridButton}
                      type="button"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>

      <ConfirmDeleteDialog open={showConfirmDelete} onClose={() => setShowConfirmDelete(false)} user={currentUser} role={role} />
    </>
  );
}