import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useGetUserSecurablesQuery, useGetVehiclesQuery } from '../api/apiSlice';
import { hasAnyPermission } from '../userProfile/securableHelper'
import { STATUS_COLOURS } from '../uiHelpers/StatusColours';

export const WidgetVehicleCount = (props: any) => {
  const [canRead, setCanRead] = useState<boolean>(false);
  const [vehicleTotal, setVehicleTotal] = useState<number>(0);

  const { data: userSecurables } = useGetUserSecurablesQuery();
  const { data: rawVehicles, refetch: refetchData } = useGetVehiclesQuery();

  useEffect(() => {
    if (userSecurables && userSecurables.length > 0) {
      setCanRead(hasAnyPermission(userSecurables));
    }
  }, [userSecurables])

  useEffect(() => {
    let totalCount: number = 0;
    if (rawVehicles) {

      rawVehicles.filter((r) => r.active === true).forEach((e) => {
        totalCount++;
      });
    }
    setVehicleTotal(totalCount);
  }, [rawVehicles]);

  const width: number = props.width ?? 300;
  const height: number = props.height ?? 300;

  return (
    <>
      {
        canRead ?
          <div style={{ backgroundColor: '#ffffff', border: '#f0f0f0 solid 1px', borderRadius: '5px', width: `${width}px`, height: `${height}px` }}>
            <Stack>
              <Grid container>
                <Grid xs={1}>
                </Grid>
                <Grid xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <span className="pageHeader">Company Vehicle Fleet</span>
                </Grid>
                <Grid xs={1}>
                  <Tooltip title="Reload data" enterDelay={1000}>
                    <IconButton color="primary" size="small" sx={{ margin: '8px 0' }}
                      onClick={() => refetchData()}>
                      <i className="fa fa-fw fa-rotate" style={{ fontSize: '0.9em' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <span style={{ margin: '0 20px 0 20px', textAlign: 'center' }}>Total number of company vehicles</span>
                </Grid>
                <Grid xs={12} sx={{ display: 'flex', height: `${height - 100}px`, alignItems: 'center', justifyContent: 'center' }}>
                  <span style={{ fontSize: '48px', fontWeight: 'bold', color: STATUS_COLOURS.Open }}>
                    {vehicleTotal ?? 0}
                  </span>
                </Grid>
              </Grid>
            </Stack>
          </div>
          : <></>
      }
    </>
  );
}

